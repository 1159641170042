import { useContext, createContext, useEffect, ReactNode } from 'react';
import { FeatureFlags, FeatureFlagsContextType } from './FeatureFlags.type';
import { POSSIBLE_FEATURE_FLAGS } from './FeatureFlags.const';
import { useMain } from '../MainProvider/Main.provider';

const defaultValue: FeatureFlagsContextType = {
    useNewActivityUI: false,
    useNewSidebarUI: false,
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType>(defaultValue);
export const useFeatureFlags = () => useContext<FeatureFlagsContextType>(FeatureFlagsContext);

export const FeatureFlagsProvider = (props: { children: ReactNode }) => {
    const { env } = useMain();
    useEffect(() => {
        // @ts-ignore
        window.featureFlags = function(featureName?: string, state?: boolean) {
            if (featureName === undefined || state === undefined) {
                POSSIBLE_FEATURE_FLAGS.forEach(flag => {
                    console.log('-------------------------');
                    console.log(flag.description);
                    console.log(`window.featureFlags('${flag.name}', true)`);
                    console.log(`window.featureFlags('${flag.name}', false)`);
                    console.log('-------------------------');
                });
                return;
            }

            const storedFlags = localStorage.getItem('featureFlags');
            const currentFlags = storedFlags ? JSON.parse(storedFlags) : {};
            currentFlags[featureName] = state;
            localStorage.setItem('featureFlags', JSON.stringify(currentFlags));
            window.location.reload();
        }

        return () => {
            // @ts-ignore
            delete window.featureFlags;
        }
    }, [env]);


    
    const flagsFromLocalStorage: FeatureFlags = JSON.parse(localStorage.getItem('featureFlags')!) || {};
    const flags: FeatureFlags = {
        ...flagsFromLocalStorage,
    }
    return <FeatureFlagsContext.Provider value={flags}>
        {props.children}
    </FeatureFlagsContext.Provider>;

};

export default FeatureFlagsContext;