import { FC, useCallback } from "react";
import { App } from "./components/App/App.component";
import { StyledWrapper } from "./ListOfApps.styled";
import { communicationsApps, documentsApps, otherApps, projectsApps, storagesApps } from "./ListOfApps.const";
import { AppType } from "./components/App/App.type";
import { useOnboardingPage } from "../../OnboardingPage.provider";
import { StepType } from "../../OnboardingPage.type";

export const ListOfApps: FC = () => {
    const { selectedPageApps, setSelectedPageApps, step } = useOnboardingPage();
    const generateAppsJsx = useCallback((apps: AppType[], stepName: StepType) => {
        const isVisible = step === stepName;
        return apps.map(app => {
            const val = selectedPageApps.includes(app.title);
            const onChangeHandler = (val: boolean) => {
                if (val) {
                    setSelectedPageApps([...selectedPageApps, app.title]);
                } else {
                    setSelectedPageApps(selectedPageApps.filter(v => v !== app.title));
                }
            };
            return <App key={app.title} isVisible={isVisible} app={app} value={val} onChange={onChangeHandler} />
        });
    }, [selectedPageApps, setSelectedPageApps, step]);
    
    const documentsAppsJsx = generateAppsJsx(documentsApps, 'manageDocuments');
    const storagesAppsJsx = generateAppsJsx(storagesApps, 'manageStarages');
    const communicationsAppsJsx = generateAppsJsx(communicationsApps, 'manageCommunications');
    const otherAppsJsx = generateAppsJsx(otherApps, 'manageOtherApps');
    const projectsAppsJsx = generateAppsJsx(projectsApps, 'manageProjects');
    
    return <>
        <StyledWrapper>{documentsAppsJsx}</StyledWrapper>
        <StyledWrapper>{storagesAppsJsx}</StyledWrapper>
        <StyledWrapper>{communicationsAppsJsx}</StyledWrapper>
        <StyledWrapper>{otherAppsJsx}</StyledWrapper>
        <StyledWrapper>{projectsAppsJsx}</StyledWrapper>
    </>
};