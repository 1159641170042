import { FC, useMemo } from "react";
import { ActivityMessageProps } from "./ActivityMessage.type";
import {
    StyledActivityMessage,
    StyledChannel,
    StyledDate,
    StyledMassage,
    StyledName,
} from "./ActivityMessage.styled";
import { Icon } from "./components/Icon/Icon.component";
import { getChannelName } from "@/utils/GetChannelName/GetChannelName.util";
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { cutLinks } from "@/utils/cutLinks.util";
import moment from 'moment/moment';
import { formatActivityMessage } from "./ActivityMessage.utils";

export const ActivityMessage: FC<ActivityMessageProps> = ({ activityMessage, className, useSkeleton = true, isExpired = false }) => {
    const channelName = useMemo(() => {
        return getChannelName(activityMessage)
    }, [activityMessage]);
    const formattedContent = useMemo(() => formatActivityMessage(activityMessage.text), [activityMessage.text]);
      
    return <StyledActivityMessage className={className}>
        <Icon activity={activityMessage} useSkeleton={useSkeleton} />
        <div>
            <div>
                <StyledName useSkeleton={useSkeleton}>{activityMessage.userName}</StyledName>
                <StyledChannel useSkeleton={useSkeleton}>&nbsp;in&nbsp;{channelName}</StyledChannel>
            </div>
            <StyledMassage useSkeleton={useSkeleton} isExpired={isExpired}>
                {parse(sanitizeHtml(cutLinks(formattedContent), {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
                  allowedAttributes: {
                    ...sanitizeHtml.defaults.allowedAttributes,
                    'a': ['href', 'title', 'target', 'class']
                  }
                }))}
            </StyledMassage>
            {'messageTs' in activityMessage && <StyledDate useSkeleton={useSkeleton} isExpired={isExpired}>{moment.unix(parseInt(activityMessage.messageTs)).format('MMMM Do YYYY, h:mm a')}</StyledDate>}
        </div>
    </StyledActivityMessage>
};