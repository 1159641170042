import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { Box, styled } from "@mui/material";
import { zIndex } from 'Layouts/theme';

export const StyledDropDown = styled(Box)`
    width: 288px;
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    z-index: ${zIndex.spaceMenuDropdown};
`;
export const StyledListItem = styled(ListItem)`
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledListItemCount = styled('div')`
    color: var(--Text-Secondary, #636363);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;