import { FC, useMemo } from "react";
import { LargeHeaderProps } from "./LargeHeader.type";
import { StyledAction, StyledDate, StyledNode, StyledName, StyledWrapper, StyledNameWrapper, StyledNodeWrapper } from "./LargeHeader.styled";
import { UserAvatar } from "../../../../Other/UserAvatar/UserAvatar.component";
import { getFullName } from "@/utils/v3/GetFullName/GetFullName.util";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { actionTypeToText } from "@/utils/v3/ActionTypeToText/ActionTypeToText.util";
import { formatGroupDate } from "@/utils/v3/FormatGroupDate/FormatGroupDate.util";

export const LargeHeader: FC<LargeHeaderProps> = ({node, actor, useSkeleton = true, isExpired = false}) => {
    const isMobile = useRenederBelow('tablet');
    const fullName = useMemo(() => {
        return getFullName(actor?.user?.firstName, actor?.user?.lastName);
    }, [actor?.user?.firstName, actor?.user?.lastName]);
    const date = actor?.properties.happenedAt ? actor?.properties.happenedAt : node.createdAt;
    const dateStr = useMemo(() => {
        return formatGroupDate(date);
    }, [date]);
    const nodeWithDateJsx = <>
        <StyledDate useSkeleton={useSkeleton} >{dateStr}</StyledDate>
    </>
    const action = useMemo(() => {
        if (!!actor?.properties.fromState && !!actor?.properties.toState && actor?.actionType === 'UPDATED' && !!actor?.properties.toState) {
            return `updated status to: ${actor?.properties.toState}`;
        } else {
            return actionTypeToText(actor?.actionType);
        }
    }, [actor?.actionType, actor?.properties.fromState, actor?.properties.toState]);
    return <StyledWrapper>
        <StyledNameWrapper>
            <UserAvatar avatar={actor?.user?.avatar} useSkeleton={useSkeleton} />
            <StyledName useSkeleton={useSkeleton}>{fullName} <StyledAction>
                {action}
            </StyledAction></StyledName>
        </StyledNameWrapper>
        {isMobile && <StyledNodeWrapper useSkeleton={useSkeleton}>{nodeWithDateJsx}</StyledNodeWrapper>}
        {!isMobile && nodeWithDateJsx}
    </StyledWrapper>
};