import { CheckboxCard } from "@/components/v3/Fields/CheckboxCard/CheckboxCard.component";
import { AppIcon } from "@/components/v3/Other/AppIcon/AppIcon.component";
import { styled } from "@mui/material";

export const StyledAppIcon = styled(AppIcon)`
  width: 40px;
  height: 40px;
`;
export const StyledText = styled('div')`
  color: #202020;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  margin-top: 16px;
`;
export const StyledCheckboxCard = styled(CheckboxCard)`
  width: 100%;
`;