import { StyledActionsContainer, StyledActionsStepContainer } from './ManageIntegrations.styled';
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { ManageAppsLayout } from '@/components/v3/Layouts/ManageAppsLayout/ManageAppsLayout.component';
import { ListOfApps } from '@/providers/v3/OnboardingPage/components/ListOfApps/ListOfApps.component';
import { useOnboardingPage } from '@/providers/v3/OnboardingPage/OnboardingPage.provider';

export const ManageIntegrations = () => {
    const { progress, selectedPageApps, step, title, nextStep, previousStep, skipStep } = useOnboardingPage();
    const actionsJsx = <StyledActionsContainer>
        <Button variant="tertiary" onClick={() => skipStep()}>Skip this step</Button>
        <StyledActionsStepContainer>
            {step !== 'manageDocuments' && <Button onClick={() => previousStep()} variant="secondary" >Back</Button>}
            <Button disabled={!selectedPageApps.length} onClick={() => nextStep()}>Next</Button>
        </StyledActionsStepContainer>
    </StyledActionsContainer>
    return (
        <>
            <ManageAppsLayout progress={progress} title={title} actions={actionsJsx} >
                <ListOfApps />
            </ManageAppsLayout>
        </>
    );
};
