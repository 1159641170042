import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { SummaryIcon } from "@/icons/v3/summary.ion";
import { FC, useEffect, useState } from "react";
import { StyledContent, StyledHeaderText, StyledHeaderWrapper, StyledWrapper } from "./Summary.styled";
import { SummaryProps } from "./Summary.type";

export const Summary: FC<SummaryProps> = ({ value }) => {
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        let currentIndex = 0;
        setDisplayedText("");
        const intervalId = setInterval(() => {
            if (currentIndex < value.length) {
                setDisplayedText((prev) => prev + value[currentIndex]);
                currentIndex++;
            } else {
                clearInterval(intervalId); // Очищення інтервалу, коли весь текст відображено
            }
        }, 10); // Інтервал між друкуванням кожного символу (50мс)

        return () => clearInterval(intervalId); // Очищення інтервалу при демонтажі компонента
    }, [value]);
    if (!value) {
        return null;
    }
    return (
        <StyledWrapper>
            <SectionHeader
                title={
                    <StyledHeaderWrapper>
                        <SummaryIcon />
                        <StyledHeaderText>Summary</StyledHeaderText>
                    </StyledHeaderWrapper>
                }
            />
            <StyledContent>
                {displayedText}
            </StyledContent>
        </StyledWrapper>
    );
};