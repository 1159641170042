import { SearchIcon } from "@/icons/v3/search.icon";
import { styled } from "@mui/material";

export const StyledSearchIconWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-left: 4px;
    margin-bottom: 2px;
`;
export const StyledLoaderIconWrapper = styled(StyledSearchIconWrapper)`
    margin-bottom: 0;
`;
export const StyledSearchIcon = styled(SearchIcon)`
    width: 14px;
    height: 14px;
`;
export const StyledIconsWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
export const StyledEndIconWrapper = styled('div')`
    border-radius: 4px;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 4px;
`;