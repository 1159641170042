import { FC } from "react";
import { StyledPerson, StyledWrapper } from "./RelatedPeople.styled";
import { RelatedPeopleProps } from "./RelatedPeople.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";

export const RelatedPeople: FC<RelatedPeopleProps> = ({
    listOfPeople
}) => {
    const router = useRouter();
    if (!listOfPeople.length) {
        return null;
    }
    // const listOfPeopleJsx = listOfPeople.map((user, i) => {
    //     return <StyledPerson
    //         key={i}
    //         person={user}
    //         onClick={() => router.push({name: 'commonRelations', params: {fromUserId: user.id}})}
    //     />
    // });
    const listOfPeopleJsx = listOfPeople.map((user, i) => {
        return <StyledPerson
            key={i}
            person={user}
            onClick={() => router.push({name: 'commonRelations', params: {fromUserId: user.id}})}
        />
    });
    return <StyledWrapper>
        <SectionHeader title="Related people" />
        <div>
            {listOfPeopleJsx}
        </div>
    </StyledWrapper>
};