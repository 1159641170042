import { FC, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { SpaceType, SpacesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { getListWithFilledNames } from 'service/getListWithFilledNames';
import { AddToSpaceModalProps } from './AddToSpaceModal.type';
import { StyledFilledCard, StyledApplicationIcon, StyledSectionHeader } from './AddToSpaceModal.styled';
import { GetSpacesFetch } from '@/axios/AiService/Spaces/Spaces.api';
import { ToastService } from '@/service/ToastService';
import { APP_OPTIONS } from './AddToSpaceModal.const';
import { Space } from './components/Space/Space.component';
import { Dialog } from '@/components/v3/Other/Dialog/Dialog.component';
import { DialogLayout } from '@/components/v3/Layouts/DialogLayout/DialogLayout.component';
import { Input } from '@/components/v3/Fields/Input/Input.component';
import { StyledSearchIcon, StyledSearchIconWrapper } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled';
import { StyledCloseIcon } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/Search.styled';
import { EmptyStateLayoyt } from '@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component';
import { OptionType } from '@/components/v3/Fields/Select/components/Option/Option.type';
import { FilterIcon } from '@/icons/v3/filter.icon';
import { Select } from '@/components/v3/Fields/Select/Select.component';

export const AddToSpaceModal: FC<AddToSpaceModalProps> = ({ onClose, nodeId }) => {
  const [openSpaceId, setOpenSpaceId] = useState<string>();
  const [spaces, setSpaces] = useState<SpaceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [app, setApp] = useState<string>('All');

  const loadSpaces = async () => {
    setIsLoading(true);
    await GetSpacesFetch({}).then((res: SpacesAxiosResponseSuccessType) => {
      setSpaces(getListWithFilledNames<SpaceType>(res.data));
      setIsLoading(false);
    }).catch(() => {
      ToastService.showToast('error', 'Some error occurred! Please try again later!');
    });
  }
  useEffect(() => {
    nodeId && loadSpaces()
  }, [nodeId]);
  const listOfFilteredSpaces = useMemo(() => {
    const sortedSPaces = spaces.sort((a, b) => a.name.localeCompare(b.name));
    const filyeredBySearch = sortedSPaces.filter((space) => space.name.toLowerCase().includes(searchValue));
    if (app==='All') {
      return filyeredBySearch;
    }
    return filyeredBySearch.filter((space) => space.application===app);
  }, [app, searchValue, spaces]);
  
  const appOptions = useMemo((): (OptionType<string>)[] => {
    const appOptions: OptionType<string>[] = APP_OPTIONS.map((option) => ({
      ...option,
      icon: <StyledApplicationIcon application={option.value} />
    }));
    appOptions.unshift({
      label: "All",
      value: "All",
    });
    return appOptions;
  }, []);
  const listOfSpacesJsx = listOfFilteredSpaces.map((space) => {
    const isOpen = openSpaceId === space.id;
    return <Space space={space} nodeId={nodeId!} key={space.id} isOpen={isOpen} setIsOpen={(val) => {
      setOpenSpaceId(val ? space.id : '');
    }} />
  });
  return (
    <Dialog value={!!nodeId} onChange={() => onClose()}>
      <DialogLayout title="Add to Space" onCloseClick={onClose} isLoading={isLoading}>
        <Input
          placeholder="Search in Sense"
          variant="filled"
          size='medium'
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          InputProps={{
            startAdornment: (
              <StyledSearchIconWrapper>
                <StyledSearchIcon />
              </StyledSearchIconWrapper>
            ),
            endAdornment: <>
              {!!searchValue && <StyledCloseIcon onClick={() => setSearchValue('')} />}
            </>,
          }}
        />
        <StyledFilledCard>
          <EmptyStateLayoyt
            value={!nodeId || !listOfFilteredSpaces.length}
            emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
              Nothing found. Try to change filter
            </Typography>}
          >
            <StyledSectionHeader title="Spaces" >
              {appOptions.length && <Select
                label="Apps"
                value={app}
                onChange={setApp}
                options={appOptions}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
              />}
            </StyledSectionHeader>
            <div>
              {!!nodeId && listOfFilteredSpaces.length > 0 && <>
                {listOfSpacesJsx}
              </>}
            </div>
          </EmptyStateLayoyt>
        </StyledFilledCard>
      </DialogLayout>
    </Dialog>
  );
};
