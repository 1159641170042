import { FC } from "react";
import { StyledWrapper } from "./CheckboxCard.styled";
import { CheckboxCardProps } from "./CheckboxCard.type";

export const CheckboxCard:FC<CheckboxCardProps> = ({
    children,
    value,
    onChange,
    className
}) => {
    return <StyledWrapper active={value} onClick={() => onChange(!value)} className={className}>
        {children}
    </StyledWrapper>
}