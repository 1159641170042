import { styled } from "@mui/material";
import { DialogLayout } from "../../Layouts/DialogLayout/DialogLayout.component";

export const StyledDialogLayout = styled(DialogLayout)`
    padding: 0;
    width: 800px;
    overflow: unset;
}`;
export const StyledLoaderWrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}`;