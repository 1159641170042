import { styled } from "@mui/material";

export const StyledSectionHeader = styled('div')`
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding: 8px 4px;
`;
export const StyledSectionHeaderWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 10000 1 0%;
`;