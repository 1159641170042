export const POSSIBLE_FEATURE_FLAGS = [
    {
        name: 'useNewActivityUI',
        description: 'Enables/disables new activity UI.'
    },
    {
        name: 'useNewSidebarUI',
        description: 'Enables/disables new sidebar UI.'
    },
] as const;
