import { FC, useState, useEffect, useRef } from "react";
import { FallingCardProps } from "./FallingCard.type";
import { StyledFallingCard } from "./FallingCard.styled";

export const FallingCard: FC<FallingCardProps> = ({ children, value }) => {
    const [fallingStyle, setFallingStyle] = useState<React.CSSProperties>({});
    const isMounted = useRef(false);  // Додаємо ref для відстеження монтування компоненти

    useEffect(() => {
        if (!isMounted.current) {
            if (value) {
                // Якщо компонента замонтована зі значенням true, встановлюємо видимість без анімації
                setFallingStyle({
                    opacity: 1,
                    transform: 'translateY(0) rotate(0deg)'
                });
            } else {
                // Якщо з false, то робимо невидимою без анімації
                setFallingStyle({
                    opacity: 0,
                    transform: 'translateY(0) rotate(0deg)'
                });
            }
            isMounted.current = true;  // Вказуємо, що компонента замонтована
        } else {
            // Логіка анімації для оновлень
            if (value) {
                const randomStart = Math.random() * -400 - 500;
                const randomRotateStart = Math.random() * 90 - 45;

                setFallingStyle({
                    transition: 'none',
                    transform: `translateY(${randomStart}px) rotate(${randomRotateStart}deg)`,
                    opacity: 0
                });

                setTimeout(() => {
                    setFallingStyle({
                        transition: `transform ${Math.random() * 0.3 + 0.3}s ease-out, opacity 1s`,
                        transform: `translateY(0) rotate(0deg)`,
                        opacity: 1
                    });
                }, 10);
            } else {
                const randomEnd = window.innerHeight + Math.random() * 300 + 100;
                const randomRotateEnd = Math.random() * 360 - 180;

                setFallingStyle({
                    transition: `transform ${Math.random() * 0.3 + 0.3}s ease-in, opacity 1s`,
                    transform: `translateY(${randomEnd}px) rotate(${randomRotateEnd}deg)`,
                    opacity: 0
                });
            }
        }
    }, [value]);

    return (
        <StyledFallingCard style={fallingStyle} isVisible={value}>
            {children}
        </StyledFallingCard>
    );
};
