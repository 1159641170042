import { styled } from "@mui/material";
import { SizeType, VariantType } from "./Button.type";

const getBoxShadow = (variant: VariantType, disabled: boolean) => {
    if (disabled) return "none";
    switch (variant) {
      case "primary":
      case "secondary":
        return "0px 1px 1px 0px rgba(0, 0, 0, 0.12)";
      default:
        return "none";
    }
  };
  
  const getBackgroundColor = (variant: VariantType, disabled: boolean) => {
    if (disabled) {
      if (variant === "tertiary" || variant === "error") {
        return '#ffffff';
      } else {
        return '#EBEBEB';
      }
    }
    if (variant === "primary") return "#202020";
    if (variant === 'secondary') return "#ffffff";
    return 'transparent';
  };
  
  const getColor = (variant: VariantType, disabled: boolean) => {
    if (disabled) {
      return '#B5B5B5';
    } else {
      if (variant === "primary") {
        return '#ffffff';
      } else if (variant === 'error') {
        return '#F00';
      } else {
        return '#202020';
      }
    }
  };
  
  const getBorder = (variant: VariantType, disabled: boolean) => {
    if (disabled) return 'none';
    return variant === "secondary" ? "1px solid rgba(0, 0, 0, 0.16)" : "none";
  };
  
  const getPadding = (size: SizeType, hasChildren: boolean) => {
    if (!hasChildren) {
      return '0px'
    }
    if (size === "medium") {
      return '0 12px';
    } else {
      return '0 16px';
    }
  };
  const getHeight = (size: SizeType) => {
    return size === "medium" ? "32px" : "40px";
  };
  
  const getCursor = (disabled: boolean) => {
    return disabled ? "not-allowed" : "pointer";
  };
  

export const StyledButton = styled("button", {
    shouldForwardProp: (prop) => prop !== "variant" && prop !== "size" && prop !== "disabled" && prop !== "hasChildren" && prop !== "center"
})<{variant: VariantType; size: SizeType, disabled: boolean, hasChildren: boolean, center: boolean}>`
    box-shadow: ${({ variant, disabled }) => getBoxShadow(variant, disabled)};
    background: ${({ variant, disabled }) => getBackgroundColor(variant, disabled)};
    color: ${({ variant, disabled }) => getColor(variant, disabled)};
    border: ${({ variant, disabled }) => getBorder(variant, disabled)};
    padding: ${({ size, hasChildren }) => getPadding(size, hasChildren)};
    height: ${({ size }) => getHeight(size)};
    min-width: ${({ size }) => getHeight(size)};
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    border-radius: 6px;
    cursor: ${({ disabled }) => getCursor(disabled)};
    transition: all 0.1s ease-in-out;
    box-sizing: border-box;
    display: flex;
    justify-content: ${({ hasChildren, center }) => !hasChildren || center ? "center" : "flex-start"};
    align-items: center;
    white-space: nowrap;
    &:hover {
      background: ${({ variant }) => (variant === "tertiary" || variant === "error") ? "rgba(0, 0, 0, 0.06)" : undefined};
      border: ${({ variant, disabled }) => !disabled && variant === "secondary" ? "1px solid #202020" : undefined};
    }
`;
export const StyledIcon = styled("div", {
    shouldForwardProp: (prop) => prop !== "variant" && prop !== "disabled" && prop !== "hasChildren" && prop !== "size"
})<{variant: VariantType; disabled: boolean; hasChildren: boolean; size: SizeType}>`
    margin-right: ${({ hasChildren }) => hasChildren ? "8px" : "0px"};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ size }) => size === "medium" ? "16px" : "18px"};
    height: ${({ size }) => size === "medium" ? "16px" : "18px"};
    svg {
      width: ${({ size }) => size === "medium" ? "16px" : "18px"};
      height: ${({ size }) => size === "medium" ? "16px" : "18px"};
    }
`;