import { styled } from "@mui/material";

export const StyledActionsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledActionsStepContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`;