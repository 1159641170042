import { StyledContainer, StyledWrapper } from "./Tab.styled";
import { TabProps } from "./Tab.type";
import { useTabs } from "./Tabs.component";

export const Tab = <T,>(props: TabProps<T>) => {
    const { value, onChange } = useTabs<T>();
    const isActive = value === props.value;
    return (
        <StyledContainer className={props.className}>
            <StyledWrapper isActive={isActive} onClick={() => onChange(props.value)}>
                {props.icon}
                {props.children}
            </StyledWrapper>
        </StyledContainer>
    )
};