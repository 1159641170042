import { FC, useCallback, useEffect, useState } from "react";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { StyledBackButton, StyledDialogLayout, StyledInput } from "./OnboardingPeople.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { SectionHeader } from "../../Sections/SectionHeader/SectionHeader.component";
import { StyledSearchIcon, StyledSearchIconWrapper } from "../../Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled";
import { StyledCloseIcon } from "../../Layouts/MainLayout/components/Header/components/Search/Search.styled";
import { Recomended } from "./components/Recomended/Recomended.component";
import { Users } from "./components/Users/Users.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { Button } from "../../Fields/Button/Button.component";
import { GetRelatedUsersFetch } from "@/axios/AiService/Users/Users.api";
import { RelatedUsersAxiosResponseSuccessType, UserType } from "@/axios/AiService/Users/Types/Related/Related.type";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";


export const OnboardingPeople: FC = () => {
    const [search, setSearch] = useState<string>('');
    const { spaces } = useSpaces();
    const [recomendedUsers, setRecomendedUsers] = useState<UserType[]>([]);
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'onboarding-people' });
    const onChange = (value: boolean) => {
        onChangeIsOpened(value);
    };
    const { users: organizationUsers, user, loadUsers } = useMe();
    const users = organizationUsers.filter((el) => {
        return el.id !== user?.id;
    });
    const loadRecomendedUsers = useCallback(async () => {
        !!user?.id && await GetRelatedUsersFetch({ userId: user?.id || '' }).then((response: RelatedUsersAxiosResponseSuccessType) => {
            setRecomendedUsers(response.data);
        });
    }, [user?.id]);
    useEffect(() => {
        value && loadRecomendedUsers();
    }, [loadRecomendedUsers, value]);

    useEffect(() => {
        value && loadUsers();
    }, [loadUsers, value]);
    const router = useRouter();
    return <Dialog value={value} onChange={onChange} disableEscapeKeyDown>
        <StyledDialogLayout
            title={`${users.length} people found`}
            actions={<>
                {!!spaces.length && <StyledBackButton size="large" variant="secondary" onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'onboarding-projects'
                        }
                    })
                }}>
                    Back
                </StyledBackButton>}
                <Button icon={<>👍</>} size="large" variant="secondary" onClick={() => {
                    // router.push({
                    //     name: router.name,
                    //     params: router.params,
                    //     query: {
                    //         ...router.query,
                    //         dialog: undefined
                    //     }
                    // })
                    const newUrl = router.getPathWithParams(router.name, router.params, {
                        ...router.query,
                        dialog: undefined
                    });
                    window.location.href = newUrl;
                }}>
                    Get started
                </Button>
            </>}
        >
            <SectionHeader title="Which people do you want to track?" caption="These people will be pinned for quick access. You will have access to all found projects that you are eligible to access." />
            <StyledInput
                placeholder="Search"
                variant="filled"
                size='medium'
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                    startAdornment: (
                        <StyledSearchIconWrapper>
                            <StyledSearchIcon />
                        </StyledSearchIconWrapper>
                    ),
                    endAdornment: <>
                        {!!search && <StyledCloseIcon onClick={() => setSearch('')} />}
                    </>,
                }}
            />
            <Recomended users={recomendedUsers} search={search} />
            <Users search={search} />
        </StyledDialogLayout>
    </Dialog>
};