import { HttpClient } from "@/axios/HttpClient";
import { SearchGetQueryParams } from "./Types/Get/Get.type";
import { envUrl } from "@/service/helpers";
import { SearchGetSmartQueryParams } from "./Types/GetSmart/GetSmart.type";

export const SearchGetFetch = (query: SearchGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/search`, {
        params: query
    });
}
export const SearchSmartGetFetch = (query: SearchGetSmartQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/search/smart`, {
        params: query
    });
}