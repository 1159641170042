import { FC } from "react";
import { SectionHeaderProps } from "./SectionHeader.type";
import { StyledSectionHeader, StyledSectionHeaderWrapper } from "./SectionHeader.styled";

export const SectionHeader: FC<SectionHeaderProps> = ({ children, actions, className }) => {
    return <StyledSectionHeader className={className}>
        <StyledSectionHeaderWrapper>
            {children}
        </StyledSectionHeaderWrapper>
        {actions}
    </StyledSectionHeader>
};