import { FC, useEffect } from 'react';
import { useMe } from 'providers/MeProvider/Me.provider';
import { useHistory } from 'react-router-dom';
import { LoginSSOFetch } from "@/axios/OrganizationService/Users/Users.api";
import { LoginSSOAxiosResponseSuccessType } from "@/axios/OrganizationService/Users/Types/LoginSSO/LoginSSO.type";
import { useRouter } from 'providers/Router/Router.provider';
import { GetMeFetch } from '@/axios/SenseFacade/Users/Users.api';
import { UserMeAxiosResponseSuccessType } from '@/axios/SenseFacade/Users/Types/GetMe/GetMe.type';
import { EventTypes, useAmplitude } from '@/service/TrackingService';
import { ToastService } from '@/service/ToastService';
import { Wrapper } from './GoogleLoginButton.styled';
import { HttpClient } from '@/axios/HttpClient';
import { envUrl } from '@/service/helpers';

const clientId = process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID;


export const GoogleLoginButton: FC = () => {
    const router = useRouter();
    const { login, loadUser } = useMe();
    const history = useHistory();
    const trackEvent = useAmplitude();
    const callbackHandle = (response: any) => {
        if (response.credential) {
            LoginSSOFetch({
                token: response.credential,
            }).then((response: LoginSSOAxiosResponseSuccessType) => {
                if (response.data.otpEnabled === false) {
                    login(response.data.accessToken, async () => {
                        await GetMeFetch().then(async (res: UserMeAxiosResponseSuccessType) => {
                            const { accountReady, emailConfirmed, isOnboarded } = res.data.user;
                            if (!isOnboarded) {
                              await HttpClient.put(`${envUrl}/organization-service/v1/payment/subscription`, {
                                name: 'INDIVIDUAL',
                                userId: res.data.user.id,
                              });
                              // localStorage.setItem("onboardingStep", "manageIntegrations");
                              // router.push({
                              //     name: 'manageIntegrations'
                              // })
                            }
                            if (!res.data.user.firstLogin) {
                                HttpClient.patch(`${envUrl}/organization-service/v1/users/${res.data.user.id}`, {
                                    firstLogin: true,
                                });
                                trackEvent(EventTypes.FIRST_LOGIN)
                            }
                            await loadUser()
                            
                            const pathFromLink = localStorage.getItem('pathFromLink');

                            if (!emailConfirmed) {
                                router.push({
                                    name: 'linkConfirmation',
                                    query: router.query
                                })
                                return;
                            } else if (!isOnboarded) {
                                // start disable onboarding
                                router.push({
                                    name: 'manageIntegrations'
                                })
                                // if (router.query.redirectUrl) {
                                //     window.location.href = `${window.location.origin}${router.query.redirectUrl}`;
                                // } else {
                                //     router.push({
                                //         name: 'forYou',
                                //         query: router.query
                                //     })
                                // }
                                // end disable onboarding
                                return;
                            } else {
                                const path = pathFromLink && pathFromLink !== '/login' ? pathFromLink : '/for-you';
                                localStorage.removeItem('pathFromLink');
                                if (router.query.redirectUrl) {
                                    window.location.href = `${window.location.origin}${router.query.redirectUrl}`;
                                } else {
                                    history.push(path);
                                }
                            }
                            const selectedSpace = localStorage.getItem('selectedSpace');
                            if (!selectedSpace) {
                                localStorage.setItem('selectedSpace', 'My Spaces')
                            }
                        })
                    });
                } else {
                    router.push({
                        name: 'twoFactorAuthntication',
                        params: {
                            secret: response.data.secret,
                        },
                        query: router.query
                    })
                }
            }).catch(error => {
                ToastService.showToast('error', error?.response?.data?.errorMessage);
            });
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.google) {
                clearInterval(interval);
                window.google.accounts.id.initialize({
                    client_id: clientId,
                    callback: callbackHandle,
                    cancel_on_tap_outside: false,
                });

                window.google.accounts.id.renderButton(
                    document.getElementById('google-one-tap-button'),
                    {
                        width: '320px',
                    }
                );

                window.google.accounts.id.prompt();
            }
        }, 100);
    }, [])

    return (
        <Wrapper>
            <div id="google-one-tap-button"></div>
        </Wrapper>
    );
}
