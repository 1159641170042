import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Popper, styled } from "@mui/material";

export const StyledButton = styled(Button)`
    .icon {
        path {
            fill: #636363;
        }
    }
    &:hover {
        .icon {
            path {
                fill: #202020;
            }
        }
    }
`;
export const StyledPopper = styled(Popper)`
    z-index: 1301 !important;
`;
export const StyledContentWrapper = styled('div')`
    position: relative;
    border-radius: 16px;
    background: #202020;
    padding: 16px;
    margin-bottom: 16px;
`;
export const StyledContentTitle = styled('div')`
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px;
`;
export const StyledContentDescription = styled('div')`
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
`;
export const StyledContentActions = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const Arrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" fill="none">
<path d="M0 10.3919L12 -0.000427246L12 20.7842L0 10.3919Z" fill="#202020"/>
</svg>
const ArrowComponent = ({className}: {className?: string}) => <div className={className}><Arrow /></div>;
export const StyledArrow = styled(ArrowComponent)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: rotate(270deg);
    top: calc(100% - 5px);
    left: calc(50% - 6px);
    transition: opacity 0.3s ease-in-out;
    z-index: 100;
  `;