import { IconProps } from "./icon.type";

export const SummaryIcon = (props: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
        <path d="M10.7249 1.20624C10.5124 1.29999 10.4687 1.38124 10.1999 2.20624C10.0624 2.63124 9.90616 3.05624 9.86241 3.15624C9.61866 3.69999 9.13741 4.19374 8.56866 4.48749C8.45616 4.54374 8.01866 4.70624 7.59991 4.84374C7.17491 4.98124 6.78741 5.12499 6.73741 5.15624C6.53116 5.29374 6.43741 5.56249 6.52491 5.77499C6.62491 6.02499 6.68116 6.04999 7.55616 6.34374C8.36866 6.61249 8.68116 6.74374 8.94366 6.93749C9.29991 7.19374 9.70616 7.68124 9.86866 8.06874C9.90616 8.15624 10.0562 8.57499 10.1937 8.99374C10.4624 9.81249 10.5249 9.92499 10.7249 10.0062C10.9374 10.0937 11.2437 10.0062 11.3624 9.81874C11.3999 9.76874 11.5499 9.34999 11.7062 8.88749C12.0124 7.98749 12.1124 7.77499 12.4062 7.41874C12.4937 7.31249 12.6562 7.14999 12.7562 7.06874C13.0999 6.78749 13.3562 6.66874 14.2624 6.36249C14.7874 6.18749 15.1374 6.05624 15.1874 6.01249C15.4249 5.81249 15.4187 5.38124 15.1749 5.18749C15.1312 5.14999 14.7562 5.01249 14.3374 4.87499C13.9187 4.73749 13.4812 4.58124 13.3687 4.52499C12.8249 4.27499 12.3437 3.81874 12.0624 3.28124C12.0124 3.18124 11.8437 2.73124 11.6999 2.28749C11.5499 1.84374 11.3999 1.43749 11.3687 1.38124C11.2874 1.26249 11.0812 1.15624 10.9374 1.15624C10.8874 1.14999 10.7937 1.17499 10.7249 1.20624Z" fill="#9266CC"/>
        <path d="M2.91875 1.55625C2.8625 1.6 2.80625 1.725 2.69375 2.075C2.50625 2.64375 2.40625 2.8375 2.18125 3.05625C1.9625 3.275 1.76875 3.375 1.2 3.56875C0.6875 3.7375 0.625 3.78125 0.625 3.98125C0.625 4.175 0.7125 4.23125 1.28125 4.41875C2.1625 4.71875 2.375 4.9375 2.68125 5.84375C2.86875 6.4 2.90625 6.45625 3.11875 6.45625C3.3 6.45625 3.3625 6.36875 3.525 5.86875C3.70625 5.31875 3.825 5.10625 4.04375 4.8875C4.2625 4.675 4.45 4.58125 4.99375 4.4C5.48125 4.24375 5.575 4.175 5.575 3.98125C5.575 3.7875 5.5125 3.7375 5 3.56875C4.43125 3.375 4.25 3.28125 4.01875 3.05C3.8 2.83125 3.7 2.625 3.5 2.03125C3.4 1.71875 3.34375 1.6 3.2875 1.55625C3.1875 1.48125 3.01875 1.48125 2.91875 1.55625Z" fill="#9266CC"/>
        <path d="M5.12649 7.45626C4.97024 7.52501 4.90149 7.66876 4.67649 8.33126C4.55774 8.68126 4.41399 9.06876 4.35149 9.19376C4.13274 9.61876 3.78899 9.9625 3.36399 10.1813C3.24524 10.2438 2.85149 10.3938 2.49524 10.5063C1.93274 10.6875 1.82649 10.7375 1.73274 10.825C1.60774 10.9438 1.56399 11.0938 1.61399 11.2563C1.67649 11.4563 1.75774 11.5063 2.51399 11.7625C3.37024 12.05 3.48274 12.1063 3.84524 12.4063C4.20149 12.7063 4.38899 13.0438 4.68274 13.9313C4.91399 14.6313 4.96399 14.7188 5.12649 14.8063C5.30149 14.9 5.58274 14.8125 5.68899 14.6375C5.71399 14.5938 5.84524 14.2438 5.97024 13.8563C6.24524 13.0375 6.33899 12.8563 6.69524 12.5063C7.04524 12.1563 7.22649 12.0625 8.04524 11.7813C8.42649 11.65 8.77649 11.525 8.82649 11.5C9.00149 11.3938 9.08899 11.1188 8.99524 10.9375C8.90774 10.775 8.82024 10.725 8.12024 10.4938C7.23274 10.1938 6.89524 10.0125 6.59524 9.65626C6.28899 9.29376 6.23899 9.18125 5.95149 8.325C5.80149 7.8875 5.68899 7.60001 5.64524 7.55626C5.50149 7.42501 5.30149 7.38126 5.12649 7.45626Z" fill="#9266CC"/>
    </svg>
  );
};
