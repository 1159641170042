import { DropdownField } from '@/components/v3/Fields/DropdownField/DropdownField.component';
import { FC, useMemo, useState } from 'react';
import { PeopleDropdownProps, ValueType } from './PeopleDropdown.type';
import { StyledDropDown, StyledListItem, StyledListItemCount } from './PeopleDropdown.styled';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { Divider } from '@/components/v3/Other/Divider/Divider.component';
import { StyledAddIcon } from '../../../../Sidebar.styled';
import { useRouter } from '@/providers/Router/Router.provider';

export const PeopleDropdown: FC<PeopleDropdownProps> = ({
  value, onChange
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const title = useMemo(() => {
    if (value === 'all') {
      return 'All people';
    }
    return 'My team';
  }, [value]);
  const { userRelations } = useMe();
  const router = useRouter();
  const onChangeHandler = (value: ValueType) => {
    onChange(value);
    setIsOpened(false);
  };
  return (
    <>
      <DropdownField 
        label={title} 
        onChange={setIsOpened}
        value={isOpened}
        variant='tetrinary'
      >
        <StyledDropDown>
          <StyledListItem
            title="My team"
            onClick={() => {onChangeHandler('followed')}}
          >
            <StyledListItemCount>{userRelations?.length}</StyledListItemCount>
          </StyledListItem>
          <Divider />
          <StyledListItem
            title="All people"
            onClick={() => {onChangeHandler('all')}}
          />
          <Divider />
          <StyledListItem
            title="Invite people"
            img={<StyledAddIcon />}
            onClick={() => {
              router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'manage-users'
                }
              });
              setIsOpened(false);
            }}
          />
        </StyledDropDown>
      </DropdownField>
    </>
  );
};
