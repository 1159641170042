import { styled } from "@mui/material";
import { Node } from "./components/Node/Node.component";
import { SizeType } from "../../Activity.type";
import { TextSkeleton } from "@/components/v3/Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    @media (max-width: 768px) {
        margin-bottom: 4px;
    }
`;
export const StyledNameWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
export const StyledNodeWrapper = styled(TextSkeleton)`
    margin-left: 8px;
`;
export const StyledName = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
`;
export const StyledAction = styled('span')`
    color: #636363;
    font-weight: 400;
`;
export const StyledNode = styled(Node)`
    margin-left: 4px;
    @media (max-width: 768px) {
        margin-left: 0;
    }
`;
export const StyledDate = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;