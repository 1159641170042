import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { styled } from "@mui/material";

export const StyledActionsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledAppsWrapper = styled("div")`
    margin-top: 24px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    margin-bottom: 16px;
`;
export const StyledActionsStepContainer = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`;