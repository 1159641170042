import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    border-radius: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.06);
    overflow: hidden;
    position: relative;
`;
export const StyledProgress = styled('div', {
    shouldForwardProp: (prop) => prop !== 'value'
})<{width: number}>`
    width: ${({width}) => width}%;
    height: 8px;
    background: #636363;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
`;