import { Route, Switch, useHistory } from 'react-router-dom';
import { mainLayoutRoutesConfig, onboardingLayoutRouteConfig } from './RoutesConfig';
import { useMe } from 'providers/MeProvider/Me.provider';
import { useEffect, useState } from 'react';
import { OnboardingPageProvider } from '@/providers/v3/OnboardingPage/OnboardingPage.provider';
// import { TrackRecentVisit } from '../components/TrackRecentVisit';

export const OnboardingRoutes = () => {
  const [isRedirect, setIsRedirect] = useState(false);
  const { isLoggedIn, user } = useMe();
  const history = useHistory();
  useEffect(() => {
    mainLayoutRoutesConfig.forEach(route => {
        setIsRedirect(history.location.pathname.includes(route.path));
      })
    if (history.location.pathname.includes('/login') || history.location.pathname.includes('/signup') ) {
      setIsRedirect(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (isLoggedIn) {
    if (user?.isOnboarded && user.emailConfirmed && isRedirect) {
      history.push('/for-you');
    }
  }
  return (
    <>
      <OnboardingPageProvider>
        <Switch>
          {onboardingLayoutRouteConfig.map(route => {
              return <Route key={route.path} path={route.path} exact={route?.exact ?? true} component={route.component}/>
          })}
        </Switch>
      </OnboardingPageProvider>
    </>
  );
};
