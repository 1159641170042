import { FC, useMemo } from "react";
import { NodeProps } from "./Node.type";
import { StyledExpiredTip, StyledName, StyledNodeIcon, StyledWrapper } from "./Node.styled";
import { getLinkName } from "@/utils/v3/GetLinkName/GetLinkName.util";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";

export const Node :FC<NodeProps> = ({ node, className, useSkeleton = true, isExpired = false }) => {
    const { setActiveNodeId } = useNode();
    const name = getLinkName(node.name);
    const router = useRouter();
    const { invitedUsers, confirmedInvitedUsers } = useMe();
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const onClickHandler = () => {
        if (isExpired) {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            });
        } else {
            setActiveNodeId(node.id)
        }
    };
    return <StyledWrapper className={`${className} disable-node-drawer-close`} onClick={onClickHandler}>
        <StyledNodeIcon node={node} useSkeleton={useSkeleton} />
        <StyledName>{name}</StyledName>
        {isExpired && <StyledExpiredTip />}
    </StyledWrapper>
};