import { Node } from "@/components/v3/List/Node/Node.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled(FilledCard)`
    margin-top: 16px;
`;
export const StyledNodesWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;
export const StyledNode = styled(Node)`
    margin-top: 8px;
`;