import { styled } from "@mui/material";

export const StyledWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'active'
})<{active: boolean}>`
    box-sizing: border-box;
    background: #FFF;
    transition: all 0.1s;
    cursor: pointer;
    min-height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 6px;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    ${({active}) => !active && `
        border: 1px solid rgba(0, 0, 0, 0.16);
        &:hover {
            border: 1px solid #202020;
        }
    `}
    ${({active}) => active && `
        border: 2px solid #202020;
    `}
`;