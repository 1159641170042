import { createContext, useContext, useEffect, useState, ReactNode, useCallback, useMemo, useRef } from "react";
import {
    SpacePageContextType,
    SpaceNodeTypeWithRelationships,
    SpacesNodeWrapperType,
    TabValueType
} from "./SpacePage.type";
import { GetSpacesNodesFetch } from "@/axios/AiService/SpacesNodes/SpacesNodes.api";
import { SpacesNodesAxiosResponseSuccessType } from "@/axios/AiService/SpacesNodes/Types/Get/Get.type";
import { GetSpaceSuggestionsFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { useMe } from "providers/MeProvider/Me.provider";
import { ToastService } from "service/ToastService";
import { RecentActivityType } from "@/axios/AiService/NodeRecentActivities/Types/Get/Get.type";
import { fetchNodeRelations } from "utils/FetchNodeRelations/FetchNodeRelations.util";
import { useInfiniteScroll } from "hooks/v3/UseInfiniteScroll/UseInfiniteScroll.hook";
import { useModal } from "store/ModalContext";
import { useRouter } from "@/providers/Router/Router.provider";
import { GetAllResourcesFetch, GetUpdatesFetch } from "@/axios/SenseFacade/Public/Public.api";
import { UpdatesAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetUpdates/GetUpdates.type";
import { AllResourcesAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetAllResources/GetAllResources.type";
import { addUsersToRelations } from "@/utils/AddUsersToRelations/AddUsersToRelations.util";
import { EventTypes, useAmplitude } from "@/service/TrackingService";
import { NodeRelationTypeWithUser } from "@/utils/FetchNodeRelations/FetchNodeRelations.type";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { GetAiSuggestionsAxiosResponseSuccessType, SpaceSuggestionType } from "@/axios/AiService/Spaces/Types/GetAiSuggestions/GetAiSuggestions.type";
import { useSpacePageCommon } from "../SpacePageCommon/SpacePageCommon.provider";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { GetActivitiesDetailsFetch } from "@/axios/SenseFacade/ActivitiesDetails/ActivitiesDetails.api";
import { ActivitiesDetailsAxiosResponseSuccessType, ActivityType } from "@/axios/SenseFacade/ActivitiesDetails/Types/Get/Get.type";
import { useDebounce } from "@/hooks/UseDebounce/UseDebounce.hook";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { RelationActionType } from "@/types/Relations/Relations.type";
import { GetNodesApplicationsFetch } from "@/axios/AiService/Nodes/Nodes.api";
import { NodesApplicationsAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Applications/Applications.type";
import { ActivityTypeWithUser } from "../ForYouPage/ForYouPage.type";

const defaultValue: SpacePageContextType = {
    update: () => undefined,
    selectedApps: [],
    setSelectedApps: () => undefined,
    selectedRelations: [],
    setSelectedRelations: () => undefined,
    selectedPeople: [],
    setSelectedPeople: () => undefined,
    listOfRecentActivities: [],
    listOfLastRecentActivities: [],
    updateNodeNameById: () => undefined,
    activeTab: 'UPDATES',
    setActiveTab: () => undefined,
    listOfAllResources: [],
    listOfEmails: [],
    listOfNoteResentActivities: [],

    isFetchingAllResources: true,
    isFetchingRecentActivities: true,
    isFetchingUpdates: true,
    isFetchingListOfLastRecentActivities: true,
    listOfSuggestions: [],
    isFetchingListOfSpaceSuggestions: true,
    isFetchingNoteRecentActivities: true,
};

const SpacePageContext = createContext<SpacePageContextType>(defaultValue);
export const useSpacePage = () => useContext<SpacePageContextType>(SpacePageContext);

export const SpacePageProvider = (props: { children: ReactNode }) => {
    const router = useRouter();
    const { isSharedSpace } = useIsShared();
    
    const [isFetchingListOfLastRecentActivities, setIsFetchingListOfLastRecentActivities] = useState<boolean>(true);
    const [isFetchingAllResources, setIsFetchingAllResources] = useState<boolean>(true);
    const [isFetchingRecentActivitiesWithFilters, setIsFetchingRecentActivitiesWithFilters] = useState<boolean>(false);
    const [isFetchingListOfSpaceSuggestions, setIsFetchingListOfSpaceSuggestions] = useState<boolean>(true);
    const [isFetchingNoteRecentActivities, setIsFetchingNoteRecentActivities] = useState<boolean>(false);

    const [activeTab, setActiveTab] = useState<TabValueType>('UPDATES');
    const [listOfRecentActivitiesWithFilters, setListOfRecentActivitiesWithFilters] = useState<SpacesNodeWrapperType[]>([]);
    const [listOfLastRecentActivities, setListOfLastRecentActivities] = useState<SpacesNodeWrapperType[]>([]);
    const [listOfAllResources, setListOfAllResources] = useState<SpaceNodeTypeWithRelationships[]>([]);
    const [selectedApps, setSelectedApps] = useState<number[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const [selectedRelations, setSelectedRelations] = useState<RelationActionType[]>([]);
    const { user, users, isIntegrationInProgress } = useMe();
    const spaceId = router.params.spaceId as string | undefined;
    const code = router.params.code as string | undefined;
    const [listOfSuggestions, setListOfSuggestions] = useState<SpaceSuggestionType[]>([]);
    const [listOfNoteResentActivities, setListOfNoteResentActivities] = useState<ActivityTypeWithUser[]>([]);
    const { closeRenameNodeModal } = useModal();
    
    const {
        listOfSpaceApplications,
        listOfSpaceUsers,
        update: updateSpacePageCommon,
    } = useSpacePageCommon();
    useEffect(() => {
        setSelectedApps([]);
    }, [listOfSpaceApplications])
    useEffect(() => {
        setSelectedPeople([]);
    }, [listOfSpaceUsers])

    const loadSuggestions = useCallback(async () => {
        if (!isSharedSpace) {
            if (!spaceId) { return }
            setIsFetchingListOfSpaceSuggestions(true);
            await GetSpaceSuggestionsFetch(spaceId).then((res: GetAiSuggestionsAxiosResponseSuccessType) => {
                setListOfSuggestions(res.data);
            });
            setIsFetchingListOfSpaceSuggestions(false);
        } else {
            setIsFetchingListOfSpaceSuggestions(false);
        }
    }, [isSharedSpace, spaceId])
    const fetchPublicRecentActivities = useCallback(async (code: string | undefined, offset: number, limit: number): Promise<{
        node: RecentActivityType;
        relationships?: NodeRelationTypeWithUser
    }[]> => {
        if (!code) { return [] }
        return await GetUpdatesFetch({
            code,
            offset,
            limit
        }).then((res: UpdatesAxiosResponseSuccessType) => {
            const recentActivities = res.data.activityDetails.map((activity) => activity.node);
            const listOfRelationships = addUsersToRelations(users, res.data.activityDetails.filter(el => !!el.relationships).map(el => el.relationships!));
            return recentActivities.map((node) => {
                const relationships = listOfRelationships.find(relation => relation?.nodeId === node.id);
                return {
                    node,
                    relationships
                }
            });
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred! Please try again later!');
            return []
        });
    }, [users])
    const isSelectedFilters = selectedApps.length > 0 || selectedPeople.length > 0 || selectedRelations.length > 0;
    const useInfiniteScrollCallback = useCallback(async (offset: number, limit: number): Promise<(SpacesNodeWrapperType)[] | false> => {
        if (isSharedSpace) {
            if (!code) { return [] }
            const recentActivitiesWithRelationships = await fetchPublicRecentActivities(code, offset, limit);
            return recentActivitiesWithRelationships.map(wrapper => {
                const lastActor = findLastActor(wrapper.relationships?.actors || []);
                return {
                    ...wrapper,
                    activities: [],
                    importantUserIds: [],
                    lastActor
                }
            });
        } else {
            if (!isSelectedFilters) {
                if (!spaceId) { return [] }
                return await GetActivitiesDetailsFetch({
                    spaceId: spaceId,
                    offset,
                    limit,
                }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
                    const recentActivities = res.data.activities.map((wrapper) => {
                        const relationships = addUsersToRelations(users, [wrapper.relationships])[0];
                        const lastActor = findLastActor(relationships?.actors || []);
                        return {
                            node: wrapper.node,
                            activities: wrapper.message || [],
                            relationships,
                            lastActor
                        }
                    });
                    
                    const recentActivitiesNodeIds = recentActivities.map((wrapper) => wrapper.node.id);
                    const importantUsers = await fetchImportantUsers(recentActivitiesNodeIds);
                    const nodesWithImportantUsers = recentActivities.map((el) => {
                        const info = importantUsers.find(info => info.nodeId === el.node.id)!
                        return {
                            ...el,
                            importantUserIds: info.userIds
                        }
                    })
                    return nodesWithImportantUsers;
                }).catch(() => {
                    ToastService.showToast('error', 'Some error occurred!');
                    return []
                });
            }
            return false;
        }
    }, [isSelectedFilters, isSharedSpace, spaceId, code, users, fetchPublicRecentActivities]);
    const {
        items: listOfRecentActivitiesWithoutFilters,
        setItems: setLlistOfRecentActivitiesWithoutFilters,
        isFetching: isFetchingRecentActivitiesWithoutFilters,
        refresh:
        refreshScroll
    } = useInfiniteScroll(useInfiniteScrollCallback, {
        disabled: isSelectedFilters || activeTab !== 'ACTIVITIES'
    });

    const loadNodesWithFilters = useCallback(async () => {
        if (activeTab !== 'ACTIVITIES') { return }
        if (!isSelectedFilters) { return }
        if (isSharedSpace) {
            if (!code) { return [] }
            setIsFetchingRecentActivitiesWithFilters(true);
            await GetUpdatesFetch({
                code,
                apps: selectedApps,
                users: selectedPeople,
            }).then(async (res: UpdatesAxiosResponseSuccessType) => {
                const recentActivities = res.data.activityDetails.map((activity) => activity.node);
                const listOfRelationships = addUsersToRelations(users, res.data.activityDetails.filter(el => !!el.relationships).map(el => el.relationships!));
                const nodeIds = res.data.activityDetails.map((el) => el.node.id)
                const importantUsers = await fetchImportantUsers(nodeIds);
                setListOfRecentActivitiesWithFilters(recentActivities.map(node => {
                    const relationships = listOfRelationships.find(relation => relation?.nodeId === node.id);
                    const importantInfo = importantUsers.find(info => info.nodeId === node.id)!;
                    const lastActor = findLastActor(relationships?.actors || []);
                    return {
                        node,
                        activities: [],
                        relationships,
                        importantUserIds: importantInfo.userIds,
                        lastActor
                    }
                }));
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred! Please try again later!');
                return []
            });
            setIsFetchingRecentActivitiesWithFilters(false);
        } else {
            if (!spaceId) { return }
            setIsFetchingRecentActivitiesWithFilters(true);
            await GetActivitiesDetailsFetch({
                spaceId: spaceId,
                apps: selectedApps,
                users: selectedPeople,
                relations: selectedRelations
            }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
                const recentActivities = res.data.activities.map((wrapper) => {
                    const relationships = addUsersToRelations(users, [wrapper.relationships])[0];
                    const lastActor = findLastActor(relationships?.actors || []);
                    return {
                        node: wrapper.node,
                        activities: wrapper.message || [],
                        relationships: addUsersToRelations(users, [wrapper.relationships])[0],
                        lastActor
                    }
                });
                
                const recentActivitiesNodeIds = recentActivities.map((wrapper) => wrapper.node.id);
                const importantUsers = await fetchImportantUsers(recentActivitiesNodeIds);
                const nodesWithImportantUsers = recentActivities.map((el) => {
                    const info = importantUsers.find(info => info.nodeId === el.node.id)!
                    return {
                        ...el,
                        importantUserIds: info.userIds
                    }
                })
                setListOfRecentActivitiesWithFilters(nodesWithImportantUsers)
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred!');
            });
            setIsFetchingRecentActivitiesWithFilters(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedApps, activeTab, selectedPeople, selectedRelations, spaceId, users, isSharedSpace]);
    const loadAllResources = useCallback(async () => {
        if (activeTab !== 'ALL_RESOURCES' && activeTab !== 'EMAILS') { return }
        setIsFetchingAllResources(true);
        if (isSharedSpace) {
            if (!code) { return }
            await GetAllResourcesFetch({
                code
            }).then(async (res: AllResourcesAxiosResponseSuccessType) => {
                const listOfRelationships = await addUsersToRelations(users, res.data.resourceDetails.filter(el => !!el.relationships).map(el => el.relationships!));
                const wrapper = res.data.resourceDetails.map((wrapper) => {
                    const relationships = listOfRelationships.find(relation => relation?.nodeId === wrapper.node.id);
                    const lastActor = findLastActor(relationships?.actors || []);
                    return {
                        node: wrapper.node,
                        relationships,
                        importantUserIds: [],
                        lastActor
                    }
                });
                setListOfAllResources(wrapper);
            });
        } else {
            if (!spaceId) { return }
            await GetSpacesNodesFetch(spaceId).then(async (res: SpacesNodesAxiosResponseSuccessType) => {
                const nodesIds = res.data.map((node) => node.id);
                const listOfRelationships = await fetchNodeRelations(users, nodesIds);
                const importantUsers = await fetchImportantUsers(nodesIds);
                const wrapper = res.data.map((node) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === node.id)!;
                    const relationships = listOfRelationships.find(relation => relation?.nodeId === node.id);
                    const lastActor = findLastActor(relationships?.actors || []);
                    return {
                        node: node,
                        relationships,
                        importantUserIds: importantInfo.userIds,
                        lastActor
                    }
                });
                setListOfAllResources(wrapper);
            });
        }
        setIsFetchingAllResources(false);
    }, [code, activeTab, isSharedSpace, spaceId, users]);
    const fetchLastRecentActivities = useCallback(async (offset: number, limit: number): Promise<ActivityType[]> => {
        if (!spaceId) { return []}
        return await GetActivitiesDetailsFetch({
            spaceId: spaceId,
            offset,
            limit,
        }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
            return res.data.activities;
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
            return [];
        });
    }, [spaceId]);
    useEffect(() => {
    }, [isIntegrationInProgress]);
    const loadListOfLastRecentActivities = useCallback(async () => {
        if (activeTab !== 'UPDATES') {
            setIsFetchingListOfLastRecentActivities(false);
            return
        }
        const offset = 0;
        const limit = 8;
        if (isSharedSpace) {
            if (!code) {
                setIsFetchingListOfLastRecentActivities(false);
                return
            }
            setIsFetchingListOfLastRecentActivities(true);
            const recentActivitiesWithRelationships = await fetchPublicRecentActivities(code, offset, limit);
            const nodesWithActivities = recentActivitiesWithRelationships.map(wrapper => {
                const lastActor = findLastActor(wrapper.relationships?.actors || []);
                return {
                    ...wrapper,
                    activities: [],
                    importantUserIds: [],
                    lastActor
                }
            });
            setIsFetchingListOfLastRecentActivities(false);
            setListOfLastRecentActivities(nodesWithActivities)
        } else {
            setIsFetchingListOfLastRecentActivities(true);
            const recentActivitiesData = await fetchLastRecentActivities(offset, limit);
            setIsFetchingListOfLastRecentActivities(false);
            if (!spaceId) {
                setIsFetchingListOfLastRecentActivities(false);
                return
            }
            const recentActivities = recentActivitiesData.map((wrapper) => {
                return {
                    node: wrapper.node,
                    activities: wrapper.message || [],
                    relationships: addUsersToRelations(users, [wrapper.relationships])[0]
                }
            });
            
            const recentActivitiesNodeIds = recentActivities.map((wrapper) => wrapper.node.id);
            setIsFetchingListOfLastRecentActivities(true);
            const importantUsers = await fetchImportantUsers(recentActivitiesNodeIds);
            setIsFetchingListOfLastRecentActivities(false);
            const nodesWithImportantUsers = recentActivities.map((wrapper) => {
                const info = importantUsers.find(info => info.nodeId === wrapper.node.id)!;
                const lastActor = findLastActor(wrapper.relationships?.actors || []);
                return {
                    ...wrapper,
                    importantUserIds: info.userIds,
                    lastActor
                }
            })
            setListOfLastRecentActivities(nodesWithImportantUsers);
        }
    }, [activeTab, code, fetchLastRecentActivities, fetchPublicRecentActivities, isSharedSpace, spaceId, users]);

    const loadNoteApplication = useCallback(async (): Promise<number | null> => {
        return await GetNodesApplicationsFetch({
            name: 'Sense Note',
        }).then(async (res: NodesApplicationsAxiosResponseSuccessType) => {
            const note = res.data.find((app) => app.name === 'Sense Note');
            return note?.id || null;
        }).catch(() => {
            ToastService.showToast('error', 'Some error occurred!');
            return null;
        });
    }, []);
    const loadRecentActivitiesNotes = useCallback(async () => {
        if (!user) {
            return
        }
        if (activeTab !== "NOTES") {
            return
        }
        setIsFetchingNoteRecentActivities(true);
        const noteApplicationId = await loadNoteApplication();
        if (noteApplicationId) {
            await GetActivitiesDetailsFetch({
                spaceId: spaceId,
                apps: [noteApplicationId]
            }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
                const nodeIds = res.data.activities.map((activity) => activity.node.id)
                const importantUsers = await fetchImportantUsers(nodeIds);
                const results = res.data.activities.map((activity) => {
                    const importantInfo = importantUsers.find(info => info.nodeId === activity.node.id)!
                    const actors = activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                        return {
                            ...actor,
                            user: users.find((user) => user.id === actor.userId)
                        }
                    });
                    const lastActor = findLastActor(actors);
                    return {
                        node: activity.node,
                        messages: activity.message || [],
                        relationships: {
                            ...activity.relationships,
                            actors: activity.relationships.actors.filter(el => el.userId===user.id).map((actor) => {
                                return {
                                    ...actor,
                                    user: users.find((user) => user.id === actor.userId)
                                }
                            })
                        },
                        importantUserIds: importantInfo.userIds,
                        lastActor: lastActor
                    }
                });
                setListOfNoteResentActivities(results);
            }).catch(() => {
                ToastService.showToast('error', 'Some error occurred!');
            });
        }
        setIsFetchingNoteRecentActivities(false);
    }, [activeTab, loadNoteApplication, spaceId, user, users]);
    const updateHandler = useCallback(async () => {
        updateSpacePageCommon();
        loadSuggestions();
        loadAllResources();
        loadRecentActivitiesNotes();
        loadListOfLastRecentActivities();
        refreshScroll();
        loadNodesWithFilters();
    }, [updateSpacePageCommon, loadSuggestions, refreshScroll, loadNodesWithFilters, loadAllResources, loadRecentActivitiesNotes, loadListOfLastRecentActivities]);
    
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const checkLastActivities = useDebounce(async () => {
        const lastActivities = await fetchLastRecentActivities(0, 1);
        if (lastActivities.length && !listOfLastRecentActivities.length) {
            updateHandler();
        } else {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                checkLastActivities();
            }, 5000);
        }
    }, 500, [fetchLastRecentActivities, listOfLastRecentActivities.length, updateHandler]);

    useEffect(() => {
        if (isIntegrationInProgress) {
            checkLastActivities();
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntegrationInProgress]);

    useEffect(() => {
        loadListOfLastRecentActivities();
    }, [loadListOfLastRecentActivities]);
    useEffect(() => {
        loadSuggestions();
    }, [loadSuggestions]);
    useEffect(() => {
        loadNodesWithFilters();
    }, [loadNodesWithFilters]);
    useEffect(() => {
        loadAllResources();
    }, [loadAllResources]);
    useEffect(() => {
        loadRecentActivitiesNotes();
    }, [loadRecentActivitiesNotes]);
    useEffect(() => {
        setListOfAllResources([]);
    }, [spaceId]);
    useEffect(() => {
        setActiveTab('UPDATES');
    }, [spaceId]);
    const trackEvent = useAmplitude();
    useEffect(() => {
        if (router.name === 'publicSpace') {
            trackEvent(EventTypes.SHARED_SPACE_VISITED)
        }
    }, [router.name, trackEvent]);
    const updateNodeNameById = (id: number, name: string) => {
        const updatedListOfRecentActivitiesWithoutFilters = listOfRecentActivitiesWithoutFilters.map((el) => {
            if (el.node.id === id) {
                return { ...el.node, name: name };
            }
            return el;
        }) as typeof listOfRecentActivitiesWithFilters;
        setLlistOfRecentActivitiesWithoutFilters(updatedListOfRecentActivitiesWithoutFilters)
        const updatedListOfRecentActivitiesWithFilters = listOfRecentActivitiesWithFilters.map((el) => {
            if (el.node.id === id) {
                return { ...el.node, name: name };
            }
            return el;
        }) as typeof listOfRecentActivitiesWithFilters;
        setListOfRecentActivitiesWithFilters(updatedListOfRecentActivitiesWithFilters);
        updateSpacePageCommon();
        closeRenameNodeModal()
    }
    const isFetchingRecentActivities = isFetchingRecentActivitiesWithoutFilters || isFetchingRecentActivitiesWithFilters;
    const setActiveTabHandler = (tab: TabValueType) => {
        setSelectedApps([]);
        setSelectedPeople([]);
        setActiveTab(tab);
    }
    
    const listOfEmails = useMemo(() => {
        return listOfAllResources.filter(wrapper => wrapper.node.categories.includes('email'))
    }, [listOfAllResources]);
    useEffect(() => {
        const handleUpdate = () => {
            updateHandler();
        };
        window.addEventListener('updateDataByNodeDrawer', handleUpdate);
        return () => {
            window.removeEventListener('updateDataByNodeDrawer', handleUpdate);
        };
      }, [updateHandler]);
      
    const contextValue: SpacePageContextType = {
        update: updateHandler,
        selectedApps, setSelectedApps,
        selectedPeople, setSelectedPeople,
        selectedRelations, setSelectedRelations,
        listOfRecentActivities: isSelectedFilters ? listOfRecentActivitiesWithFilters : listOfRecentActivitiesWithoutFilters,
        listOfLastRecentActivities: listOfLastRecentActivities
        .sort((a, b) => {
            const aDate = a.node.createdAt ? new Date(a.node.createdAt) : new Date(0);
            const bDate = b.node.createdAt ? new Date(b.node.createdAt) : new Date(0);
            return bDate.getTime() - aDate.getTime();
        }),
        updateNodeNameById,
        activeTab,
        setActiveTab: setActiveTabHandler,
        listOfAllResources,
        listOfEmails,
        listOfSuggestions,
        listOfNoteResentActivities,

        isFetchingRecentActivities,
        isFetchingListOfLastRecentActivities,
        isFetchingUpdates: isFetchingListOfLastRecentActivities,
        isFetchingAllResources,
        isFetchingListOfSpaceSuggestions,
        isFetchingNoteRecentActivities,
    };

    return <SpacePageContext.Provider value={contextValue}>
        {props.children}
    </SpacePageContext.Provider>;
};
