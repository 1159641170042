import { createContext, useState, useContext, useEffect, useMemo } from "react";
import { OnboardingPageContextType, OnboardingPageProviderProps, SelectedAppsType, StepType } from "./OnboardingPage.type";
import { AppTypes, AppTypesKeys } from "@/types/AppTypes/AppTypes.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { communicationsApps, documentsApps, otherApps, projectsApps, storagesApps } from "./OnboardingPage.const";
import { AppType } from "./components/ListOfApps/components/App/App.type";

const initialChannelContext: OnboardingPageContextType = {
  step: 'manageDocuments',
  title: '',
  nextStep: () => undefined,
  previousStep: () => undefined,
  skipStep: () => undefined,
  progress: 0,
  selectedAllApps: [],
  selectedPageApps: [],
  setSelectedPageApps: () => undefined,
}
const ONBOARDING_PAGES: StepType[] = ['manageDocuments', 'manageProjects', 'manageCommunications', 'manageStarages', 'manageOtherApps', 'connectApps'];
const OnboardingPageContext = createContext<OnboardingPageContextType>(initialChannelContext);
export const useOnboardingPage = () => useContext<OnboardingPageContextType>(OnboardingPageContext);

export const APPS: AppTypesKeys[] = ['Slack', 'Jira', 'Asana', 'Github', 'ClickUp', 'Trello', 'Figma', 'Confluence', 'MicrosoftTeams', 'Notion', 'TeamSpaces'];
const OnboardingPageProvider = ({ children }: OnboardingPageProviderProps) => {
  const selectedAppsStore = localStorage.getItem('selectedApps');
  const [step, setStep] = useState<StepType>('manageDocuments');
  const [progress, setProgress] = useState<number>(0);
  const [selectedApps, setSelectedApps] = useState<SelectedAppsType>(selectedAppsStore ? JSON.parse(selectedAppsStore) : {
    manageDocuments: [],
    manageProjects: [],
    manageCommunications: [],
    manageStarages: [],
    manageOtherApps: [],
    connectApps: [],
  });
  const [selectedPageApps, setSelectedPageApps] = useState<AppTypes[]>([]);
  const router = useRouter();
  useEffect(() => {
    localStorage.setItem('selectedApps', JSON.stringify(selectedApps));
  }, [selectedApps]);
  useEffect(() => {
    for (let i = 0; i < ONBOARDING_PAGES.length; i++) {
      const onboardingPage = ONBOARDING_PAGES[i];
      if (onboardingPage === step) {
        setProgress(1 / (ONBOARDING_PAGES.length+1) * (i + 1));
        break;
      }
    }
  }, [step]);
  useEffect(() => {
    if (router.name === 'connectApps') {
      setStep('connectApps');
    }
  }, [router.name]);
  const appGroups: { [key: string]: AppType[] | undefined } = {
    manageDocuments: documentsApps,
    manageProjects: projectsApps,
    manageCommunications: communicationsApps,
    manageStarages: storagesApps,
    manageOtherApps: otherApps,
    connectApps: undefined
  };

  const updateSelectedApps = (currentStep: StepType) => {
    const currentApps = appGroups[currentStep];
    if (currentStep !== 'connectApps' && currentApps) {
      const seletedStepApps = selectedApps[currentStep];
      currentApps.forEach(app => {
        if (selectedPageApps.includes(app.title)) {
          !seletedStepApps.includes(app.title) && setSelectedApps(prevApps => {
            return {
              ...prevApps,
              [currentStep]: [...prevApps[currentStep], app.title]
            }
          });
        } else {
          seletedStepApps.includes(app.title) && setSelectedApps(prevApps => {
            return {
              ...prevApps,
              [currentStep]: prevApps[currentStep].filter(v => v !== app.title)
            }
          });
        }
      });
      setSelectedPageApps([]);
    }
  };
  const seletedStepApps = useMemo(() => selectedApps[step], [selectedApps, step]);
  
  const selectedAllApps = useMemo(() => {
    const allApps: AppTypes[] = [];
    for (const key in selectedApps) {
      allApps.push(...selectedApps[key as StepType]);
    }
    return Array.from(new Set(allApps));
  }, [selectedApps]);
  useEffect(() => {
    const nextApps = appGroups[step];
    if (nextApps) {
      nextApps.forEach(app => {
        if (seletedStepApps.includes(app.title)) {
          !selectedPageApps.includes(app.title) && setSelectedPageApps(prevApps => [...prevApps, app.title]);
        } else {
          selectedPageApps.includes(app.title) && setSelectedPageApps(prevApps => prevApps.filter(v => v !== app.title));
        }
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const nextStep = () => {
    const currentStepIndex = ONBOARDING_PAGES.findIndex((onboardingPage) => onboardingPage === step);
    if (currentStepIndex === -1) {
      return;
    }
    const currentStep = ONBOARDING_PAGES[currentStepIndex]
    const newStep = ONBOARDING_PAGES[currentStepIndex + 1]
    if (newStep === 'connectApps' && router.name !== 'connectApps') {
      if (selectedAllApps.length === 0) {
        router.push({
          name: 'noAppsPage',
        });
      } else {
        router.push({
          name: 'connectApps',
        });
      }
    }
    setStep(newStep);
    updateSelectedApps(currentStep);
  };
  const skipStep = () => {
    const currentStepIndex = ONBOARDING_PAGES.findIndex((onboardingPage) => onboardingPage === step);
    if (currentStepIndex === -1) {
      return;
    }
    const currentStep = ONBOARDING_PAGES[currentStepIndex]
    const newStep = ONBOARDING_PAGES[currentStepIndex + 1]
    if (newStep === 'connectApps' && router.name !== 'connectApps') {
      if (selectedAllApps.length === 0) {
        router.push({
          name: 'noAppsPage',
        });
      } else {
        router.push({
          name: 'connectApps',
        });
      }
    }
    setStep(newStep);
    setSelectedPageApps([]);
    const currentApps = appGroups[currentStep];
    if (currentStep !== 'connectApps' && currentApps) {
      currentApps.forEach(app => {
        seletedStepApps.includes(app.title) && setSelectedApps(prevApps => {
          return {
            ...prevApps,
            [currentStep]: prevApps[currentStep].filter(v => v !== app.title)
          }
        });
      });
    }
  };
  const previousStep = () => {
    const currentStepIndex = ONBOARDING_PAGES.findIndex((onboardingPage) => onboardingPage === step);
    if (currentStepIndex === -1 || currentStepIndex === 0) {
      return;
    }
    const newVal = ONBOARDING_PAGES[currentStepIndex - 1];
    if (newVal !== 'connectApps' && router.name !== 'manageIntegrations') {
      router.push({
        name: 'manageIntegrations',
      });
    }
    setStep(newVal);
  };
  const title = useMemo(() => {
    switch (step) {
      case 'manageDocuments':
        return 'Which apps do you use for creating documents?';
      case 'manageProjects':
        return 'Which apps do you use for project management?';
      case 'manageCommunications':
        return 'How do you communicate?';
      case 'manageStarages':
        return 'Which cloud storage do you use?';
      case 'manageOtherApps':
        return 'Any other apps you use?';
      default:
        return '';
    }
  }, [step]);
  const contextValue: OnboardingPageContextType = {
    step,
    title,
    nextStep,
    previousStep,
    skipStep,
    progress,
    selectedAllApps,
    selectedPageApps,
    setSelectedPageApps,
  };

  return (
    <OnboardingPageContext.Provider value={contextValue}>
      {children}
    </OnboardingPageContext.Provider>
  );
};

export { OnboardingPageContext, OnboardingPageProvider };