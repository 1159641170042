import { Button } from "@/components/v3/Fields/Button/Button.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { styled } from "@mui/material";
import { Input } from "../../Fields/Input/Input.component";

export const StyledDialogLayout = styled(DialogLayout)`
    min-width: 720px;
`;
export const StyledInput = styled(Input)`
    margin-top: 24px;
`;
export const StyledBackButton = styled(Button)`
    margin-right: 8px;
`;