import { FC } from "react";
import { PersonProps } from "./Person.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { StyledPersonWrapper } from "./Person.styled";
import { Person as MainPerson } from "@/components/v3/List/Person/Person.component";

export const Person: FC<PersonProps> = ({ person }) => {
  const router = useRouter();
  const isActive = router.params.fromUserId === person.id;
  return <>
    <StyledPersonWrapper
      onClick={() => {
        router.push({
          name: 'commonRelations',
          params: {
            fromUserId: person.id
          }
        })
      }}
      active={router.name==='commonRelations' && isActive}
    >
      <MainPerson
        imgSize="medium"
        person={{
          ...person,
          email: ''
        }}
      />
    </StyledPersonWrapper>
  </>
}