import { FC } from "react";
import { StyledNode, StyledNodesWrapper, StyledWrapper } from "./RelatedResources.styled";
import { RelatedResourcesProps } from "./RelatedResources.type";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useNode } from "@/providers/v3/NodeProvider/Node.provider";
import { EventTypes, useAmplitude } from "@/service/TrackingService";

export const RelatedResources: FC<RelatedResourcesProps> = ({
    listOfNodes, onUpdate
}) => {
    const { users } = useMe();
    const { setActiveNodeId } = useNode();
    const trackEvent = useAmplitude();
    if (!listOfNodes.length) {
        return null;
    }
    const nodeClickHandler = (nodeId: number) => {
        setActiveNodeId(nodeId)
        trackEvent(EventTypes.SEARCH_RESULT_CLICKED)
      };
    const listOfNodesJsx = listOfNodes.map((node) => {
        return <StyledNode
          key={node.id}
          node={node}
          onUpdate={onUpdate}
          users={users}
          importantUserIds={[]}
          onClick={() => nodeClickHandler(node.id)}
        />;
      });
    return <StyledWrapper>
        <SectionHeader title="Related resources" />
        <StyledNodesWrapper>
            {listOfNodesJsx}
        </StyledNodesWrapper>
    </StyledWrapper>
};