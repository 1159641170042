import { ActionTypeType } from "../People/components/Person/Person.type";
import { SizeType } from "./Activity.type";
import { RelationshipsActorType } from "./components/Header/Header.type";

export const getSize = ({ size, actionType }: { size?: SizeType, actionType?: ActionTypeType }): SizeType => {
    if (!!size) {
        return size;
    }
    if (!!actionType) {
        switch (actionType) {
            case 'CREATED_BY':
            case 'CREATOR':
                return 'large';
            case 'COMMENTED_BY':
            case 'COMMENTER':
            case 'POSTED_BY':
                return 'small';
            case 'EDITED_BY':
                return 'small';
            case 'SHARED_BY':
            case 'SHARED_TO':
                return 'large';
            case 'WATCHED_BY':
                // clarify watched
                return 'small';
            case 'ASSIGNEE':
                return 'small';
            case 'WATCHER':
                // clarify is a watcher of
                return 'small';
            case 'MENTIONS':
            case 'CC':
            case 'BCC':
            case 'TO':
                return 'large';
            case 'FROM':
                // clarify sent
                return 'small';
            case 'ADDED_BY':
                // clarify added manually
                return 'small';
            default:
                return 'small';
        }
    }
    return 'small';
};

function getMostRecentAction<T extends RelationshipsActorType>(actors: T[]): T | null {
    if (actors.length && !actors[0].properties.happenedAt) {
        return actors[0];
    }
    return actors.reduce<T | null>((prev, curr) => {
        if (!prev) return curr;
        return new Date(curr.properties.happenedAt!) > new Date(prev.properties.happenedAt!) ? curr : prev;
    }, null);
}
export function findLastActor<T extends RelationshipsActorType>(actors: T[]): T | null {
    const filteredActors = actors.filter(actor => {
        return actor.actionType !== 'FOLLOWED_BY' && actor.actionType !== 'TO';
    });
    return getMostRecentAction(filteredActors);
}
