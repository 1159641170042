import { FC, useEffect, useMemo, useState } from "react";
import { SelectSpaceDialogProps } from "./SelectSpaceDialog.type";
import { DialogLayout } from "../../Layouts/DialogLayout/DialogLayout.component";
import { Input } from "../../Fields/Input/Input.component";
import { StyledCloseIcon } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/Search.styled';
import { StyledSearchIcon, StyledSearchIconWrapper } from '@/components/v3/Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled';
import { StyledFilledCard, StyledApplicationIcon, StyledSectionHeader } from './SelectSpaceDialog.styled';
import { EmptyStateLayoyt } from "../../Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { Typography } from "@mui/material";
import { Select } from "../../Fields/Select/Select.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { OptionType } from "../../Fields/Select/components/Option/Option.type";
import { APP_OPTIONS } from "./SelectSpaceDialog.const";
import { SpaceType, SpacesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { GetSpacesFetch } from '@/axios/AiService/Spaces/Spaces.api';
import { getListWithFilledNames } from "@/service/getListWithFilledNames";
import { ToastService } from "@/service/ToastService";
import { Space } from "./components/Space/Space.component";
import { Dialog } from "../../Other/Dialog/Dialog.component";

export const SelectSpaceDialog: FC<SelectSpaceDialogProps> = ({ onInput, value, onChange }) => {
    const [openSpaceId, setOpenSpaceId] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [app, setApp] = useState<string>('All');
    const [spaces, setSpaces] = useState<SpaceType[]>([]);
    const loadSpaces = async () => {
        setIsLoading(true);
        await GetSpacesFetch({}).then((res: SpacesAxiosResponseSuccessType) => {
          setSpaces(getListWithFilledNames<SpaceType>(res.data));
          setIsLoading(false);
        }).catch(() => {
          ToastService.showToast('error', 'Some error occurred! Please try again later!');
        });
    }
    useEffect(() => {
        loadSpaces()
    }, []);
    const listOfFilteredSpaces = useMemo(() => {
        const sortedSPaces = spaces.sort((a, b) => a.name.localeCompare(b.name));
        const filyeredBySearch = sortedSPaces.filter((space) => space.name.toLowerCase().includes(searchValue));
        if (app==='All') {
          return filyeredBySearch;
        }
        return filyeredBySearch.filter((space) => space.application===app);
      }, [app, searchValue, spaces]);

    const appOptions = useMemo((): (OptionType<string>)[] => {
        const appOptions: OptionType<string>[] = APP_OPTIONS.map((option) => ({
          ...option,
          icon: <StyledApplicationIcon application={option.value} />
        }));
        appOptions.unshift({
          label: "All",
          value: "All",
        });
        return appOptions;
    }, []);
    const listOfSpacesJsx = listOfFilteredSpaces.map((space) => {
        const isOpen = openSpaceId === space.id;
        return <Space space={space} onAdd={onInput} key={space.id} isOpen={isOpen} setIsOpen={(val) => {
          setOpenSpaceId(val ? space.id : '');
        }} />
    });
    return <Dialog value={value} onChange={() => onChange(false)}>
      <DialogLayout title="Add to Space" onCloseClick={() => onChange(false)} isLoading={isLoading}>
        <Input
        placeholder="Search in Sense"
        variant="filled"
        size='medium'
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        InputProps={{
            startAdornment: (
            <StyledSearchIconWrapper>
                <StyledSearchIcon />
            </StyledSearchIconWrapper>
            ),
            endAdornment: <>
            {!!searchValue && <StyledCloseIcon onClick={() => setSearchValue('')} />}
            </>,
        }}
        />
        <StyledFilledCard>
            <EmptyStateLayoyt
                value={!listOfFilteredSpaces.length}
                emptyNode={<Typography variant="body1" style={{ padding: '0 24px' }}>
                Nothing found. Try to change filter
                </Typography>}
            >
                <StyledSectionHeader title="Spaces" >
                {appOptions.length && <Select
                    label="Apps"
                    value={app}
                    onChange={setApp}
                    options={appOptions}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    icon={<FilterIcon />}
                />}
                </StyledSectionHeader>
                <div>
                {listOfFilteredSpaces.length > 0 && <>
                    {listOfSpacesJsx}
                </>}
                </div>
            </EmptyStateLayoyt>
        </StyledFilledCard>
    </DialogLayout>
  </Dialog>
};