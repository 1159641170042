import { FC } from "react";
import { MenuItemProps } from "./MenuItem.type";
import { EventTypes, useAmplitude } from 'service/TrackingService';
import { useRouter } from "@/providers/Router/Router.provider";
import { WalkthroughWrapper } from "@/providers/WalkthroughProvider/components/WalkthroughWrapper/WalkthroughWrapper.component";
import { StyledImg, StyledNavigationItem, StyledNavigationItemWrapper } from "./MenuItem.styled";

export const MenuItem: FC<MenuItemProps> = ({
    route,
    title,
    icon,
    handleDrawerClosing,
    walkthrough,
    actions
}) => {
    const trackEvent = useAmplitude();
    const router = useRouter();
    const isActive = route.name === router.name && route.query?.dialog !== 'search';
    const item = <StyledNavigationItem
      active={isActive}
      id={walkthrough?.targetId}
      actions={!!actions}
    >
      <StyledNavigationItemWrapper
        onClick={() => {
          if (handleDrawerClosing) {
            handleDrawerClosing();
          }
          trackEvent(EventTypes.MENU_CLICK, { uri: router.getPathWithParams(route.name) });
          router.push(route);
        }}
      >
        <StyledImg src={icon} avatar={route.name === "forYou"} />
        {title}
      </StyledNavigationItemWrapper>
      {actions}
    </StyledNavigationItem>
      return (<>{walkthrough ? <WalkthroughWrapper {...walkthrough}>
        {item}
      </WalkthroughWrapper> : item}</>
      );
}