import { styled } from "@mui/material";
const RIGHT_SIDEBAR_WIDTH = 360;
const MAIN_SIDEBAR_WIDTH = 320;
const HEADER_HEIGHT = 56;

export const StyledMainLayout = styled("div")`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    height: 100vh;
    width: 100%;
`;
export const StyledMainSidebar = styled("div")`
    width: ${MAIN_SIDEBAR_WIDTH}px;
    height: 100%;
    background-color: #EBEBEB;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
`;
export const StyledMainHeader = styled("div")`
    height: ${HEADER_HEIGHT}px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 24px;
    @media (max-width: 768px) {
        padding: 8px;
    }
`;
export const StyledMainWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "withSidebar",
})<{withSidebar: boolean}>`
    width: ${({withSidebar}) => withSidebar ? `calc( 100% - ${MAIN_SIDEBAR_WIDTH}px );` : `100%;`};
    height: 100%;
`;
export const StyledMainContentWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "displayHeader",
})<{displayHeader: boolean}>`
    height: ${({displayHeader}) => displayHeader ? `calc( 100% - ${HEADER_HEIGHT}px );` : `100%;`};
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
`;
export const StyledMainContent = styled("div", {
    shouldForwardProp: (prop) => prop !== "useSidebar",
})<{useSidebar: boolean;}>`
    width: ${({useSidebar}) => useSidebar ? `calc( 100%  - ${RIGHT_SIDEBAR_WIDTH}px );` : `100%;`};
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`;
export const StyledRightSidebar = styled("div")`
    width: ${RIGHT_SIDEBAR_WIDTH}px;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.06);
    }
`;