import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { TeamSpacesDropdownProps } from './TeamSpacesDropdown.type';
import { StyledConnect, StyledConnectBtn, StyledDropDown, StyledListItem, StyledListItemCount } from './TeamSpacesDropdown.styled';
import { DropdownField } from '@/components/v3/Fields/DropdownField/DropdownField.component';
import { Divider } from '@/components/v3/Other/Divider/Divider.component';
import { useRouter } from '@/providers/Router/Router.provider';
import { StyledAddIcon } from '../../../../Sidebar.styled';

export const TeamSpacesDropdown = (
  {
    teamSpaces,
    mySpaces,
    jiraSpaces,
    asanaSpaces,
    githubSpaces,
    clickUpSpaces,
    trelloSpaces,
    microsoftTeamSpaces,
    confluenceSpaces,
    slackSpaces,
    notionSpaces,
    setNode
  }: TeamSpacesDropdownProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [title, setTitle] = useState('My Spaces');
  const titleFromLocalStorage = localStorage.getItem('selectedSpace');
  const history = useHistory();
  const router = useRouter();

  const nodes = [
    { id: 1, iconPath: '/icons/confluence.svg', title: 'Confluence', count: confluenceSpaces?.length },
    { id: 2, iconPath: '/icons/icon-asana.png', title: 'Asana', count: asanaSpaces?.length },
    { id: 2, iconPath: '/icons/icon-github.png', title: 'GitHub', count: githubSpaces?.length },
    { id: 2, iconPath: '/icons/icon-click_up.png', title: 'ClickUp', count: clickUpSpaces?.length },
    { id: 2, iconPath: '/icons/icon-trello.png', title: 'Trello', count: trelloSpaces?.length },
    { id: 2, iconPath: '/icons/jira.svg', title: 'Jira', count: jiraSpaces?.length },
    { id: 3, iconPath: '/icons/slack.svg', title: 'Slack', count: slackSpaces?.length },
    { id: 4, iconPath: '/icons/teams.svg', title: 'MicrosoftTeams', count: microsoftTeamSpaces?.length },
    { id: 5, iconPath: '/icons/icon-notion.svg', title: 'Notion', count: notionSpaces?.length },
  ];

  const isEmptySpace = confluenceSpaces?.length || asanaSpaces?.length || githubSpaces?.length || clickUpSpaces?.length || trelloSpaces?.length || jiraSpaces?.length || slackSpaces?.length || microsoftTeamSpaces?.length || notionSpaces?.length;

  const setNodeHandlerClick = (nodeTitle: string) => {
    setNode(nodeTitle);
    console.log('nodeTitle', nodeTitle);
    
    if (nodeTitle === 'Sense') {
      setTitle('Team Spaces');
      localStorage.setItem('selectedSpace','Sense');
    } else {
      setTitle(nodeTitle);
      localStorage.setItem('selectedSpace', nodeTitle);
    }
    setIsOpened(false);
  }

  useEffect(() => {
    if (titleFromLocalStorage === 'Sense') {
      setTitle('Team Spaces')
    } else {
      titleFromLocalStorage && setTitle(titleFromLocalStorage)
    }
  }, [titleFromLocalStorage])
  const integrationSpacesJsx = nodes.filter(node => node.count).map((node, i) => {
    return <StyledListItem
      title={node.title}
      img={<img src={node.iconPath} alt="space" width="24" height="24" />}
      onClick={() => {setNodeHandlerClick(node.title)}}
    >
      <StyledListItemCount>{node.count}</StyledListItemCount>
    </StyledListItem>
  });
  return (
    <>
      <DropdownField 
        label={title} 
        onChange={setIsOpened}
        value={isOpened}
        variant='tetrinary'
      >
        <StyledDropDown>
          <StyledListItem
            title="My Spaces"
            onClick={() => {setNodeHandlerClick('My Spaces')}}
          >
            <StyledListItemCount>{mySpaces?.length}</StyledListItemCount>
          </StyledListItem>
          <Divider />
          <StyledListItem
            title="All spaces"
            onClick={() => {setNodeHandlerClick('All Spaces')}}
          />
          <Divider />
          <StyledListItem
            title="Team Spaces"
            img={<img src="/icons/space.svg" alt="space" width="24" height="24" />}
            onClick={() => {setNodeHandlerClick('Sense')}}
          >
            <StyledListItemCount>{teamSpaces?.length}</StyledListItemCount>
          </StyledListItem>
          <Divider />
          {integrationSpacesJsx}
          <Divider />
          <StyledListItem
            title="Create space"
            img={<StyledAddIcon />}
            onClick={() => {
              router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  dialog: 'create-space'
                }
              });
              setIsOpened(false);
            }}
          />
          {!isEmptySpace && <Box>
            <StyledConnect>Connect apps to import your existing projects or spaces to Sense</StyledConnect>
            <StyledConnectBtn  onClick={() => history.push(`${window.location.pathname}?dialog=manage-integrations`)}>Connect apps</StyledConnectBtn>
          </Box>}
        </StyledDropDown>
      </DropdownField>
    </>
  );
};
