import { FC } from "react";
import { GroupLayoutProps } from "./GroupLayout.type";
import { StyledFilledCard, StyledTitle, StyledWrapper } from "./GroupLayout.styled";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { GroupLayout as GroupLayout2 } from "../GroupLayout2/GroupLayout.component";

export const GroupLayout: FC<GroupLayoutProps> = (props) => {
    const {
        title, children,
    } = props;
    const { useNewActivityUI } = useFeatureFlags();
    if (useNewActivityUI) {
        return <GroupLayout2 {...props} />;
    }
    return <StyledWrapper>
        <StyledTitle>{title}</StyledTitle>
        <StyledFilledCard>
            {children}
        </StyledFilledCard>
    </StyledWrapper>
};