const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
const host = process.env.REACT_APP_MANAGE_INTEGRATION_HOST;

type OptionsType = {
    spaceApp?: string;
    spaceId?: string;
    addTab?: string;
    isOnboarding?: boolean;
}

export const openConectAppLink = (type: string, userId: string | undefined, options: OptionsType): {
    url: string;
    appPathName: string;
} => {
    const { spaceApp, spaceId, isOnboarding } = options;
    const dialogPaths: Record<string, string> = {
        'slack': 'connect-slack-channels',
        'click_up': 'connect-click-up-projects',
        'trello': 'connect-trello-boards',
        'asana': 'connect-asana-projects',
        'github': 'connect-github-projects',
        'atlassian': 'connect-atlassian-projects',
        'microsoft_teams': 'connect-microsoft-teams-projects',
    };
    const appPathName = dialogPaths[type] || 'manage-integrations';
    const prefix = isOnboarding ? `/${appPathName}` : (spaceId ? `/space/${spaceId}` : '/for-you');
    const dialogParam = isOnboarding ? `` : `dialog=${appPathName}&type=${type}`;
    const spaceAppParam = spaceApp ? `&spaceApp=${spaceApp}` : '';
    const addTabParam = options.addTab ? `&addTab=${options.addTab}` : '';
    let urlPath = `${prefix}?${dialogParam}${spaceAppParam}${addTabParam}`;
    
    const fullURL = `${window.location.origin}${urlPath}`;
    //@ts-ignore
    if (isSafari || isFirefox || chrome || chrome.hasOwnProperty('runtime') || chrome.runtime.hasOwnProperty('sendMessage')) {
        const url = `${host}/gateway/sense-facade/v1/integrations/start?userId=${userId}&type=${type}&frontendUrl=${btoa(fullURL)}`;
        return {
            url,
            appPathName
        }
        // window.open(url);
    }
    return {
        url: '',
        appPathName
    };
}