import { styled } from "@mui/material";
import { TextSkeleton } from "../../Skeleton/TextSkeleton/TextSkeleton.component";

export const StyledActivityMessage = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    border-bottom: 1px solid var(--Special-Divider-Light, rgba(0, 0, 0, 0.08));
    &:last-child {
        border-bottom: none;
    }
`;
export const StyledName = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: inline;
`;
export const StyledChannel = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: inline;
`;
export const StyledMassage = styled(TextSkeleton)`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    margin-top: 2px;
    & a {
        word-break: break-all;
        color: #636363;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
        transition: color 0.3s;
        &.mention {
            font-weight: 500;
            background: #DBEAA7;
            font-size: 14px;
            line-height: 20px;
        }
        &:hover {
            color: #202020;
            text-decoration: underline;
        }
    }
`;
export const StyledDate = styled(TextSkeleton)`
    color: #636363;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 2px;
`;