import { FC, useRef } from "react";
import { DropdownFieldProps } from "./DropdownField.type";
import { StyledArrow, StyledDropdownContainer, StyledDropdownWrapper, StyledIconWrapper, StyledLabel, StyledToggleBtn } from "./DropdownField.styled";
import { Popover } from "@mui/material";

export const DropdownField: FC<DropdownFieldProps> = ({
    value,
    label,
    onChange,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    transformOrigin = { vertical: 'top', horizontal: 'center' },
    disabled = false,
    size = 'small',
    children,
    icon,
    className,
    id,
    fullWidth,
    variant = 'standard'
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const handleButtonClick = () => {
        !disabled && onChange(true);
    };
    return <>
        <StyledToggleBtn
            className={className}
            ref={ref}
            active={value}
            withIcon={!!icon}
            size={size}
            disabled={disabled}
            onClick={handleButtonClick}
            style={{ textTransform: 'none' }}
            id={id}
            variant={variant}
        >
            {!!icon && <StyledIconWrapper>
                {icon}    
            </StyledIconWrapper>}
            <StyledLabel>{label}</StyledLabel>
            <StyledArrow
                width="16px"
                height="16px"
                active={value}
            />
        </StyledToggleBtn>
        <Popover
            open={value}
            anchorEl={ref.current}
            onClose={() => onChange(false)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <StyledDropdownContainer width={fullWidth && ref.current ? ref.current.offsetWidth : undefined} >
                <StyledDropdownWrapper>
                    {children}
                </StyledDropdownWrapper>
            </StyledDropdownContainer>
        </Popover>
    </>
};