import { FC, useMemo } from "react";
import { StyledFilledCard, StyledWrapper } from "./Users.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { UsersProps } from "./Users.type";
import { UserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { User } from "../User/User.component";
import { getFullName } from "@/utils/v3/GetFullName/GetFullName.util";

export const Users: FC<UsersProps> = ({
    search
}) => {
    const { users: organizationUsers, user } = useMe();
    const users = organizationUsers.filter((el) => {
        return el.id !== user?.id;
    });
    const compare = (a: UserType, b: UserType) => {
      if (a.firstName && b.firstName) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
      }
      return 0;
    }
    const filteredListOfUsers = useMemo(() => {
      return users.filter(person => {
          const fullName = getFullName(person.firstName, person.lastName)
          return fullName.includes(search) || person.email?.includes(search);
      })
  }, [users, search])
    if (filteredListOfUsers.length === 0) {
        return null;
    }
    const usersJsx = filteredListOfUsers.sort(compare).map(user => {
        return <User key={user.id} user={user} />
    });
    return <StyledFilledCard>
        <SectionHeader title="All people" />
        <StyledWrapper>
            {usersJsx}
        </StyledWrapper>
    </StyledFilledCard>
};