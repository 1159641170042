import { FC, useMemo, useState } from "react";
import { StyledPeopleWrapper, StyledSectionHeader } from "./People.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledAddIcon } from "../../Sidebar.styled";
import { useRouter } from "@/providers/Router/Router.provider";
import { PeopleDropdown } from "./components/PeopleDropdown/PeopleDropdown.component";
import { Person } from "./components/Person/Person.component";
import { ValueType } from "./components/PeopleDropdown/PeopleDropdown.type";
import { UserType } from "@/axios/AiService/Users/Types/Get/Get.type";

export const People: FC = () => {
    const [filter, setFilter] = useState<ValueType>('followed');
    const { users, userRelations } = useMe();
    const router = useRouter();
    
    const listOfUsers = useMemo(() => {
      if (filter === 'followed') {
        return userRelations.map((relation) => users.find((user) => user.id === relation.userId) as UserType);
      }
      return users
    }, [filter, userRelations, users]);
    const listOfUsersJsx = listOfUsers.map((user, i) => <Person person={user} key={i} />);
    
    return <>
        <StyledSectionHeader actions={<Button
          onClick={() => {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                  ...router.query,
                  dialog: 'manage-users'
              }
            });
          }}
          icon={<StyledAddIcon />}
          variant="tertiary"
          iconSize="large"
        />}>
          <PeopleDropdown value={filter} onChange={setFilter} />
        </StyledSectionHeader>
        <StyledPeopleWrapper>
            {listOfUsersJsx}
        </StyledPeopleWrapper>
    </>
};