import { FC, useState } from "react";
import { StyledWrapper } from "./Actions.styled";
import { ActionsProps } from "./Actions.type";
import { NodeMenu } from "./components/NodeMenu/NodeMenu.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { AddCommentDialog } from "./components/NodeMenu/components/AddCommentDialog/AddCommentDialog.component";
import { NodeCommentType } from "@/types/NodeComment/NodeComment.type";
import { People } from "../../../People/People.component";

export const Actions: FC<ActionsProps> = (props) => {
    const { node, people, size = 'large', onNewComment, onUpdate, hideMenuIcon, actions } = props;
    const [isOpenedAddCommentDialog, setIsOpenedAddCommentDialog] = useState<boolean>(false);
    const isMobile = useRenederBelow('tablet');
    const onNewCommentHandler = (comment: NodeCommentType) => {
        onNewComment && onNewComment(comment);
        !onNewComment && onUpdate && onUpdate();
    };
    return <StyledWrapper className="ignore--node-actions">
        {size==='large' && !isMobile && <People people={people} />}
        {!hideMenuIcon && <NodeMenu {...props} onNewComment={onNewCommentHandler} />}
        {actions}
        <AddCommentDialog nodeId={node.id} value={isOpenedAddCommentDialog} onChange={setIsOpenedAddCommentDialog} onNewComment={onNewCommentHandler} />
    </StyledWrapper>
};