import { FC } from "react";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";
import { useSpacePage } from "@/providers/v3/SpacePage/SpacePage.provider";
import { TabValueType } from "@/providers/v3/SpacePage/SpacePage.type";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { ResourcesIcon } from "@/icons/v3/resources.icon";
import { ActivitiesIcon } from "@/icons/v3/activities.icon";
import { NotesIcon } from "@/icons/v3/notes.icon";
import { PeopleIcon } from "@/icons/v3/people.icon";

export const Tabs: FC = () => {
  const { activeTab, setActiveTab } = useSpacePage();
  const shouldRenderTabsFromSidebar = useRenederBelow('medium');
  const { useNewActivityUI } = useFeatureFlags();
  return <StyledWrapper>
    {!!useNewActivityUI && <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="ACTIVITIES" icon={<ActivitiesIcon />}>Activities</StyledTab>
      <StyledTab value="ALL_RESOURCES" icon={<ResourcesIcon />}>Resources</StyledTab>
      <StyledTab value="NOTES" icon={<NotesIcon />}>Notes</StyledTab>
      {shouldRenderTabsFromSidebar && <>
        <StyledTab value="CHANNELS">Channels</StyledTab>
        <StyledTab value="PEOPLE" icon={<PeopleIcon />}>People</StyledTab>
      </>}
    </StyledTabs>}
    {!useNewActivityUI && <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="UPDATES">Updates</StyledTab>
      <StyledTab value="ALL_RESOURCES">All resources</StyledTab>
      <StyledTab value="ACTIVITIES">Activities</StyledTab>
      {shouldRenderTabsFromSidebar && <>
        <StyledTab value="CHANNELS">Channels</StyledTab>
        <StyledTab value="PEOPLE">People</StyledTab>
      </>}
    </StyledTabs>}
  </StyledWrapper>
};