import { FC, useEffect, useMemo, useState } from "react";
import { AppProps } from "./App.type";
import { StyledWrapper } from "./App.styled";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { openConectAppLink } from "@/utils/v3/OpenConectAppLink/OpenConectAppLink.util";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { SelectChannelsModal } from "./components/SelectChannelsModal/SelectChannelsModal.component";
import { AppIcon } from "@/components/v3/Other/AppIcon/AppIcon.component";
import { useChannels } from "@/providers/v3/Channels/Channels.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { AppTypesKeys } from "@/types/AppTypes/AppTypes.type";
import { useWindowOpen } from "@/hooks/UseWindowOpen/UseWindowOpen.hook";

export const App: FC<AppProps> = ({
    app,
    channels,
    setChannels
}) => {
    const { user, loadUser } = useMe();
    const { appChannels: listOfChannels, update } = useChannels();
    const [isLoadingChannels, setIsLoadingChannels] = useState<boolean>(false);
    const router = useRouter();
    const [isOpenedAddChannelsModal, setIsOpenedAddChannelsModal] = useState<boolean>(false);
    const loadChannels = async (app: AppTypesKeys) => {
      setIsLoadingChannels(true);
      const loadData = async ()=> {
        const channels = await update(app);
        if (channels.length === 0) {
          return new Promise((resolve) => {
            setTimeout(async () => {
              resolve(await loadData());
            }, 1000);
          });
        }
      };
      await loadData();
      setIsLoadingChannels(false);
    };
    const setIsOpenedAddChannelsModalHandler = (value: boolean) => {
      setIsOpenedAddChannelsModal(value);
      if (!value && !!router.query.spaceApp) {
        router.push({
          name: router.name,
          params: router.params,
          query: {
            ...router.query,
            spaceApp: undefined
          }
        })
        loadUser();
      }
    };
    useEffect(() => {
      const spaceApp = router.query.spaceApp as AppTypesKeys | undefined;
      const application = app.application;
      if (spaceApp === application) {
        setIsOpenedAddChannelsModalHandler(true);
        loadChannels(application);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.application, channels, router.query.spaceApp]);
    const type = useMemo(() => {
      if (app.title === "Asana") {
        return 'asana'
      } else if (app.title === "Github") {
        return 'github'
      } else if (app.title === "ClickUp") {
        return 'click_up'
      } else if (app.title === "Trello") {
        return 'trello'
      } else if (app.title === "Figma") {
        return 'figma'
      } else if (app.title === "Slack") {
        return 'slack'
      } else if (app.title === "Jira" || app.title === "Confluence") {
        return 'atlassian'
      } else if (app.title === 'Microsoft Teams') {
        return 'microsoft_teams'
      } else if (app.title === 'Notion') {
        return 'notion'
      } else if (app.title === 'Gmail') {
        return 'gmail'
      } else if (app.title === 'Google Drive') {
        return 'google_drive'
      } else if (app.title === 'Outlook') {
        return 'outlook'
      } else if (app.title === 'Dropbox for Business') {
        return 'dropbox_business'
      } else if (app.title === 'Google Calendar') {
        return 'google_calendar'
      }
      return 'asana'
    }, [app.title]);
    
    const {url, appPathName} = openConectAppLink(type, user?.id, {
      spaceApp: app.application,
      spaceId: router.query.dialog === 'edit-space' ? router.params.spaceId as string : undefined
    });
    const {open, href, focus, window: integrationWindow} = useWindowOpen();
    useEffect(() => {
      if (!!href) {
          const url = new URL(href);
          if (url.host === window.location.host) {
            const isSpace = router.query.dialog === 'edit-space';
            router.push({
              name: isSpace ? 'space' : 'forYou',
              params: {
                  spaceId: isSpace ? router.params.spaceId : undefined,
              },
              query: {
                dialog: appPathName,
                type: type,
                spaceApp: app.application
              }
            });
            loadUser();
          }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.application, appPathName, href, type]);
    const connectHandler = () => {
      if (!integrationWindow) {
        url && open(url)
      } else {
        focus();
      }
    }
    const selectHandler = () => {
      setIsOpenedAddChannelsModalHandler(true)
    };
    const selectedCount = useMemo((): number => {
      const applicationChannels = listOfChannels[app.application];
      return applicationChannels.map((channel) => channel.id).filter((channelId) => channels.includes(channelId)).length;
    }, [app.application, channels, listOfChannels]);
    const caption = useMemo((): string => {
      if (selectedCount > 0) {
        return `${selectedCount} ${app.channelsTitle}`
      }
      return `Add ${app.channelsTitle}`
    }, [app.channelsTitle, selectedCount]);
    return <StyledWrapper>
        <ListItem
            title={app.title}
            caption={caption}
            img={<AppIcon application={app.application} />}
        />
        {app.isConnected && <Button variant="secondary" onClick={selectHandler}>Select</Button>}
        {!app.isConnected && <Button variant="secondary" onClick={connectHandler}>Connect</Button>}
        <SelectChannelsModal
            isOpened={isOpenedAddChannelsModal}
            setIsOpened={setIsOpenedAddChannelsModalHandler}
            channels={channels}
            setChannels={setChannels}
            applicationName={app.title}
            application={app.application}
            channelsTitle={app.channelsTitle}
            isLoadingChannels={isLoadingChannels}
        />
    </StyledWrapper>
};