import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { MeContextType } from './Me.type';
import { GetMeFetch } from "@/axios/SenseFacade/Users/Users.api";
import { IntegrationType, IntegrationsType, UserType } from "@/axios/SenseFacade/Users/Types/Get/Get.type";
import { GetInvitesFetch, GetUsersFetch, LogoutFetch, SessionFetch } from "@/axios/OrganizationService/Users/Users.api";
import { UsersAxiosResponseSuccessType, UserType as OrganizationUserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { ToastService } from 'service/ToastService';
import { defaultUser, defaultValue } from './Me.default';
import { Progress } from './components/Progress/Progress.coponent';
import { POOLING_INTERVAL } from './Me.const';
import { clearCookies } from 'utils/ClearCookies/ClearCookies.util';
import { HttpClient } from "@/axios/HttpClient";
import { NodeType as FollowedNodeType, NodesAxiosResponseSuccessType } from "@/axios/AiService/Nodes/Types/Get/Get.type";
import { GetNodesFetch } from "@/axios/AiService/Nodes/Nodes.api";

import { useRouter } from 'providers/Router/Router.provider';
import { SetNodeRelationsFetch } from '@/axios/AiService/NodeRelations/NodeRelations.api';
import { useChromeExtension } from '../ChromeExtension/ChromeExtension.provider';
import { UserMeAxiosResponseSuccessType } from '@/axios/SenseFacade/Users/Types/GetMe/GetMe.type';
import { getCookie } from '@/utils/GetCookie/GetCookie.util';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { SessionAxiosResponseSuccessType } from '@/axios/OrganizationService/Users/Types/Session/Session.type';
import { isShared } from '@/utils/IsShared/IsShared.utils';
import { envUrl } from '@/service/helpers';
import { GetInvitesAxiosResponseSuccessType } from '@/axios/OrganizationService/Users/Types/GetInvites/GetInvites.type';
import { UserType as ConfirmedIvitedUserType } from "@/axios/OrganizationService/Users/Types/GetInvites/GetInvites.type";
import { GetUserRelationsFetch } from '@/axios/AiService/Users/Users.api';
import { RelationType, UserRelationsAxiosResponseSuccessType } from '@/axios/AiService/Users/Types/GetRelations/GetRelations.type';
import { GetSpacesFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { SpacesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/List/List.type";

const supportsPushNotifications = () => 'PushManager' in window && window.isSecureContext;
let beamsClient: PusherPushNotifications.Client | undefined;
if (supportsPushNotifications()) {
  const PRODUCTION_NOTIFICATIONS_INSTANCE_ID = '768941ec-2198-4461-b5a5-279c5038249a';
  const DEVELOPMENT_NOTIFICATIONS_INSTANCE_ID = '0c57db7f-41d3-4fcf-bdd5-eb4cd869dee8';
  beamsClient = new PusherPushNotifications.Client({
    instanceId: window.location.hostname === "memory.senseapp.ai" ? PRODUCTION_NOTIFICATIONS_INSTANCE_ID : DEVELOPMENT_NOTIFICATIONS_INSTANCE_ID,
  });
}


const MeContext = React.createContext<MeContextType>(defaultValue);
export const useMe = () => useContext<MeContextType>(MeContext);

export const MeProvider = (props: { children: React.ReactNode }) => {
  const chromeExtension = useChromeExtension();
  const router = useRouter();
  const { isSharedSpace, isSharedNode } = isShared(router);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const [shouldStartIntegration, setShouldStartIntegration] = useState<boolean>(false);
  const [shoulShowReloadPageTip, setShoulShowReloadPageTip] = useState<boolean>(false);
  const [user, setUser] = useState<UserType>(defaultUser);
  const [users, setUsers] = useState<OrganizationUserType[]>([]);
  const [userRelations, setUserRelations] = useState<RelationType[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [confirmedInvitedUsers, setConfirmedInvitedUsers] = useState<ConfirmedIvitedUserType[]>([]);
  const [followedNodes, setFollowedNodes] = useState<FollowedNodeType[]>([]);
  const setSessionToken = useCallback(async () => {
    if (router.query.session === 'true' && user.organizationId) {
      await SessionFetch().then(async (res: SessionAxiosResponseSuccessType) => {
        if (chromeExtension.versionNumber >= Number('1.4.1.44'.replace(/\./g, ''))) {
          await chromeExtension.localStorage.setItem('accessToken', res.data.accessToken);
        }
        router.push({
          name: router.name,
          params: router.params,
          query: {
            ...router.query,
            session: undefined
          }
        })
      })
    }
  }, [chromeExtension.localStorage, chromeExtension.versionNumber, router, user.organizationId])
  useEffect(() => {
    setSessionToken()
  }, [setSessionToken])
  const logoutHandler = useCallback(async () => {
    if (!user.id) {
      console.error('User id is not defined');
      router.push({
        name: 'login'
      })
      return;
    }
    await LogoutFetch(user.id);
    delete HttpClient.defaults.headers.common['Authorization'];
    delete HttpClient.defaults.headers.common['X-CSRF-Token'];
    delete HttpClient.defaults.headers.common['X-Org-Id'];
    setUser(defaultUser);
    setUsers([]);
    clearCookies();
    beamsClient && beamsClient.getDeviceId().then(async (deviceId) => {
      if (deviceId) {
        beamsClient && await beamsClient.clearAllState()
          .then(() => console.log('Beams state has been cleared'))
          .catch(e => console.error('Could not clear Beams state', e))
          beamsClient && await beamsClient.stop()
          .then(() => console.log('Beams SDK has been stopped'))
          .catch(e => console.error('Could not stop Beams SDK', e));
      }
    })
    router.push({
      name: 'login'
    })
  }, [router, user.id]);
  const loadMeHandle = useCallback(async () => {
    return await GetMeFetch().then(async (res: UserMeAxiosResponseSuccessType) => {
      HttpClient.defaults.headers.common['X-Org-Id'] = res.data.user.organizationId;
      // const randonBoolean = Math.random() >= 0.5;
      // setUser({ ...res.data.user, integrations: res.data.integrations, subscriptionStatus: randonBoolean ? 'EXPIRED' : res.data.user.subscriptionStatus });
      const userData = { ...res.data.user, integrations: res.data.integrations };
      const oldUser = JSON.stringify(user);
      const newUser = JSON.stringify(userData);
      if (oldUser !== newUser) {
        // setUser({
        //   ...userData,
        //   subscriptionStatus: 'EXPIRED',
        //   // integrations: {
        //   //   ...userData.integrations,
        //   //   slack: {
        //   //     ...userData.integrations.slack,
        //   //     inProcess: true,
        //   //   }
        //   // }
        // });
        setUser(userData);
      }
      return userData;
    }).catch(() => {
      logoutHandler();
      return;
    });
  }, [logoutHandler, user]);
  const loadInvitedUsers = useCallback(async () => {
    if (!user.id) {
      return
    }
    await GetInvitesFetch({
      organizationId: user.organizationId,
    }).then(async (res: GetInvitesAxiosResponseSuccessType) => {
      setInvitedUsers(res.data.emails);
      setConfirmedInvitedUsers(res.data.users);
    })
  }, [user.id, user.organizationId]);
  const loadOrganizationUsers = useCallback(async () => {
    if (!user.id || isSharedSpace || isSharedNode) {
      return
    }
    await GetUsersFetch({
      organizationId: user.organizationId,
    }).then(async (res: UsersAxiosResponseSuccessType) => {
      const oldUsers = JSON.stringify(users.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0)));
      const newUsers = JSON.stringify(res.data.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
    );
      if (oldUsers !== newUsers) {
        setUsers(res.data);
      }
    }).catch(() => {
      ToastService.showToast('error', 'Some error occurred!');
    });
  }, [user.id, user.organizationId, isSharedSpace, isSharedNode, users]);

  const loadUserRelations = useCallback(async () => {
    if (!user.id || isSharedSpace || isSharedNode) {
      return
    }
    !!user?.id && await GetUserRelationsFetch(user?.id).then((response: UserRelationsAxiosResponseSuccessType) => {
        setUserRelations(response.data.relations);
    });
}, [isSharedNode, isSharedSpace, user.id]);
  const loadFollowedNodes = useCallback(async () => {
    if (!user.id || isSharedSpace || isSharedNode || !user.accountReady || !user.isOnboarded) {
        return;
    }
    await GetNodesFetch({
        userId: user.id,
        relationType: 'FOLLOWED_BY'
    }).then((res: NodesAxiosResponseSuccessType) => {
        setFollowedNodes(res.data);
    });
  }, [user, isSharedSpace, isSharedNode])
  const followHandler = useCallback(async (nodeId: number) => {
    if (!user.id) {
      return;
    }
    await SetNodeRelationsFetch([{
      nodeId: nodeId,
      actors: [{
        userId: user.id,
        actionType: 'FOLLOWED_BY',
      },]
    }], {
      action: 'CREATE',
    });
    await loadFollowedNodes();
  }, [loadFollowedNodes, user.id]);
  const unFollowHandler = useCallback(async (nodeId: number) => {
    if (!user.id) {
      return;
    }
    await SetNodeRelationsFetch([{
      nodeId: nodeId,
      actors: [{
        userId: user.id,
        actionType: 'FOLLOWED_BY',
      },]
    }], {
      action: 'DELETE',
    });
    await loadFollowedNodes();
  }, [loadFollowedNodes, user.id])
  const getToken = useCallback(async (): Promise<string | null> => {
    if (chromeExtension.isIframe && chromeExtension.versionNumber >= Number('1.4.1.44'.replace(/\./g, ''))) {
      return await chromeExtension.localStorage.getItem('accessToken') || null;
    } else {
      return null;
    }
  }, [chromeExtension.isIframe, chromeExtension.localStorage, chromeExtension.versionNumber])
  const loadHandle = useCallback(async () => {
    const accessToken = await getToken();
    const csrf_token = getCookie('csrf_token');
    (!!accessToken || !!csrf_token) && loadMeHandle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken]);
  useEffect(() => {
    loadHandle();
  }, [loadHandle]);
  const authNotification = useCallback(async () => {
    if (user.id && !chromeExtension.isIframe) {
      const csrf_token = getCookie('csrf_token');
      if (!csrf_token) {
        return;
      }
      if (user.emailConfirmed && user.accountReady && user.isOnboarded) {
        const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: `${window.location.protocol}//${window.location.host}/api/notification-service/v1/browser/notifications/auth`,
          headers: {
            "X-CSRF-Token": csrf_token,
          },
        });
    
        beamsClient && await beamsClient
          .start()
          .then(() => beamsClient && beamsClient.setUserId(user.id, beamsTokenProvider))
          .then(() => console.log('Successfully registered and subscribed!'))
          .catch(console.error);
      }
    }
  }, [user.id, user.emailConfirmed, user.accountReady, chromeExtension.isIframe])
  useEffect(() => {
    authNotification();
  }, [authNotification]);
  useEffect(() => {
    loadOrganizationUsers();
    loadUserRelations();
    loadInvitedUsers();
  }, [loadOrganizationUsers, loadInvitedUsers, loadUserRelations]);
  useEffect(() => {
    if (!!user.id) {
      loadFollowedNodes();
    }
  }, [loadFollowedNodes, user]);
  useEffect(() => {
    if (router.name==='progressPage') {
      return
    }
    if (router.name !== 'notFound' && router.name !== null) {
      if (!!user.id) {
        const isEmailConfirmed = user.emailConfirmed;
        const isUserOnboarded = user.isOnboarded;
        const isAccountReady = user.accountReady;
        if (!isEmailConfirmed && router.name!=='confirmation') {
          router.push({
            name: 'linkConfirmation'
          })
          return
        }
        if (!isAccountReady && isEmailConfirmed) {
          HttpClient.put(`${envUrl}/organization-service/v1/payment/subscription`, {
            name: 'INDIVIDUAL',
            userId: user.id,
          });

          router.push({
            name: 'loadingPage',
          });
          return;
        }
        
        if (router.name === 'loadingPage' && user.accountReady) {
          router.push({
            name: 'manageIntegrations',
          });
          return;
        }
        if (!isUserOnboarded) {
          if (!router.type.includes('onboarding')) {
            router.push({
              name: 'manageIntegrations',
            });
          }
          return
        }
        if ((isAccountReady) && !router.type.includes('private') && router.name !=='confirmation') {
          if (router.query.redirectUrl) {
            window.location.href = `${window.location.origin}${router.query.redirectUrl}`;
          } else {
            router.push({
              name: 'forYou',
            })
          }
          return
        }
      }
    }
  }, [router, user]);
  const handleRedirect = useCallback(async () => {
    if (router.name==='progressPage') {
      return
    }
    const accessToken = await getToken();
    const csrf_token = getCookie('csrf_token');
    
    if (router.type.includes('private') || router.type.includes('onboarding')) {
      if (!accessToken && !csrf_token && !router.type.includes('public')) {
        await chromeExtension.localStorage.setItem('accessToken', null);
        router.push({
          name: 'login',
          query: {
            redirectUrl: router.pathname
          }
        })
      }
    } else {
      if (!!router.name && router.name !== 'notFound') {
        if ((accessToken || csrf_token) && router.name !=='linkConfirmation' && router.name !=='confirmation') {
          if (router.query.redirectUrl) {
            window.location.href = `${window.location.origin}${router.query.redirectUrl}`;;
          } else {
            router.push({
              name: 'forYou',
            })
          }
        }
      }
    }
  }, [getToken, router, chromeExtension.localStorage])
  useEffect(() => {
    handleRedirect();
  }, [handleRedirect])
  const loginHandler = useCallback(async (accessToken: string, callback?: () => void) => {
    if (chromeExtension.versionNumber >= Number('1.4.1.44'.replace(/\./g, ''))) {
      await chromeExtension.localStorage.setItem('accessToken', accessToken);
      HttpClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    const csrf_token = getCookie('csrf_token');
    if (csrf_token) {
      HttpClient.defaults.headers.common['X-CSRF-Token'] = csrf_token;
    }
    callback && callback();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chromeExtension.localStorage, chromeExtension.versionNumber]);

  const getIsVisibleIntegrationProgress = useCallback((integrations?: IntegrationsType) => {
    return Object.values(integrations || {}).some((integration: IntegrationType) => {
      if (integration.accounts && integration.accounts.length) {
        const inProcess = integration.accounts.some(account => account.inProcess) || integration.inProcess;
        return inProcess;
      }
      return integration.inProcess;
    });
  }, []);
  const isVisibleIntegrationProgress = useMemo(() => {
    if (shouldStartIntegration || shoulShowReloadPageTip) {
      return true;
    }
    if (!user.id) {
      return false;
    }
    const isVisibleIntegrationProgress = getIsVisibleIntegrationProgress(user?.integrations);
    return isVisibleIntegrationProgress;
  }, [shouldStartIntegration, shoulShowReloadPageTip, user.id, user?.integrations, getIsVisibleIntegrationProgress]);
  const prevIsVisibleIntegrationProgress = useRef(isVisibleIntegrationProgress);
  useEffect(() => {
    if (isVisibleIntegrationProgress) {
      const pooling = () => {
        loadMeHandle().then((data) => {
          if (data && getIsVisibleIntegrationProgress(data.integrations)) {
            timeoutId.current = setTimeout(pooling, POOLING_INTERVAL);
            setShouldStartIntegration(false);
          } else {
            setShoulShowReloadPageTip(true);
            timeoutId.current && clearTimeout(timeoutId.current);
            timeoutId.current = null;
          }
        });
      };
      timeoutId.current = setTimeout(pooling, POOLING_INTERVAL);
    } else if (timeoutId) {
      timeoutId.current && clearTimeout(timeoutId.current);
      timeoutId.current = null;
      if (prevIsVisibleIntegrationProgress.current && router.name!=='progressPage') {
        setShoulShowReloadPageTip(true);
      }
    }
    prevIsVisibleIntegrationProgress.current = isVisibleIntegrationProgress;
    return () => {
      if (timeoutId) {
        timeoutId.current && clearTimeout(timeoutId.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleIntegrationProgress]);
  const isOnboarded = window.localStorage.getItem('onboarding') !== 'true';
  useEffect(() => {
    if (!isOnboarded && shoulShowReloadPageTip) {
      GetSpacesFetch({}).then(async (res: SpacesAxiosResponseSuccessType) => {
          if (res.data.length === 0) {
              await GetUsersFetch({
                  organizationId: user.organizationId,
              }).then(async (res: UsersAxiosResponseSuccessType) => {
                  if ((res.data.filter((u) => u.id !== user.id)).length > 0) {
                    // router.push({
                    //     name: 'forYou',
                    //     query: {
                    //         dialog: 'onboarding-people'
                    //     }
                    // })
                    window.localStorage.removeItem('onboarding');
                    const newUrl = router.getPathWithParams(router.name, router.params, {
                      ...router.query,
                      dialog: 'onboarding-people'
                    });
                    window.location.href = newUrl;
                  }
              })
          } else {
              // router.push({
              //   name: 'forYou',
              //   query: {
              //       dialog: 'onboarding-projects'
              //   }
              // })
              window.localStorage.removeItem('onboarding');
              const newUrl = router.getPathWithParams(router.name, router.params, {
                ...router.query,
                dialog: 'onboarding-projects'
              });
              window.location.href = newUrl;
          }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, shoulShowReloadPageTip]);
  const contextValue = useMemo(() => {
    return {
      isLoggedIn: !!user,
      login: loginHandler,
      logout: logoutHandler,
      user: user,
      users: users,
      userRelations: userRelations,
      setUsers: setUsers,
      followedNodes: followedNodes,
      follow: followHandler,
      unfollow: unFollowHandler,
      setUser: setUser,
      loadUser: loadMeHandle,
      loadUsers: loadOrganizationUsers,
      loadUserRelations: loadUserRelations,
      setShouldStartIntegration,
      loadInvitedUsers,
      invitedUsers,
      confirmedInvitedUsers,
      isIntegrationInProgress: router.type.includes('private') && isVisibleIntegrationProgress,
      setShoulShowReloadPageTip,
    }
  }, [confirmedInvitedUsers, followHandler, followedNodes, invitedUsers, isVisibleIntegrationProgress, loadInvitedUsers, loadMeHandle, loadOrganizationUsers, loadUserRelations, loginHandler, logoutHandler, router.type, unFollowHandler, user, userRelations, users]);
  const shouldRenderChildren = useMemo(() => {
    if (router.type.includes('public')) {
      return true;
    }
    if (router.type.includes('private')) {
      return !!user.id && user.isOnboarded;
    }
    if (router.type.includes('onboarding')) {
      return !!user.id && (!user.isOnboarded || !user.accountReady);
    }
    return true
  }, [router, user]);
  return <MeContext.Provider value={contextValue}>
    {shouldRenderChildren && props.children}
    {router.type.includes('private') && router.name !== 'progressPage' && isVisibleIntegrationProgress && <Progress done={shoulShowReloadPageTip} />}
  </MeContext.Provider>;
};

export default MeContext;
