import { useEffect } from "react";
import { useMe } from "providers/MeProvider/Me.provider";
import { StyledButtonContainter, StyledCard, StyledContainer, StyledText, StyledWrapper } from "./Progress.styled";
import { Loader } from "@/components/Loader/Loader.component";
import { StyledTitle } from "../../Login/Login.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { GetSpacesFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { SpacesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { GetUsersFetch } from "@/axios/OrganizationService/Users/Users.api";
import { UsersAxiosResponseSuccessType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";

export const ProgressPage = () => {
    const { user, loadUser, isIntegrationInProgress, setShoulShowReloadPageTip } = useMe();
    const router = useRouter();

    const start = async () => {
        if (user?.id) {
            GetSpacesFetch({}).then(async (res: SpacesAxiosResponseSuccessType) => {
                if (res.data.length === 0) {
                    await GetUsersFetch({
                        organizationId: user.organizationId,
                    }).then(async (res: UsersAxiosResponseSuccessType) => {
                        if ((res.data.filter((u) => u.id !== user.id)).length === 0) {
                            router.push({
                                name: 'forYou'
                            })
                        } else {
                            router.push({
                                name: 'forYou',
                                query: {
                                    dialog: 'onboarding-people'
                                }
                            })
                        }
                        setShoulShowReloadPageTip(false);
                    })
                } else {
                    router.push({
                        name: 'forYou',
                        query: {
                            dialog: 'onboarding-projects'
                        }
                    })
                    setShoulShowReloadPageTip(false);
                }
            });
        }
    }
    
    useEffect(() => {
        const interval = setInterval(() => {
            loadUser();
        }, 3000)

        if (!isIntegrationInProgress) {
            start()
        }
        return () => {
            clearInterval(interval)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntegrationInProgress])
    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledTitle>Sense is organizing your work...</StyledTitle>
                <StyledCard>
                    <Loader size={64} />
                    <StyledText>
                        <div>It might take some time. You will be notified as soon as it's done.</div>
                        <div>You might not be able see all the data before that.</div>
                    </StyledText>
                    <StyledButtonContainter>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                router.push({
                                    name: 'forYou'
                                })
                                window.localStorage.setItem('onboarding', 'true')
                            }}
                        >Start exploring product</Button>
                    </StyledButtonContainter>
                </StyledCard>
            </StyledWrapper>
        </StyledContainer>
    )
}