import { styled } from "@mui/material";
import { SectionHeader } from "../SectionHeader/SectionHeader.component";

export const StyledSpacesWrapper = styled('div')`
    padding: 0px 8px;
`;
export const StyledSectionHeader = styled(SectionHeader)`
    position: sticky;
    top: 0;
    background-color: #EBEBEB;
    z-index: 1;
`;