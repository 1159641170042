import { FC, useCallback, useEffect, useState } from "react";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { StyledDialogLayout, StyledLoaderWrapper } from "./SearchModal.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { StyledLoaderIconWrapper, StyledSearchIcon, StyledSearchIconWrapper } from "../../Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled";
import { StyledCloseIcon } from "../../Layouts/MainLayout/components/Header/components/Search/Search.styled";
import { Input } from "../../Fields/Input/Input.component";
import { Summary } from "./components/Summary/Summary.component";
import { RelatedPeople } from "./components/RelatedPeople/RelatedPeople.component";
import { SearchGetFetch, SearchSmartGetFetch } from "@/axios/AiService/Serach/Serach.api";
import { useDebounce } from "@/hooks/UseDebounce/UseDebounce.hook";
import { NodeType } from "@/components/v3/List/Node/Node.type";
import { PersonType } from "@/components/v3/List/Person/Person.type";
import { SearchGetAxiosResponseSuccessType } from "@/axios/AiService/Serach/Types/Get/Get.type";
import { RelatedResources } from "./components/RelatedResources/RelatedResources.component";
import { RecentSearches } from "./components/RecentSearches/RecentSearches.component";
import { SearchGetSmartAxiosResponseSuccessType } from "@/axios/AiService/Serach/Types/GetSmart/GetSmart.type";
import { Loader } from "../../Other/Loader/Loader.component";

export const SeachModal: FC = () => {
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryToSearchBy, setQueryToSearchBy] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [listOfNodes, setListOfNodes] = useState<NodeType[]>([]);
    const [listOfPeople, setListOfPeople] = useState<PersonType[]>([]);
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'search' });
    const onChange = (value: boolean) => {
        onChangeIsOpened(value);
    };
    const loadData = async () => {
        setIsLoading(true)
        await SearchSmartGetFetch({
            query: queryToSearchBy,
        }).then((res: SearchGetSmartAxiosResponseSuccessType) => {
            setListOfNodes(res.data.nodes);
            setAnswer(res.data.answer || '');
            setListOfPeople(res.data.users || []);
        });
        setIsLoading(false)
    }
    const clear = () => {
        setQueryToSearchBy('');
        // setIsVisible(false);
        setListOfNodes([]);
        setListOfPeople([]);
        setAnswer('');
        setIsLoading(false);
    };
    const handler = useDebounce(() => {
        // setIsVisible(true);
        loadData();
    })
    useEffect(() => {
        if (queryToSearchBy) {
            handler();
        } else {
            clear();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryToSearchBy]);
    useEffect(() => {
        if (!value) {
            clear();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    
    const handleChange = useCallback ((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setQueryToSearchBy(searchTerm);
    }, []);
    const displayRecentSearches = !queryToSearchBy || (!listOfPeople.length && !listOfNodes.length && !answer);
    const topPx = 120;
    const displayResults = listOfNodes.length || listOfPeople.length || answer;
    return <Dialog topPx={120} value={value} onChange={onChange}>
        <StyledDialogLayout maxHeight={`calc(100vh - 64px - ${topPx}px)`}>
            <Input
                placeholder="Search in Sense"
                variant="filled"
                size='large'
                value={queryToSearchBy}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <>
                            {(!displayResults || !isLoading) && <StyledSearchIconWrapper>
                                <StyledSearchIcon />
                            </StyledSearchIconWrapper>}
                            {!!displayResults && isLoading && <StyledLoaderIconWrapper>
                                <Loader size={16} />
                            </StyledLoaderIconWrapper>}
                        </>
                    ),
                    endAdornment: <>
                        {!!search && <StyledCloseIcon onClick={() => setSearch('')} />}
                    </>,
                }}
                autoFocus
            />
            {isLoading && !displayResults && <StyledLoaderWrapper>
                <Loader size={48} />
            </StyledLoaderWrapper>}
            {!displayRecentSearches && <>
                <Summary value={answer} />
                <RelatedPeople listOfPeople={listOfPeople} />
                <RelatedResources listOfNodes={listOfNodes} onUpdate={loadData} />
            </>}
            {/* {displayRecentSearches && <>
                <RecentSearches />
            </>} */}
        </StyledDialogLayout>
    </Dialog>
};