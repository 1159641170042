import { FC, useEffect } from "react";
import { StyledTab, StyledTabs, StyledWrapper } from "./Tabs.styled";
import { TabValueType } from "@/providers/v3/ForYouPage/ForYouPage.type";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { ActivitiesIcon } from "@/icons/v3/activities.icon";
import { NotesIcon } from "@/icons/v3/notes.icon";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";
import { ResourcesIcon } from "@/icons/v3/resources.icon";

export const Tabs: FC = () => {
  const { activeTab, setActiveTab } = useForYouPage();
  const shouldRenderTabsFromSidebar = useRenederBelow('medium');
  const { useNewActivityUI } = useFeatureFlags();
  useEffect(() => {
    if (!shouldRenderTabsFromSidebar && activeTab === 'MEETINGS') {
      setActiveTab('UPDATES');
    }
}, [activeTab, setActiveTab, shouldRenderTabsFromSidebar]);
  return <StyledWrapper>
    {!!useNewActivityUI && <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="ACTIVITIES" icon={<ActivitiesIcon />}>Activities</StyledTab>
      <StyledTab value="RESOURCES" icon={<ResourcesIcon />}>Resources</StyledTab>
      <StyledTab value="NOTES" icon={<NotesIcon />}>Notes</StyledTab>
      {shouldRenderTabsFromSidebar && <StyledTab value="MEETINGS">Meetings</StyledTab>}
    </StyledTabs>}
    {!useNewActivityUI && <StyledTabs value={activeTab} onChange={(newVal) => setActiveTab(newVal as TabValueType)}>
      <StyledTab value="UPDATES">Updates</StyledTab>
      {shouldRenderTabsFromSidebar && <StyledTab value="MEETINGS">Meetings</StyledTab>}
      <StyledTab value="EMAILS">Emails</StyledTab>
      <StyledTab value="NOTES">Notes</StyledTab>
      <StyledTab value="ACTIVITIES">Activities</StyledTab>
    </StyledTabs>}
  </StyledWrapper>
};