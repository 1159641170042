import { FC } from "react";
import { ManageAppsLayoutProps } from "./ManageAppsLayout.type";
import { StyledActions, StyledActionsContainer, StyledContainer, StyledTitle, StyledWrapper } from "./ManageAppsLayout.styled";
import { Progress } from "../../Other/Progress/Progress.component";
export const ManageAppsLayout: FC<ManageAppsLayoutProps> = ({ title, children, actions, progress }) => {
    return <StyledWrapper>
    <StyledContainer>
        <Progress value={progress} />
        <StyledTitle>{title}</StyledTitle>
        {children}
    </StyledContainer>
    <StyledActions>
        <StyledActionsContainer>
            {actions}
        </StyledActionsContainer>
    </StyledActions>
</StyledWrapper>
};