import { FC, useMemo } from "react";
import { Integration } from "./components/Integration/Integration.component";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { IntegrationsProps } from "./Integrations.type";

export const Integrations: FC<IntegrationsProps> = ({
  search
}) => {
    const { spaces: spacesNativeData } = useSpaces();
    const spacesSorted = useMemo(() => {
        return spacesNativeData.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
    }, [spacesNativeData]);
    const slackSpaces = spacesSorted.filter(space => space.application === 'Slack');
    const confluenceSpaces = spacesSorted.filter(space => space.application === 'Confluence');
    const jiraSpaces = spacesSorted.filter(space => space.application === 'Jira');
    const asanaSpaces = spacesSorted.filter(space => space.application === 'Asana');
    const githubSpaces = spacesSorted.filter(space => space.application === 'Github');
    const clickUpSpaces = spacesSorted.filter(space => space.application === 'ClickUp');
    const trelloSpaces = spacesSorted.filter(space => space.application === 'Trello');
    const microsoftTeamsSpaces = spacesSorted.filter(space => space.application === 'MicrosoftTeams');
    const notionSpaces = spacesSorted.filter(space => space.application === 'Notion');
    return <>
        <Integration title="Slack" spaces={slackSpaces} search={search} />
        <Integration title="Confluence" spaces={confluenceSpaces} search={search} />
        <Integration title="Jira" spaces={jiraSpaces} search={search} />
        <Integration title="Jira" spaces={jiraSpaces} search={search} />
        <Integration title="Asana" spaces={asanaSpaces} search={search} />
        <Integration title="Github" spaces={githubSpaces} search={search} />
        <Integration title="ClickUp" spaces={clickUpSpaces} search={search} />
        <Integration title="Trello" spaces={trelloSpaces} search={search} />
        <Integration title="Microsoft Teams" spaces={microsoftTeamsSpaces} search={search} />
        <Integration title="Notion" spaces={notionSpaces} search={search} />
    </>
};