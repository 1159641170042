import { FC, useCallback, useEffect, useState } from "react";
import { StyledCreateAccountButton, StyledPublicTitle, StyledScrollContainer, StyledWrapper } from "./Sidebar.styled";
import { useRouter } from "@/providers/Router/Router.provider";
import { MainNavigation } from "./components/MainNavigation/MainNavigation.component";
import { CreateSpace } from "./components/CreateSpace/CreateSpace.component";
import { Spaces } from "./components/Spaces/Spaces.component";
import { useIsShared } from "@/hooks/v3/UseIsShared/UseIsShared.hook";
import { SpaceType } from '@/axios/AiService/Spaces/Types/List/List.type';
import { PublicSpace } from "./components/PublicSpace/PublicSpace.component";
import { SpaceTagType } from '@/axios/AiService/SpaceTags/Types/Get/Get.type';
import { GetCommonFetch } from "@/axios/SenseFacade/Public/Public.api";
import { CommonAxiosResponseSuccessType } from "@/axios/SenseFacade/Public/Types/GetCommon/GetCommon.type";
import { StyledSpacesWrapper } from "./components/Spaces/Spaces.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { People } from "./components/People/People.component";
import { Settings } from "./components/Settings/Settings.component";

export const Sidebar: FC = () => {
    const router = useRouter();
    const code = router.params.code as string | undefined;
    const { user: userMe } = useMe();
    const { isSharedSpace } = useIsShared();
    const [space, setSpace] = useState<SpaceType | null>(null);
    const [categories, setCategories] = useState<string[]>([]);
    const [tags, setTags] = useState<SpaceTagType[]>([]);
    const loadPublicSpace = useCallback(async () => {
        if (isSharedSpace) {
          if (!code) { return }
          await GetCommonFetch({
              code
          }).then((res: CommonAxiosResponseSuccessType) => {
              setSpace(res.data.space);
              setCategories(res.data.categories);
              setTags(res.data.tags);
          });
        }
      }, [code, isSharedSpace]);
      useEffect(() => {
        loadPublicSpace();
      }, [loadPublicSpace]);
    return <>
        {!isSharedSpace && <StyledWrapper>
            <MainNavigation />
            {/* <CreateSpace /> */}
            <StyledScrollContainer>
              <Spaces />
              <People />
            </StyledScrollContainer>
            <Settings />
        </StyledWrapper>}
        {isSharedSpace && !!space && <>
          <StyledPublicTitle>Spaces shared with you</StyledPublicTitle>
          <StyledSpacesWrapper>
            <PublicSpace space={space} categories={categories} tags={tags} />
          </StyledSpacesWrapper>
        </>}
        {isSharedSpace && !userMe?.id && <StyledCreateAccountButton center onClick={() => {
          router.push({
            name: 'signup',
          })
        }}>
          Create Sense account
        </StyledCreateAccountButton>}
    </>
};