import { BREAKPOINTS } from "@/hooks/v3/UseResponsive/BreackPoints";
import { styled } from "@mui/material";

export const StyledWrapper = styled("div")`
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 120px;
    width: 100%;
    background: #F4F4F4;
    padding-bottom: 96px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
    }
`;
export const StyledContainer = styled("div")`
    width: 100%;
    max-width: 720px;
    position: relative;
`;
export const StyledTitle = styled("div")`
    color: #202020;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-top: 16px;
`;
export const StyledActions = styled('div')`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    padding: 24px 32px;
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-left: 16px;
        padding-right: 16px;
    }
`;
export const StyledActionsContainer = styled('div')`
    width: 100%;
    max-width: 720px;
`;