import { TextField, styled } from "@mui/material";
import { SizeType, VariantType } from "./Input.type";

export const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "inputSize" && prop !== "inputVariant" && prop !== "inputIsFilled",
})<{inputSize: SizeType; inputVariant: VariantType; inputIsFilled: boolean}>`
    width: 100%;
    margin-bottom: 16px;
    border-radius: 6px;
    & fieldset {
        border: 1px solid rgba(0, 0, 0, 0.16);
    }
    & .MuiFormLabel-root.Mui-focused {
        color: #202020;
    }
    & .MuiInputBase-root.Mui-focused {
        & fieldset {
            border-color: #202020;
        }
    }
    ${({inputIsFilled}) => inputIsFilled && `
        & .MuiInputBase-input {
            color: #202020;
        }
    `}
    ${({inputVariant}) => inputVariant === "filled" && `
        & .MuiOutlinedInput-root {
            background: rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            padding-left: 8px;
            padding-right: 8px;
        }
    `}
    ${({inputVariant}) => inputVariant === "outlined" && `
        & .MuiOutlinedInput-root {
            padding-left: 8px;
            padding-right: 8px;
            border-radius: 4px;
            border: 1px solid var(--Special-Divider-Dark, rgba(0, 0, 0, 0.16));
        }
    `}
    ${({inputSize}) => (inputSize === "small" || inputSize === "medium" || inputSize === 'large') && `
        margin-bottom: 0;
        & .MuiInputBase-input {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            padding: 0;
            &::placeholder {
                color: rgba(0, 0, 0, 1);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
            }
        }
        & .MuiOutlinedInput-notchedOutline {
            border: 0;
        }
        & .MuiIconButton-root {
            color: rgba(32, 32, 32, 0.5);
            padding: 0;
        }
    `}
    ${({inputSize}) => inputSize === "small" && `
        & .MuiOutlinedInput-root {
            height: 32px;
        }
    `}
    ${({inputSize}) => inputSize === "medium" && `
        & .MuiOutlinedInput-root {
            height: 40px;
        }
    `}
    ${({inputSize}) => inputSize === "large" && `
        & .MuiOutlinedInput-root {
            height: 48px;
        }
    `}
`;