import { Content } from "./components/Content/Content.component";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { FC } from "react";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";

export const SettingsModal: FC = () => {
  const { value, onChange } = useIsModalOpened({ name: 'settings' });
  return <Dialog
    value={value}
    onChange={onChange}
    size="small"
  >
    <DialogLayout title="Settings" onCloseClick={() => onChange(false)} >
      <Content />
    </DialogLayout>
  </Dialog>
}