import { Button } from "@/components/v3/Fields/Button/Button.component";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { UserAvatar } from "@/components/v3/Other/UserAvatar/UserAvatar.component";
import { Box, styled } from "@mui/material";

export const StyledPerson = styled(ListItem)`
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    margin-top: 8px;
    transition: all 0.1s ease-in-out;
`;
export const StyledRemoveButton = styled(Button)`
    margin-left: 8px;
`;
export const StyledUserAvatar = styled(UserAvatar)`
    margin-right: 8px;
`;