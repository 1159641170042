import { ConnectChannels } from "./ConnectChannels.component";
import { IntegrationType } from "./ConnectChannels.type";
import { useEffect, useState } from "react";
import { useRouter } from "@/providers/Router/Router.provider";

export const OnboardingConnection = () => {
    const router = useRouter();
    const [isOpen, setIsopen] = useState<IntegrationType | false>(false);
    useEffect(() => {
        switch (router.name) {
            case 'connectSlackChannels':
                setIsopen('slack');
                break;
            case 'connectClickUpProjects':
                setIsopen('click_up');
                break;
            case 'connectTrelloBoards':
                setIsopen('trello');
                break;
            case 'connectAsanaProjects':
                setIsopen('asana');
                break;
            case 'connectGitHubProjects':
                setIsopen('github');
                break;
            case 'connectAtlassianProjects':
                setIsopen('atlassian');
                break;
            case 'connectMicrosoftTeamsProjects':
                setIsopen('microsoft_teams');
                break;
            default:
                router.push({
                    name: 'manageIntegrations'
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.name]);
    return (
        <ConnectChannels isOpen={isOpen} setIsOpen={setIsopen} isOnboarding={true} />
    )
}