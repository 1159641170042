import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Add2Icon } from "@/icons/v3/add2.icon";
import { styled } from "@mui/material";

export const StyledPublicTitle = styled('div')`
    padding: 16px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

export const StyledWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
`
export const StyledCreateAccountButton = styled(Button)`
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
`
export const StyledAddIcon = styled(Add2Icon)`
    & path {
        fill: #202020;
    }
`
export const StyledScrollContainer = styled('div')`
    flex: 10000 1 0%;
    max-height: calc( 100vh - 105px );
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.06);
    }
`