import { Box } from "@mui/material";
import { ManageModalProps } from "./ManageModal.type";
import {
    StyledAccWrapper,
    StyledListItemNode,
    StyledSubtitle,
} from "./ManageModal.styled";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { DialogLayout } from "@/components/v3/Layouts/DialogLayout/DialogLayout.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledListItem } from "../../../../Integration.styled";
import { defineApp } from "../../../../../../ManageIntegrationsContent.utils";

export const ManageModal = ({value, onChange, appName, icon, accounts, disconnectHandler, connectHandler}: ManageModalProps) => {
    const validateAccount = (timestamp: number) => {
        const currentDate = Math.floor(new Date().getTime()/1000);
        return timestamp > currentDate
    };
    return (
        <Dialog value={value} onChange={onChange}>
            <DialogLayout
                title={appName}
                onCloseClick={() => onChange(false)}
                actions={<>
                    <Button onClick={() => {connectHandler()}}>Add Account</Button>
                </>}
            >
                {accounts && accounts.map((account, i) => {
                    const isActive = validateAccount(account.expiresAt);
                    return <StyledListItemNode key={i}>
                        <StyledAccWrapper>
                            <StyledListItem
                                rounded={false}
                                img={icon}
                                title={account.email}
                                caption={<StyledSubtitle isActive={isActive} >{isActive ? 'Active' : 'Connection failed'}</StyledSubtitle>}
                                imgSize="large"
                            />
                            <Box>
                                {isActive && <Button variant='tertiary' onClick={() => {
                                    disconnectHandler( defineApp(appName) || '', account.email)
                                }}>
                                    Disconnect
                                </Button>}
                                {!isActive && <Button
                                    onClick={() => {connectHandler()}}
                                >
                                    Re-connect
                                </Button>}
                            </Box>
                        </StyledAccWrapper>
                    </StyledListItemNode>
                })}
            </DialogLayout>
        </Dialog>
    )
}