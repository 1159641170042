import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { Box, Button, Typography, styled } from "@mui/material";
import { zIndex } from 'Layouts/theme';

export const StyledListItem = styled(ListItem)`
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
    }
`;
export const StyledListItemCount = styled('div')`
    color: var(--Text-Secondary, #636363);
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
export const StyledDropDown = styled(Box)`
    width: 288px;
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    z-index: ${zIndex.spaceMenuDropdown};
    padding: 8px 0;
`;
export const StyledArrow = styled('img')<{active: boolean}>`
    transition: 250ms transform;
    transform-origin: center;
    transform: ${({active}) => active ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
export const StyledConnect = styled(Typography)`
    font-weight: 400;
    font-size: 12px;
    color: #636363;
    padding: 8px 16px;
`;
export const StyledConnectBtn = styled(Button)`
    margin: 8px;
`;