import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from 'service/helpers';
import { UsersGetQueryParams } from './Types/Get/Get.type';
import { RelatedUsersGetQueryParams } from "./Types/Related/Related.type";
import { CreateOrDeleteUsersRelationsBodyParams, CreateOrDeleteUsersRelationsQueryParams } from "./Types/CreateOrDeleteRelations/CreateOrDeleteRelations.type";

export const GetUsersFetch = (query: UsersGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/users`, {
        params: query
    });
}
export const GetRelatedUsersFetch = (query: RelatedUsersGetQueryParams) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/users/related`, {
        params: query
    });
}
export const GetUserRelationsFetch = (id: string) => {
    return HttpClient.get(`${envUrl}/ai-service/v1/users/${id}/relations`);
}
export const CreateOrDeleteUserRelationsFetch = (body: CreateOrDeleteUsersRelationsBodyParams, query: CreateOrDeleteUsersRelationsQueryParams) => {
    return HttpClient.post(`${envUrl}/ai-service/v1/users/relations`, body, {
        params: query
    });
}