import { FC } from "react";
import { StyledFilledCard, StyledWrapper } from "./Recomended.styled";
import { SectionHeader } from "@/components/v3/Sections/SectionHeader/SectionHeader.component";
import { RecomendedProps } from "./Recomended.type";
import { SpaceType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { Space } from "../Space/Space.component";

export const Recomended: FC<RecomendedProps> = ({
    spaces,
    search
}) => {
    const compare = (a: SpaceType, b: SpaceType) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
    }
    const filteredSpaces = spaces.filter(space => space.name.toLowerCase().includes(search.toLowerCase()));
    if (filteredSpaces.length === 0) {
        return null;
    }
    const spacesJsx = filteredSpaces.sort(compare).map(space => {
        return <Space key={space.id} space={space} />
    });
    return <StyledFilledCard>
        <SectionHeader title="Recommended" />
        <StyledWrapper>
            {spacesJsx}
        </StyledWrapper>
    </StyledFilledCard>
}