import { useEffect, useMemo, useState } from "react";
import { GetActivitiesDetailsFetch } from "@/axios/SenseFacade/ActivitiesDetails/ActivitiesDetails.api";
import { ActivitiesDetailsAxiosResponseSuccessType, NodeType } from "@/axios/SenseFacade/ActivitiesDetails/Types/Get/Get.type";
import { addRelationshipsToNodes } from "@/utils/AddRelationshipsToNodes/AddRelationshipsToNodes.util";
import { NodeRelationTypeWithUser, ActorTypeWithUser } from "@/utils/FetchNodeRelations/FetchNodeRelations.type";
import { useMe } from "@/providers/MeProvider/Me.provider";
import useInfiniteScroll from "@/hooks/v3/UseInfiniteScroll/UseInfiniteScroll.hook";
import { Loader } from "@/components/Loader/Loader.component";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { StyledWrapper, StyledFiltersWrapper, StyledSelect, StyledRelationsFiltersWrapper } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { ActivityMessageType } from "@/types/ActivityMessage/ActivityMessage.type";
import moment from "moment";
import { ActivityGroup } from "@/components/v3/List/ActivityGroup/ActivityGroup.component";
import { EmptyStateLayoyt } from "@/components/v3/Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { EmptyState } from "./components/EmptyState/EmptyState.component";
import { fetchImportantUsers } from "@/utils/v3/Fetch/FetchImportantUsers/FetchImportantUsers.util";
import { findLastActor } from "@/components/v3/List/Activity/Activity.utils";
import { RelationCheckboxButton } from "@/pages/v3/Space/components/Activities/components/RelationCheckboxButton/RelationCheckboxButton.component";
import { RelationActionType } from "@/types/Relations/Relations.type";
import { addUsersToRelations } from "@/utils/AddUsersToRelations/AddUsersToRelations.util";
import { OptionType } from "@/components/v3/Fields/Select/components/Option/Option.type";
import { useFeatureFlags } from "@/providers/FeatureFlags/FeatureFlags.provider";

export const Activities = () => {
    const { user, users } = useMe();
    const { useNewActivityUI } = useFeatureFlags();
    const {
        selectedUsers,
        setSelectedUsers,
        update
    } = useForYouPage();
    const [selectedRelations, setSelectedRelations] = useState<RelationActionType[]>([]);
    async function fetchRecentActivities(offset: number, limit: number) {
        return await GetActivitiesDetailsFetch({
            userId: user?.id,
            limit,
            offset,
            users: selectedUsers,
            relations: selectedRelations
        }).then(async (res: ActivitiesDetailsAxiosResponseSuccessType) => {
            const nodeIds = res.data.activities.map((activity) => activity.node.id);
            const importantUsers = await fetchImportantUsers(nodeIds);
            const wrappers = res.data.activities.map((activity) => {
                const listOfRelationships = addUsersToRelations(users, [activity.relationships]);
                const importantInfo = importantUsers.find(info => info.nodeId === activity.node.id)!
                return {
                    node: activity.node,
                    messages: activity.message,
                    relationships: listOfRelationships[0],
                    importantUserIds: importantInfo.userIds
                }
            });
            return wrappers;
        })
    };
    const { items, isFetching, refresh } = useInfiniteScroll(fetchRecentActivities);


    useEffect(() => {
        refresh();
    }, [selectedUsers, selectedRelations]);
    
    const groupedNodes = useMemo((): ({
        node: NodeType;
        messages: ActivityMessageType[];
        relationships: NodeRelationTypeWithUser;
        importantUserIds: string[];
        lastActor: ActorTypeWithUser | null;
    })[][] => {
        const groupsMap = new Map();
        items.forEach(wrapper => {
            const lastActor = findLastActor(wrapper.relationships?.actors || []);
            const happenedAtDate = lastActor?.properties.happenedAt || wrapper.node.createdAt;
            const momentDate = moment(happenedAtDate).format('YYYY-MM-DD');
            if (!groupsMap.has(momentDate)) {
                groupsMap.set(momentDate, []);
            }
            groupsMap.get(momentDate).push({
                ...wrapper,
                lastActor
            });
        });
        return Array.from(groupsMap.values());
    }, [items]);
    const groupsJsx = groupedNodes.map((group, i) => {
        return <ActivityGroup
            key={i}
            date={group[0].node.createdAt}
            group={group.map((wrapper) => {
                return {
                    node: wrapper.node,
                    activities: wrapper.messages,
                    relationships: wrapper.relationships,
                    users: users,
                    onUpdate: update,
                    importantUserIds: wrapper.importantUserIds,
                    lastActor: wrapper.lastActor
                }
            })}
        />
    });
    const isEmptySpace = !groupedNodes.length && !isFetching && !selectedUsers.length && !selectedRelations.length;
    const listOfActivityOptions: OptionType<string>[] = [
        {
            label: 'Mentioned you',
            value: 'MENTIONED_YOU',
        },
        {
            label: 'Assigned to you',
            value: 'ASSIGNED_TO_YOU',
        },
        {
            label: 'Task status changed',
            value: 'TASK_STATUS_CHANGED',
        },
        {
            label: 'Commented',
            value: 'COMMENTED',
        },
        {
            label: 'Created',
            value: 'CREATED',
        },
        {
            label: 'Edited',
            value: 'EDITED',
        },
        {
            label: 'Shared',
            value: 'SHARED',
        },
        {
            label: 'Sent to you',
            value: 'SENT_TO_YOU',
        },
        {
            label: 'Added manually',
            value: 'ADDED_MANUALLY',
        },
    ];
    const setSelectedRelationsHandler = (val: string[]) => {
        const newVal: RelationActionType[] = [];
        if (val.includes('MENTIONED_YOU')) {
            newVal.push(RelationActionType.MENTIONS);
            newVal.push(RelationActionType.CC);
            newVal.push(RelationActionType.BCC);
        }
        if (val.includes('ASSIGNED_TO_YOU')) {
            newVal.push(RelationActionType.ASSIGNEE);
        }
        if (val.includes('TASK_STATUS_CHANGED')) {
            newVal.push(RelationActionType.UPDATED);
        }
        if (val.includes('COMMENTED')) {
            newVal.push(RelationActionType.COMMENTED_BY);
            newVal.push(RelationActionType.COMMENTER);
            newVal.push(RelationActionType.POSTED_BY);
        }
        if (val.includes('CREATED')) {
            newVal.push(RelationActionType.CREATED_BY);
            newVal.push(RelationActionType.CREATOR);
        }
        if (val.includes('EDITED')) {
            newVal.push(RelationActionType.EDITED_BY);
        }
        if (val.includes('SHARED')) {
            newVal.push(RelationActionType.SHARED_TO);
            newVal.push(RelationActionType.SHARED_BY);
        }
        if (val.includes('SENT_TO_YOU')) {
            newVal.push(RelationActionType.FROM);
        }
        if (val.includes('ADDED_MANUALLY')) {
            newVal.push(RelationActionType.ADDED_BY);
        }
        setSelectedRelations(newVal);
    };
    const activityValue = useMemo(() => {
        const val: string[] = [];
        if (selectedRelations.includes(RelationActionType.MENTIONS) && selectedRelations.includes(RelationActionType.CC) && selectedRelations.includes(RelationActionType.BCC)) {
            val.push('MENTIONED_YOU');
        }
        if (selectedRelations.includes(RelationActionType.ASSIGNEE)) {
            val.push('ASSIGNED_TO_YOU');
        }
        if (selectedRelations.includes(RelationActionType.UPDATED)) {
            val.push('TASK_STATUS_CHANGED');
        }
        if (selectedRelations.includes(RelationActionType.COMMENTED_BY) && selectedRelations.includes(RelationActionType.COMMENTER) && selectedRelations.includes(RelationActionType.POSTED_BY)) {
            val.push('COMMENTED');
        }
        if (selectedRelations.includes(RelationActionType.CREATED_BY) && selectedRelations.includes(RelationActionType.CREATOR)) {
            val.push('CREATED');
        }
        if (selectedRelations.includes(RelationActionType.EDITED_BY)) {
            val.push('EDITED');
        }
        if (selectedRelations.includes(RelationActionType.SHARED_TO) && selectedRelations.includes(RelationActionType.SHARED_BY)) {
            val.push('SHARED');
        }
        if (selectedRelations.includes(RelationActionType.FROM)) {
            val.push('SENT_TO_YOU');
        }
        if (selectedRelations.includes(RelationActionType.ADDED_BY)) {
            val.push('ADDED_MANUALLY');
        }
        return val;
    }, [selectedRelations]);
    return (
        <LoaderLayout isLoading={isFetching && !items.length} transparent={!!items.length}>
            <EmptyStateLayoyt
                value={isEmptySpace}
                emptyNode={<EmptyState />}
            >
                <StyledWrapper>
                    {!useNewActivityUI && <StyledRelationsFiltersWrapper>
                        <RelationCheckboxButton val={[RelationActionType.MENTIONS, RelationActionType.CC, RelationActionType.BCC]} value={selectedRelations} onChange={setSelectedRelations}>Mentioned you</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.ASSIGNEE]} value={selectedRelations} onChange={setSelectedRelations}>Assigned to you</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.UPDATED]} value={selectedRelations} onChange={setSelectedRelations}>Task status changed</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.COMMENTED_BY, RelationActionType.COMMENTER, RelationActionType.POSTED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Commented</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.CREATED_BY, RelationActionType.CREATOR]} value={selectedRelations} onChange={setSelectedRelations}>Created</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.EDITED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Edited</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.SHARED_TO, RelationActionType.SHARED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Shared</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.FROM]} value={selectedRelations} onChange={setSelectedRelations}>Sent to you</RelationCheckboxButton>
                        <RelationCheckboxButton val={[RelationActionType.ADDED_BY]} value={selectedRelations} onChange={setSelectedRelations}>Added manually</RelationCheckboxButton>
                    </StyledRelationsFiltersWrapper>}
                    <StyledFiltersWrapper>
                        {useNewActivityUI && <StyledSelect
                            label="Activity"
                            value={activityValue}
                            onChange={(val: any) => setSelectedRelationsHandler(val)}
                            options={listOfActivityOptions}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            icon={<FilterIcon />}
                        />}
                        
                        <StyledSelect
                            label="People"
                            value={selectedUsers}
                            onChange={(val: any) => setSelectedUsers(val)}
                            options={users.map((user) => {
                                return { label: `${user.firstName} ${user.lastName}`, value: user.id, img: user.avatar || "/icons/no_avatar.svg"}
                            })}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            icon={<FilterIcon />}
                            id="walkthrough-updates"
                        />
                    </StyledFiltersWrapper>
                    {groupsJsx}
                    {isFetching && <Loader/>}
                </StyledWrapper>
            </EmptyStateLayoyt>
        </LoaderLayout>
    )
}