import { useEffect, useMemo, useState } from "react";
import {
    FormControl,
    FormGroup,
    Typography
} from "@mui/material";
import { StyledApplicationIcon, StyledCancelButton, StyledDescription, StyledDescriptionTitle, StyledTitleContainer } from "./ConnectChannels.styled";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { ConnectChannelsProps, UserProjectType } from "./ConnectChannels.type";
import {
    GetChannelsFetch,
    SynchronizeChannelsFetch
} from "@/axios/SlackIntegrationService/Channels/Channels.api";
import {
    ChannelsAxiosResponseSuccessType
} from "@/axios/SlackIntegrationService/Channels/Types/Get/Get.type";

import {
    GetSpacesFetch as GetClickupSpacesFetch,
    SynchronizeSpacesFetch as SynchronizeClickupSpacesFetch
} from "@/axios/ClickUpService/Spaces/Spaces.api";
import {
    SpacesAxiosResponseSuccessType as ClickupSpacesAxiosResponseSuccessType
} from "@/axios/ClickUpService/Spaces/Types/Get/Get.type";

import {
    GetBoardsFetch as GetTrelloBoardsFetch,
    SynchronizeBoardsFetch as SynchronizeTrelloBoardsFetch
} from "@/axios/TrelloService/Boards/Boards.api";
import {
    BoardsAxiosResponseSuccessType as TrelloBoardsAxiosResponseSuccessType
} from "@/axios/TrelloService/Boards/Types/Get/Get.type";

import {
    GetProjectsFetch as GetAsanaProjectsFetch,
    SynchronizeProjectsFetch as SynchronizeAsanaProjectsFetch
} from "@/axios/AsanaService/Projects/Projects.api";
import {
    ProjectsAxiosResponseSuccessType as AsanaProjectsAxiosResponseSuccessType
} from "@/axios/AsanaService/Projects/Types/Get/Get.type";

import {
    GetReposFetch as GetGitHubReposFetch,
    SynchronizeReposFetch as SynchronizeGitHubReposFetch
} from "@/axios/GiHubService/Repos/Repos.api";
import {
    ReposAxiosResponseSuccessType as GitHubReposAxiosResponseSuccessType
} from "@/axios/GiHubService/Repos/Types/Get/Get.type";

import {
    GetProjectsFetch as GetJiraProjectsFetch,
    GetSpacesFetch as GetConfluenceSpacesFetch,
    SynchronizeProjectsFetch as SynchronizeAtlassianProjectsFetch
} from "@/axios/AtlassianService/Projects/Projects.api";
import {
    ProjectsAxiosResponseSuccessType as JiraProjectsAxiosResponseSuccessType
} from "@/axios/AtlassianService/Projects/Types/GetJira/GetJira.type";
import {
    SpacesAxiosResponseSuccessType as ConfluenceSpacesAxiosResponseSuccessType
} from "@/axios/AtlassianService/Projects/Types/GetConfluence/GetConfluence.type";

import {
    GetChannelsFetch as GetTeamsChannelsFetch,
    SynchronizeProjectsFetch as SynchronizeAzureProjectsFetch
} from "@/axios/AzureService/Projects/Projects.api";
import {
    ChannelsAxiosResponseSuccessType as TeamsChannelsAxiosResponseSuccessType
} from "@/axios/AzureService/Projects/Types/GetTeams/GetTeams.type";

import { Checkbox } from "./components/Checkbox/Checkbox.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { getButtonLabel, getDescription, getEmptyDescription, getEmptyDescriptionTitle, getIcon, getTitle } from "./ConnectChannels.utils";
import { Dialog } from "../../Other/Dialog/Dialog.component";
import { DialogLayout } from "../../Layouts/DialogLayout/DialogLayout.component";
import { Button } from "../../Fields/Button/Button.component";
import { EmptyStateLayoyt } from "../../Layouts/EmptyStateLayoyt/EmptyStateLayoyt.component";
import { ToastService } from "@/service/ToastService";

export const ConnectChannels = ({isOpen, setIsOpen, isOnboarding}: ConnectChannelsProps) => {
    const { user } = useMe();
    const [checkboxList, setCheckboxList] = useState<UserProjectType[]>([]);
    const [checkboxList2, setCheckboxList2] = useState<UserProjectType[]>([]);
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const [selectedChannels2, setSelectedChannels2] = useState<string[]>([]);
    const router = useRouter();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const titles = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return [getTitle(isOpen)];
            case 'click_up':
                return [getTitle(isOpen)];
            case 'asana':
                return [getTitle(isOpen)];
            case 'trello':
                return [getTitle(isOpen)];
            case 'github':
                return [getTitle(isOpen)];
            case 'atlassian':
                return [
                    getTitle('jira'),
                    getTitle('confluence')
                ];
            case 'microsoft_teams':
                return [getTitle('teams')];
        }
        return [];
    }, [isOpen]);
    const descriptions = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return [getDescription(isOpen)];
            case 'click_up':
                return [getDescription(isOpen)];
            case 'asana':
                return [getDescription(isOpen)];
            case 'trello':
                return [getDescription(isOpen)];
            case 'github':
                return [getDescription(isOpen)];
            case 'atlassian':
                return [
                    getDescription('jira'),
                    getDescription('confluence')
                ];
            case 'microsoft_teams':
                return [getDescription('teams')];
        }
        return [];
    }, [isOpen]);
    const emptyDescriptionTitle = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return [getEmptyDescriptionTitle(isOpen)];
            case 'click_up':
                return [getEmptyDescriptionTitle(isOpen)];
            case 'asana':
                return [getEmptyDescriptionTitle(isOpen)];
            case 'trello':
                return [getEmptyDescriptionTitle(isOpen)];
            case 'github':
                return [getEmptyDescriptionTitle(isOpen)];
            case 'atlassian':
                return [
                    getEmptyDescriptionTitle('jira'),
                    getEmptyDescriptionTitle('confluence')
                ];
            case 'microsoft_teams':
                return [getEmptyDescriptionTitle('teams')];
        }
        return [];
    }, [isOpen]);
    const emptyDescription = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return [getEmptyDescription(isOpen)];
            case 'click_up':
                return [getEmptyDescription(isOpen)];
            case 'asana':
                return [getEmptyDescription(isOpen)];
            case 'trello':
                return [getEmptyDescription(isOpen)];
            case 'github':
                return [getEmptyDescription(isOpen)];
            case 'atlassian':
                return [
                    getEmptyDescription('jira'),
                    getEmptyDescription('confluence')
                ];
            case 'microsoft_teams':
                return [getEmptyDescription('teams')];
        }
        return [];
    }, [isOpen]);
    const icons = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return [getIcon(isOpen)];
            case 'click_up':
                return [getIcon(isOpen)];
            case 'asana':
                return [getIcon(isOpen)];
            case 'trello':
                return [getIcon(isOpen)];
            case 'github':
                return [getIcon(isOpen)];
            case 'atlassian':
                return [
                    getIcon('jira'),
                    getIcon('confluence')
                ];
            case 'microsoft_teams':
                return [getIcon('teams')];
        }
        return [];
    }, [isOpen]);
    const buttonLabel = useMemo(() => {
        switch (isOpen) {
            case 'slack':
                return getButtonLabel(isOpen, selectedChannels.length + selectedChannels2.length)
            case 'click_up':
                return getButtonLabel(isOpen, selectedChannels.length + selectedChannels2.length)
            case 'asana':
                return getButtonLabel(isOpen, selectedChannels.length + selectedChannels2.length)
            case 'trello':
                return getButtonLabel(isOpen, selectedChannels.length + selectedChannels2.length)
            case 'github':
                return getButtonLabel(isOpen, selectedChannels.length + selectedChannels2.length)
            case 'atlassian':
                return 'Add'
            case 'microsoft_teams':
                return getButtonLabel('teams', selectedChannels.length + selectedChannels2.length)
        }
        return [];
    }, [isOpen, selectedChannels.length, selectedChannels2.length]);

    const isButtonDisabled = !selectedChannels.length && !selectedChannels2.length;

    useEffect(() => {
        if(user?.id) {
            switch (isOpen) {
                case 'slack':
                    GetChannelsFetch({
                        userId: user.id
                    }).then((res: ChannelsAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.channels.map((channel) => {
                            return {
                                id: channel.channelId,
                                name: channel.name
                            }
                        }));
                        setSelectedChannels(res.data.channels.map((channel) => channel.channelId));
                    }).finally(() => setIsLoading(false))
                    break;
                case 'click_up':
                    GetClickupSpacesFetch({
                        userId: user.id
                    }).then((res: ClickupSpacesAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.spaces);
                        setSelectedChannels(res.data.spaces.map((space) => space.id));
                    }).finally(() => setIsLoading(false))
                    break;
                case 'trello':
                    GetTrelloBoardsFetch({
                        userId: user.id
                    }).then((res: TrelloBoardsAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.boards);
                        setSelectedChannels(res.data.boards.map((board) => board.id));
                    }).finally(() => setIsLoading(false))
                    break;
                case 'asana':
                    GetAsanaProjectsFetch({
                        userId: user.id
                    }).then((res: AsanaProjectsAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.projects.map((project) => {
                            return {
                                id: project.gid,
                                name: project.name
                            }
                        }));
                        setSelectedChannels(res.data.projects.map((project) => project.gid));
                    }).finally(() => setIsLoading(false))
                    break;
                    
                case 'github':
                    GetGitHubReposFetch({
                        userId: user.id
                    }).then((res: GitHubReposAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.repositories.map((repository) => {
                            return {
                                id: repository.id,
                                name: repository.name
                            }
                        }));
                        setSelectedChannels(res.data.repositories.map((repository) => repository.id));
                    }).finally(() => setIsLoading(false))
                    break;
                case 'atlassian':
                    GetJiraProjectsFetch({
                        userId: user.id
                    }).then((res: JiraProjectsAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.projects);
                        setSelectedChannels(res.data.projects.map((project) => project.id));
                    }).finally(() => setIsLoading(false))
                    GetConfluenceSpacesFetch({
                        userId: user.id
                    }).then((res: ConfluenceSpacesAxiosResponseSuccessType) => {
                        setCheckboxList2(res.data.spaces);
                        setSelectedChannels2(res.data.spaces.map((space) => space.id));
                    }).finally(() => setIsLoading(false))
                    break;
                case 'microsoft_teams':
                    GetTeamsChannelsFetch({
                        userId: user.id
                    }).then((res: TeamsChannelsAxiosResponseSuccessType) => {
                        setCheckboxList(res.data.teams.map((team) => {
                            return {
                                id: team.teamId,
                                name: team.name,
                            }
                        }));
                        setSelectedChannels(res.data.teams.map((team) => team.teamId));
                    }).finally(() => setIsLoading(false))
                    break;
            }
        }
    },[isOpen, user?.id]);
    const checkboxListJsx = checkboxList.map(checkbox => {
        return <Checkbox value={selectedChannels} onChange={setSelectedChannels} label={checkbox.name} val={checkbox.id} key={checkbox.id} />
    });
    const checkboxList2Jsx = checkboxList2.map(checkbox => {
        return <Checkbox value={selectedChannels2} onChange={setSelectedChannels2} label={checkbox.name} val={checkbox.id} key={checkbox.id} />
    });

    const handleConnection = () => {
        switch (isOpen) {
            case 'slack':
                SynchronizeChannelsFetch({
                    channelIds: selectedChannels
                }).then(data => {
                    ToastService.showToast('success', 'Channels connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'slack'
                    }
                });
                break;
            case 'click_up':
                SynchronizeClickupSpacesFetch({
                    spaceIds: selectedChannels
                }).then(data => {
                    ToastService.showToast('success', 'Spaces connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'click_up'
                    }
                });
                break;
            case 'trello':
                SynchronizeTrelloBoardsFetch({
                    trelloBoardIds: selectedChannels
                }).then(data => {
                    ToastService.showToast('success', 'Spaces connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'trello'
                    }
                });
                break;
            case 'asana':
                SynchronizeAsanaProjectsFetch({
                    asanaProjects: selectedChannels.map((channel) => {
                        return {
                            gid: channel
                        }
                    })
                }).then(data => {
                    ToastService.showToast('success', 'Projects connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'asana'
                    }
                });
                break;
            case 'github':
                SynchronizeGitHubReposFetch({
                    repositoryIds: selectedChannels
                }).then(data => {
                    ToastService.showToast('success', 'Projects connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'github'
                    }
                });
                break;
            case 'atlassian':
                SynchronizeAtlassianProjectsFetch({
                    jiraProjectIds: selectedChannels,
                    confluenceSpaceIds: selectedChannels2
                }).then(data => {
                    ToastService.showToast('success', 'Projects connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'atlassian'
                    }
                });
                if (!!router.query.spaceApp && isOnboarding) {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: isOnboarding ? undefined : 'manage-integrations',
                            type: 'atlassian'
                        }
                    });
                }
                break;
            case 'microsoft_teams':
                SynchronizeAzureProjectsFetch({
                    teamIds: selectedChannels,
                }).then(data => {
                    ToastService.showToast('success', 'Projects connected!');
                })
                .catch(error => {
                    ToastService.showToast('error', 'Some error occurred! Please try re-add links!');
                });
                setIsOpen && setIsOpen(false);
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: isOnboarding ? undefined : 'manage-integrations',
                        type: 'microsoft_teams'
                    }
                });
                break;
        }
    }
    const isEmpty1 = !checkboxList.length;
    const isEmpty2 = !checkboxList2.length;
    const isEmpty = isEmpty1 && isEmpty2;
    
    return (
        <Dialog size="small" value={!!isOpen} onChange={() => setIsOpen(false)}>
            <DialogLayout
                isLoading={isLoading}
                title={<StyledTitleContainer>
                    <StyledApplicationIcon application={icons[0]}  />
                    {!!isOpen && titles[0]}
                </StyledTitleContainer>}
                actions={<>
                    {!isEmpty && <>
                        <StyledCancelButton size="large" variant="error" onClick={() => {
                            setIsOpen && setIsOpen(false);
                            !isOnboarding && router.push({ name: router.name, params: router.params, query: {} })
                        }}>
                            Cancel
                        </StyledCancelButton>
                        <Button size="large" onClick={handleConnection} disabled={isButtonDisabled}>
                            {buttonLabel}
                        </Button>
                    </>}
                    {isEmpty && <>
                        <StyledCancelButton size="large" variant="error" onClick={() => {
                            setIsOpen && setIsOpen(false);
                            !isOnboarding && router.push({ name: router.name, params: router.params, query: {} })
                        }}>
                            Close
                        </StyledCancelButton>
                    </>}
                </>}
            >
                <EmptyStateLayoyt value={isEmpty1} emptyNode={<>
                    <StyledDescriptionTitle>
                        {!!isOpen && emptyDescriptionTitle[0]}
                    </StyledDescriptionTitle>
                    <StyledDescription>
                        {!!isOpen && emptyDescription[0]}
                    </StyledDescription>
                </>} >
                    <div>
                        <StyledDescription>
                            {!!isOpen && descriptions[0]}
                        </StyledDescription>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {checkboxListJsx}
                            </FormGroup>
                        </FormControl>
                    </div>
                </EmptyStateLayoyt>
                {!!titles[1] && !!checkboxList2Jsx.length && <div>
                    <EmptyStateLayoyt value={isEmpty2} emptyNode={<>
                        <StyledDescriptionTitle>
                            {!!isOpen && emptyDescriptionTitle[1]}
                        </StyledDescriptionTitle>
                        <StyledDescription>
                            {!!isOpen && emptyDescription[1]}
                        </StyledDescription>
                    </>} >
                        <StyledTitleContainer>
                            <StyledApplicationIcon application={icons[1]}  />
                            <Typography variant="h1" component="h1" style={{ fontSize: '24px', fontWeight: 600, paddingLeft: '10px' }}>
                                {!!isOpen && titles[1]}
                            </Typography>
                        </StyledTitleContainer>
                        <StyledDescription>
                            {!!isOpen && descriptions[1]}
                        </StyledDescription>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {checkboxList2Jsx}
                            </FormGroup>
                        </FormControl>
                    </EmptyStateLayoyt>
                </div>}
            </DialogLayout>
        </Dialog>
    )
}