import { FC } from "react";
import { SpaceProps } from "./Space.type";
import { StyledListItem, StyledWrapper } from "./Space.styled";
import { getIconPath } from "@/components/v3/Layouts/MainLayout/components/Sidebar/components/Spaces/components/Space/Space.utils";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { CreateSpaceRelationFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { UserType } from "@/axios/OrganizationService/Users/Types/Get/Get.type";
import { People } from "@/components/v3/List/People/People.component";

export const Space: FC<SpaceProps> = ({
    space
}) => {
    const { user, users: organizationUsers } = useMe();
    const { loadSpaces, mySpaces } = useSpaces();
    const isSelected = mySpaces.some(mySpace => mySpace.id === space.id);
    const spaceImage = getIconPath(space.application, false);

    const users = space.relatedUserIds.map(userId => (organizationUsers.find(el => el.id === userId))).filter((el) => {
        return !!el && el.id !== user?.id;
    }) as UserType[];
    
    const addToMySpacesHandler = async () => {
        if (!user?.id) return;
        await CreateSpaceRelationFetch([{
            spaceId: space.id,
            actors: [{
                userId: user.id,
                actionType: 'FOLLOW_BY',
                properties: {}
            }]
        }], {
            action: 'CREATE'
        }).then(data => {
            loadSpaces();
        })
    };
    const removeFromMySpacesHandler = async () => {
        if (!user?.id) return;
        await CreateSpaceRelationFetch([{
            spaceId: space.id,
            actors: [{
                userId: user.id,
                actionType: 'FOLLOW_BY',
                properties: {}
            }]
        }], {
            action: 'DELETE'
        }).then(data => {
            loadSpaces();
        })
    };
    return <StyledWrapper>
        <StyledListItem
            title={space.name}
            img={spaceImage}
        />
        <People maxDisplayCount={5} people={users.map(user => {
            return {
                user,
                actionType: ''
            }
        })} />
        {!isSelected && <Button onClick={addToMySpacesHandler}>Select</Button>}
        {isSelected && <Button variant="tertiary" onClick={removeFromMySpacesHandler}>Remove</Button>}
    </StyledWrapper>;
}