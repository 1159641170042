import { useState } from "react";
import { StyledPeopleContainer, StyledButton, StyledButtonContent, StyledPopper } from "./People.styled";
import { PeopleProps } from "./People.type";
import { Person } from "./components/Person/Person.components";
import { ClickAwayListener, Paper, Fade } from '@mui/material';
import { ToolpipContent } from "./components/ToolpipContent/ToolpipContent.component";

export const People = ({ people, className, useSkeleton = true, isExpired = false, maxDisplayCount = 3 }: PeopleProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleTooltipClose = () => {
        setAnchorEl(null);
    };

    const handleTooltipOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'popper' : undefined;

    const creatorIds = new Set();
    people.forEach(el => {
        if (el.actionType === 'CREATOR' || el.actionType === 'CREATED_BY') {
            creatorIds.add(el.user.id);
        }
    });
    const filteredPeoples = people.filter(el => {
        return el.actionType === 'CREATOR' || el.actionType === 'CREATED_BY' || !creatorIds.has(el.user.id);
    });
    const displayedUsers = filteredPeoples.slice(0, maxDisplayCount);
    const remainingUsers = filteredPeoples.slice(maxDisplayCount);

    const displayedUsersJsx = displayedUsers.map((el, i) => {
        return <Person key={i} user={el.user} actionType={el.actionType} useSkeleton={useSkeleton} isExpired={isExpired} />
    })
    return (
        <StyledPeopleContainer className={className}>
            {displayedUsersJsx}

            {remainingUsers.length > 0 && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <StyledButton>
                            <StyledButtonContent
                                onMouseEnter={handleTooltipOpen}
                                onMouseLeave={handleTooltipClose}
                            >
                                +{remainingUsers.length}
                            </StyledButtonContent>
                        </StyledButton>

                        <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="top" transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                        <ToolpipContent people={people} />
                                    </Paper>
                                </Fade>
                            )}
                        </StyledPopper>
                    </div>
                </ClickAwayListener>
            )}
        </StyledPeopleContainer>
    )
}