import { FC, useEffect, useMemo, useState } from 'react';
import { HttpClient } from '@/axios/HttpClient';
import { useMe } from 'providers/MeProvider/Me.provider';
import { ErrorsType } from './InviteTeammates.type';
import { InvitedUsersListItem } from './components/InvitedUsersListItem/InvitedUsersListItem.component';
import { UserType } from "@/axios/OrganizationService/Users/Types/GetInvites/GetInvites.type";
import {
  StyledInput,
  StyledInputWrapper,
  StyledNoUsers,
  StyledOnboardingContent,
  StyledOnboardingStep,
  StyledSendInviteButton,
  StyledFilledCard,
  StyledIndividualTip,
  StyledActions,
  StyledSectionHeader
} from './InviteTeammates.styled';
import { SendInvitesFetch } from '@/axios/OrganizationService/Users/Users.api';
import { EventTypes, useAmplitude } from '@/service/TrackingService';
import { useRouter } from '@/providers/Router/Router.provider';
import { LoaderLayout } from '@/components/v3/Layouts/LoaderLayout/LoaderLayout.component';
import { ToastService } from '@/service/ToastService';
import { envUrl } from '@/service/helpers';
import { ConfirmInviteModal } from '@/components/ConfirmInviteModal/ConfirmInviteModal.component';
import { StyledOnboardingLayout } from '../ManageIntegrations/ManageIntegrations.styled';
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { UpgradeToTeamPlanModal } from '@/components/v3/Modals/UpgradeToTeamPlanModal/UpgradeToTeamPlanModal.component';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';

export const InviteTeammates: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<ErrorsType>({});
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useRouter();
  const { user, loadUser, invitedUsers, confirmedInvitedUsers, loadInvitedUsers } = useMe();
  const users = useMemo((): UserType[] => {
    const pendingUsers = invitedUsers.map((email: string) => ({ 'fullName': '', 'email': email, 'status': 'pending', isAdmin: false } as UserType));
    const invitedFullUsers = confirmedInvitedUsers.map((user: any) => ({ 'fullName': user.fullName, 'email': user.email, 'status': 'invited', 'avatar': user.avatar, 'id': user.id, isAdmin: user.isAdmin } as UserType));
    return [...invitedFullUsers, ...pendingUsers]
  }, [invitedUsers, confirmedInvitedUsers]);
  const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
  const trackEvent = useAmplitude();

  const finishHandler = async () => {
    setIsLoading(true);
    if (users.length) {
      const emails = users.map((user: any) => user.email)
      user?.organizationId && await SendInvitesFetch({
        organizationId: user.organizationId,
        emails: emails,
      }).then(() => {
        trackEvent(EventTypes.ONBOARDING_INVITE_USERS, {
          usersCount: emails.length
        })
        ToastService.showToast('success', 'Invites successfully sent!');
        loadInvitedUsers();
      }).catch(error => {
        ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
      });
    }
    await HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
      onboarded: true,
    }).then(() => {
      loadUser();
    });
    localStorage.setItem('selectedSpace', 'My Spaces');
    router.push({
      name: 'forYou'
    })
    localStorage.removeItem("onboardingStep");
    localStorage.removeItem("plan");
    setIsLoading(false);
  }

  const validate = (newEmail: string) => {
    let tempErrors: ErrorsType = {};
    if (!newEmail) tempErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(newEmail)) tempErrors.email = "Invalid email format";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  const submit = async (newEmail: string) => {
    if (validate(newEmail)) {
      if (isIndividual) {
        router.push({
          name: router.name,
          params: router.params,
          query: {
              ...router.query,
              dialog: !!router.query.dialog ? Array.isArray(router.query.dialog) ? router.query.dialog : [router.query.dialog as string, 'payment'] : 'payment',
              invite: !!newEmail ? newEmail : undefined
          }
      })
        return
      }
      setIsLoading(true);
      await HttpClient.post(`${envUrl}/organization-service/v1/users/invites`, {
        organizationId: user?.organizationId,
        emails: [newEmail],
      })
        .then(response => response.data)
        .then(async data => {
          ToastService.showToast('success', 'Invites successfully sent!');
          setEmail('');
          if (!!router.query.invite) {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                ...router.query,
                invite: undefined,
              }
            })
          }
          await loadInvitedUsers()
          setIsLoading(false);
        })
        .catch(error => {
          ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    if (router.query.invite && !router.query.dialog) {
      submit(router.query.invite as string)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.invite]);
  const headerTitle = useMemo(() => {
    if (users.length>1) {
      return `${users.length} teammates to be invited`
    }
    return `${users.length} teammate to be invited`
  }, [users.length]);
  const { confirm, component } = useConfirm({
    title: "Remove Users",
    content: <>
      Removing users will erase all their data on Sense. They will not be able to use their Sense account anymore.
      The billing will be updated in the end of your current billing cycle.
    </>,
    cancelButtonProps: {
      children: "Keep users",
      variant: 'primary',
    },
    submitButtonProps: {
      children: "Remove users",
      variant: 'error'
    },
  });
  const removeAllHandler = async () => {
    confirm(async (result) => {
      if (!!result) {
        setIsLoading(true);
        await HttpClient.delete(`${envUrl}/organization-service/v1/users/invites`, {
          data: {
            organizationId: user?.organizationId,
            emails: users.map((user) => user.email),
          }
        })
          .then(response => response.data)
          .then(data => {
            ToastService.showToast('success', 'Email successfully removed!');
          })
          .catch(error => {
            ToastService.showToast('error', 'Some error occurred! Please try re-send email!');
          })
          await loadInvitedUsers();
          setIsLoading(false);
      }
    })
  }
  return (
    <StyledOnboardingLayout title="Invite teammates">
      <LoaderLayout isLoading={isLoading} transparent>
        <StyledOnboardingContent>
          <StyledOnboardingStep>
            <StyledInputWrapper>
              <StyledInput size="medium" variant="outlined" error={errors.email} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your teammate email" />
              <StyledSendInviteButton variant={!!users.length ? 'secondary' : 'primary'} center onClick={() => submit(email)} size="large">Add</StyledSendInviteButton>
            </StyledInputWrapper>
            {/* {!isIndividual && !!users.length && <StyledOnboardingNotice>
              You will not pay anything now. You will be able to manage users before you have to pay after 14 days free trial.
            </StyledOnboardingNotice>} */}
            {isIndividual && <StyledIndividualTip>You are on Individual Plan, which includes only 1 user. To invite more users you need to upgrade your subscription to Team Plan. </StyledIndividualTip>}
            <StyledFilledCard>
              {!!users.length && !isIndividual ? <>
                <StyledSectionHeader title={headerTitle} >
                  <Button onClick={removeAllHandler} variant="tertiary" size="medium" >Remove all</Button>
                </StyledSectionHeader>
                {users.map((user, i) => {
                  return <InvitedUsersListItem isLoading={isLoading} setIsLoading={setIsLoading} key={i} user={user} />
                })}
              </> : <StyledNoUsers>
                Add your teammates to let them access single place for your team work
              </StyledNoUsers>}
            </StyledFilledCard>
            <StyledActions>
              {!!users.length && <Button onClick={finishHandler} size="medium">Invite and finish</Button>}
              {!users.length && <Button onClick={finishHandler} variant="secondary" size="medium">Skip this step</Button>}
            </StyledActions>
          </StyledOnboardingStep>
        </StyledOnboardingContent>
        <ConfirmInviteModal isOpen={isInviteModalOpen} setIsOpen={setIsInviteModalOpen} finishHandler={finishHandler} users={users} />
        <UpgradeToTeamPlanModal />
      </LoaderLayout>
      {component}
    </StyledOnboardingLayout>
  );
};
