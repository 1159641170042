import { FC, useCallback, useEffect, useState } from "react";
import { Dialog } from "@/components/v3/Other/Dialog/Dialog.component";
import { StyledDialogLayout, StyledInput } from "./OnboardingProjects.styled";
import { useIsModalOpened } from "@/hooks/v3/UseIsModalOpened/UseIsModalOpened.hook";
import { useSpaces } from "@/providers/SpacesProvider/Spaces.provider";
import { SectionHeader } from "../../Sections/SectionHeader/SectionHeader.component";
import { StyledSearchIcon, StyledSearchIconWrapper } from "../../Layouts/MainLayout/components/Header/components/Search/components/SearchTextField/SearchTextField.styled";
import { StyledCloseIcon } from "../../Layouts/MainLayout/components/Header/components/Search/Search.styled";
import { Recomended } from "./components/Recomended/Recomended.component";
import { Integrations } from "./components/Integrations/Integrations.component";
import { SpacesAxiosResponseSuccessType, SpaceType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { GetSpacesFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { Button } from "../../Fields/Button/Button.component";
import { useRouter } from "@/providers/Router/Router.provider";


export const OnboardingProjects: FC = () => {
    const [search, setSearch] = useState<string>('');
    const [recomendedSpaces, setRecomendedSpaces] = useState<SpaceType[]>([]);
    const [isFetchingRecomended, setIsFetchingRecomended] = useState<boolean>(false);
    const { value, onChange: onChangeIsOpened } = useIsModalOpened({ name: 'onboarding-projects' });
    const onChange = (value: boolean) => {
        onChangeIsOpened(value);
    };
    const { user, users: organizationUsers } = useMe();
    const router = useRouter();
    const { isFetching, spaces, loadSpaces } = useSpaces();
    const slackSpaces = spaces.filter(space => space.application === 'Slack');
    const confluenceSpaces = spaces.filter(space => space.application === 'Confluence');
    const jiraSpaces = spaces.filter(space => space.application === 'Jira');
    const asanaSpaces = spaces.filter(space => space.application === 'Asana');
    const githubSpaces = spaces.filter(space => space.application === 'Github');
    const clickUpSpaces = spaces.filter(space => space.application === 'ClickUp');
    const trelloSpaces = spaces.filter(space => space.application === 'Trello');
    const microsoftTeamsSpaces = spaces.filter(space => space.application === 'MicrosoftTeams');
    const notionSpaces = spaces.filter(space => space.application === 'Notion');
    const projectsCount = slackSpaces.length + confluenceSpaces.length + jiraSpaces.length + asanaSpaces.length + githubSpaces.length + clickUpSpaces.length + trelloSpaces.length + microsoftTeamsSpaces.length + notionSpaces.length;
    const loadRecomendedSpaces = useCallback(async () => {
        if (user?.id) {
            setIsFetchingRecomended(true);
            await GetSpacesFetch({
                userId: user.id
            }).then((res: SpacesAxiosResponseSuccessType) => {
                setRecomendedSpaces(res.data);
            });
            setIsFetchingRecomended(false);
        }
    }, [user?.id])
    useEffect(() => {
        value && loadSpaces();
    }, [loadSpaces, value]);
    useEffect(() => {
        value && loadRecomendedSpaces();
    }, [loadRecomendedSpaces, value]);
    const haveUsers = (organizationUsers.filter((u) => u.id !== user?.id)).length > 0;
    return <Dialog value={value} onChange={onChange} disableEscapeKeyDown>
        <StyledDialogLayout
            title={`${projectsCount} projects found`}
            isLoading={isFetching || isFetchingRecomended}
            actions={<>
                {/* <StyledCancelButton size="large" variant="error" onClick={() => onChange(false)}>
                    Cancel
                </StyledCancelButton> */}
                {haveUsers && <Button size="large" variant="secondary" onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'onboarding-people'
                        }
                    })
                }}>
                    Next
                </Button>}
                {!haveUsers && <Button icon={<>👍</>} size="large" variant="secondary" onClick={() => {
                    // router.push({
                    //     name: router.name,
                    //     params: router.params,
                    //     query: {
                    //         ...router.query,
                    //         dialog: undefined
                    //     }
                    // })
                    const newUrl = router.getPathWithParams(router.name, router.params, {
                        ...router.query,
                        dialog: undefined
                    });
                    window.location.href = newUrl;
                }}>
                    Get started
                </Button>}
            </>}
        >
            <SectionHeader title="Which projects are you mostly interested in? " caption="These projects will be pinned for quick access. You will have access to all found projects that you are eligible to access." />
            <StyledInput
                placeholder="Search"
                variant="filled"
                size='medium'
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                    startAdornment: (
                        <StyledSearchIconWrapper>
                            <StyledSearchIcon />
                        </StyledSearchIconWrapper>
                    ),
                    endAdornment: <>
                        {!!search && <StyledCloseIcon onClick={() => setSearch('')} />}
                    </>,
                }}
            />
            <Recomended spaces={recomendedSpaces} search={search} />
            <Integrations search={search} />
        </StyledDialogLayout>
    </Dialog>
};