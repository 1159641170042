import { AppTypes } from "@/types/AppTypes/AppTypes.type";
import { AppType } from "./components/ListOfApps/components/App/App.type";

export const documentsApps: AppType[] = [
    {
        application: "Confluence",
        title: AppTypes.Confluence
    },
    {
        application: "Notion",
        title: AppTypes.Notion
    },
    {
        application: "GoogleDrive",
        title: AppTypes.GoogleDrive
    },
]
export const communicationsApps: AppType[] = [
    {
        application: "Gmail",
        title: AppTypes.Gmail
    },
    {
        application: "GoogleCalendar",
        title: AppTypes.GoogleCalendar
    },
    {
        application: "Outlook",
        title: AppTypes.Outlook
    },
    {
        application: "Slack",
        title: AppTypes.Slack
    },
    {
        application: "MicrosoftTeams",
        title: AppTypes.MicrosoftTeams
    },
]
export const otherApps: AppType[] = [
    {
        application: "Figma",
        title: AppTypes.Figma
    },
    {
        application: "Github",
        title: AppTypes.Github
    },
]
export const projectsApps: AppType[] = [
    {
        application: "Asana",
        title: AppTypes.Asana
    },
    {
        application: "Jira",
        title: AppTypes.Jira
    },
    {
        application: "Trello",
        title: AppTypes.Trello
    },
    {
        application: "ClickUp",
        title: AppTypes.ClickUp
    },
]
export const storagesApps: AppType[] = [
    {
        application: "GoogleDrive",
        title: AppTypes.GoogleDrive
    },
    {
        application: "Sharepoint",
        title: AppTypes.Sharepoint
    },
    {
        application: "Dropbox",
        title: AppTypes.Dropbox
    },
]