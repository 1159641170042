import {
  Menu,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useMe } from 'providers/MeProvider/Me.provider';
import { StyledArrow, StyledMenuBox, StyledMenuItem, StyledMenuName, StyledMobileName, StyledUserAvatar } from './AppMenu.styled';
import { useRouter } from '@/providers/Router/Router.provider';
import { HttpClient } from '@/axios/HttpClient';
import { envUrl } from '@/service/helpers';
import { PaymentPortal } from '@/components/Payments';
import { useModal } from '@/store/ModalContext';
import { useRenederBelow } from '@/hooks/v3/UseResponsive/UseResponsive.hook';
import { UserAvatar } from '@/components/v3/Other/UserAvatar/UserAvatar.component';
import { StartIntegrationDialog } from '@/components/v3/Modals/StartIntegrationDialog/StartIntegrationDialog.component';
import { IntegrationType } from '@/components/v3/Modals/ConnectChannels/ConnectChannels.type';
import { ConnectChannels } from '@/components/v3/Modals/ConnectChannels/ConnectChannels.component';

export const AppMenu = () => {
  const router = useRouter();

  const shouldRenederSmallName = useRenederBelow('tablet');

  const [anchorEl, setAnchorEl] = useState(null);
  const [isStartIntegrationModalOpen, setIsStartIntegrationModalOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { logout, user, invitedUsers, confirmedInvitedUsers } = useMe();

  const dialogName = router.query.dialog;
  const [isOpenConnectChannels, setIsOpenConnectChannels] = useState<IntegrationType | false>(false);

  const amountOfPaidUsers = useMemo(() => {
    return invitedUsers.length + confirmedInvitedUsers.length + 1;
}, [invitedUsers, confirmedInvitedUsers]);
  useEffect(() => {
    if (dialogName === 'manage-integrations' && !!router.query.type) {
      setIsStartIntegrationModalOpen(true);
    } else if (dialogName === 'inviteTeammates') {
      router.push({
        name: router.name,
        params: router.params,
        query: {
          ...router.query,
          dialog: 'manage-users',
        }
      })
    } else if (dialogName === 'connect-slack-channels') {
      setIsOpenConnectChannels('slack');
    } else if (dialogName === 'connect-click-up-projects') {
      setIsOpenConnectChannels('click_up');
    } else if (dialogName === 'connect-trello-boards') {
      setIsOpenConnectChannels('trello');
    } else if (dialogName === 'connect-asana-projects') {
      setIsOpenConnectChannels('asana');
    } else if (dialogName === 'connect-github-projects') {
      setIsOpenConnectChannels('github');
    } else if (dialogName === 'connect-atlassian-projects') {
      setIsOpenConnectChannels('atlassian');
    } else if (dialogName === 'connect-microsoft-teams-projects') {
      setIsOpenConnectChannels('microsoft_teams');
    } else if (dialogName === 'managePayment') {
      router.push({
        name: router.name,
        params: router.params,
        query: {
          ...router.query,
          dialog: 'payment',
          plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
        }
      })
    } else if (dialogName === 'openCreateSpaceModal') {
      router.push({
        name: router.name,
        params: router.params,
        query: {
            ...router.query,
            dialog: 'create-space',
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogName, router, amountOfPaidUsers]);
  const isSubscriptionPaid = user?.subscriptionStatus === "PAID";
  const isAdmin = user?.isAdmin;

  const getRedirectLinkHandlerClick = () => {
    HttpClient.get<PaymentPortal>(`${envUrl}/organization-service/v1/payment/portal?userId=${user?.id}`)
        .then(response => response.data.portalUrl)
        .then(data => {
          window.location.href = data
        });
  }

  return (
    <>
      <ConnectChannels
          isOpen={isOpenConnectChannels}
          setIsOpen={setIsOpenConnectChannels}
      />
      <StartIntegrationDialog value={isStartIntegrationModalOpen} onChange={setIsStartIntegrationModalOpen} />
      <StyledMenuBox
        onClick={handleClick}
      >
          <StyledMenuName id="open-menu-button" flex="1 1 0">
            {!shouldRenederSmallName ? (
              <>
                {!!user?.avatar && <StyledUserAvatar avatar={user.avatar} useSkeleton={false} />}
                <span>
                  {user?.firstName} {user?.lastName}
                </span>
              </>
            ) : (
              <>
                {!!user?.avatar && <UserAvatar avatar={user.avatar} />}
                {!user?.avatar && <StyledMobileName>{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}</StyledMobileName>}
              </>
            )}
            <StyledArrow src="/icons/icon-disclose.svg" alt="" />
          </StyledMenuName>
      </StyledMenuBox>
      <Menu
        style={{
          position: 'absolute',
          top: '10px',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.16)',
          borderRadius: '10px',
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'open-menu-button',
        }}
      >
        <StyledMenuItem
          onClick={() => {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                ...router.query,
                dialog: 'manage-integrations',
              }
            });
            handleMenuClose();
          }}
        >
          Manage integrations
        </StyledMenuItem>
        {isAdmin && <StyledMenuItem
            onClick={() => {
              router.push({
                name: router.name,
                params: router.params,
                query: {
                  ...router.query,
                  dialog: 'manage-users',
                }
              })
              handleMenuClose();
            }}
        >
          Manage users
        </StyledMenuItem>}
        {!isSubscriptionPaid && isAdmin && (
            <StyledMenuItem
                onClick={() => {
                  router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                      ...router.query,
                      dialog: 'payment',
                      plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                    }
                  })
                  handleMenuClose();
                }}
            >
              Payment
            </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => {
            router.push({
              name: router.name,
              params: router.params,
              query: {
                ...router.query,
                dialog: 'settings',
              }
            })
            handleMenuClose();
          }}
        >
          Settings
        </StyledMenuItem>
        
        {isSubscriptionPaid && isAdmin && (
            <StyledMenuItem
                onClick={() => {
                  // history.push('/invite');
                  // setOpenPaymentDialog(true);
                  // handleMenuClose();
                  getRedirectLinkHandlerClick()
                }}
            >
              Manage billing
            </StyledMenuItem>
        )}

        <StyledMenuItem
          onClick={() => {
            logout();
            handleMenuClose();
          }}
        >
          Logout
        </StyledMenuItem>
      </Menu>
    </>
  );
};
