import { styled } from "@mui/material";

export const StyledSettingsWrapper = styled('div')`
    border-top: 1px solid var(--Special-Divider-Light, rgba(0, 0, 0, 0.08));
    padding: 4px 8px;
`;
export const StyledContainer = styled('div')`
    padding: 4px 8px;
    border-radius: 8px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:hover {
        background: rgba(0,0,0,.06);
    }
`;
export const StyledMenuContainer = styled('div')`
    min-width: 320px;
`;