import { HttpClient } from "../../axios/HttpClient";
import { envUrl } from "../../service/helpers";
import { ToastService } from "../../service/ToastService";
import { useMe } from "providers/MeProvider/Me.provider";
import { useEffect } from "react";
import { StyledButton, StyledContainer, StyledEmail, StyledWrapper, Styledtext } from "./OnboardingConfirmationLink.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";

export const OnboardingConfirmationLinkPage = () => {
    const { user, logout, loadUser } = useMe();
    const organizationId = user?.organizationId;
    const handleResend = () => {
        HttpClient.post(`${envUrl}/organization-service/v1/organizations/${organizationId}/email`, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(data => ToastService.showToast('success', 'Link was successfully sent'))
    }
    useEffect(() => {
        if (!user?.email) {
            loadUser();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.email])
    return (
        <StyledContainer>
            <StyledWrapper>
                <Styledtext>
                    <div>We sent you confirmation link to <StyledEmail>{user?.email}</StyledEmail></div>
                    <div>Open emails from Sense and press link to continue creating your account</div>
                </Styledtext>
                
                <StyledButton onClick={handleResend}>Resend confirmation link</StyledButton>
                <Button onClick={logout} variant="tertiary">Back to Login</Button>
            </StyledWrapper>

        </StyledContainer>
    )
}