import React, { useCallback, useContext, useEffect } from "react";
import { SpacesContextType } from "./Spaces.type";
import { useState } from "react";
import { SpaceType, SpacesAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/List/List.type";
import { GetSpaceRelationsFetch, GetSpacesFetch } from "@/axios/AiService/Spaces/Spaces.api";
import { useRouter } from "../Router/Router.provider";
import { isShared } from "@/utils/IsShared/IsShared.utils";
import { GetSpacesRelationsAxiosResponseSuccessType } from "@/axios/AiService/Spaces/Types/GetRelations/GetRelations.type";
import { useMe } from "../MeProvider/Me.provider";


const defaultValue: SpacesContextType = {
    spaces: [],
    mySpaces: [],
    loadSpaces: () => undefined,
    isFetching: true
};

const SpacesContext = React.createContext<SpacesContextType>(defaultValue);
export const useSpaces = () => useContext<SpacesContextType>(SpacesContext);

export const SpacesProvider = (props: { children: React.ReactNode }) => {
    const [spaces, setSpaces] = useState<SpaceType[]>([]);
    const [mySpaces, setMySpaces] = useState<SpaceType[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const router = useRouter();
    const { user } = useMe();
    const { isSharedSpace, isSharedNode } = isShared(router);
    const loadSpaces = useCallback(async () => {
        if (!isSharedSpace && !isSharedNode && user?.id) {
            setIsFetching(true);
            await GetSpacesFetch({}).then((res: SpacesAxiosResponseSuccessType) => {
                setSpaces(res.data);
            });
            await GetSpaceRelationsFetch({
                userId: user.id,
                relations: ['FOLLOW_BY']
            }).then((res: GetSpacesRelationsAxiosResponseSuccessType) => {
                setMySpaces(res.data);
            });
            setIsFetching(false);
        }
    }, [isSharedSpace, isSharedNode, user?.id])
    useEffect(() => {
        loadSpaces();
    }, [loadSpaces]);

    const contextValue = {
        spaces,
        mySpaces,
        loadSpaces,
        isFetching
    };

    return <SpacesContext.Provider value={contextValue}>
        {props.children}
    </SpacesContext.Provider>;
};

export default SpacesContext;