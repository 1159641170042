import { FC, useMemo, useState } from "react";
import { StyledWrapper, StyledFiltersWrapper, StyledSelect } from "@/pages/v3/Space/components/AllResources/AllResources.styled";
import { CategoryGroup } from "@/components/v3/List/CategoryGroup/CategoryGroup.component";
import { FilterIcon } from "@/icons/v3/filter.icon";
import { LoaderLayout } from "@/components/v3/Layouts/LoaderLayout/LoaderLayout.component";
import { useForYouPage } from "@/providers/v3/ForYouPage/ForYouPage.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { groupNodeWrappersByCategory } from "@/utils/v3/GroupNodeByCategory/GroupNodeByCategory.util";

export const AllResources: FC = () => {
    const {
        users,
    } = useMe();
    const {
        listOfAllResources,
        isFetchingAllResources,
        update,
    } = useForYouPage();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
    const [selectedApps, setSelectedApps] = useState<string[]>([]);
    const allGroups = useMemo(() => {
        return groupNodeWrappersByCategory(listOfAllResources);
    }, [listOfAllResources]);
    const listOfSpaceApplications = useMemo(() => {
        const applications: string[] = [];
        listOfAllResources.forEach((resource) => {
            if (!applications.includes(resource.node.application)) {
                applications.push(resource.node.application);
            }
        });
        return applications;
    }, [listOfAllResources]);
    const filteredListOfNodes = useMemo(() => {
        return listOfAllResources.filter((wrapper) => {
            const selectedAppsLabels = listOfSpaceApplications.filter((application) => selectedApps.includes(application));
            if (selectedApps.length && !selectedAppsLabels.includes(wrapper.node.application)) {
                return false;
            }
            if (selectedPeople.length) {
                const actorIds = wrapper.relationships?.actors?.map(actor => actor.userId) || [];
                if (!actorIds.some(id => selectedPeople.includes(id))) {
                    return false;
                }
            }
            return true;
        });
    }, [listOfAllResources, listOfSpaceApplications, selectedApps, selectedPeople]);
    const groups = useMemo(() => {
        return groupNodeWrappersByCategory(filteredListOfNodes);
    }, [filteredListOfNodes]);
    const filteredGroups = useMemo(() => {
        if (!selectedCategories.length) {
            return groups;
        }
        return groups.filter((group) => {
            return selectedCategories.includes(group.category);
        });
    }, [groups, selectedCategories]);
    const listOfCategoriesJsx = filteredGroups.map((group, i) => {
        return <CategoryGroup useShowAll title={group.category} nodes={group.wrappers.map((wrapper) => {
            return {
                node: wrapper.node,
                users: users,
                relationships: wrapper.relationships,
                onUpdate: update,
                importantUserIds: wrapper.importantUserIds
            }
        })} key={i} />
    });
    return <LoaderLayout isLoading={isFetchingAllResources} transparent={!!listOfAllResources.length}>
    <StyledWrapper>
        <StyledFiltersWrapper>
            <StyledSelect
                label="Categories"
                value={selectedCategories}
                onChange={(val: any) => setSelectedCategories(val)}
                options={allGroups.map((group) => {
                    return { label: group.category, value: group.category}
                })}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
            />
            <StyledSelect
                label="Apps"
                value={selectedApps}
                onChange={(val: any) => setSelectedApps(val)}
                options={listOfSpaceApplications.map((app) => {
                    return { label: `${app}`, value: app}
                })}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
            />
            <StyledSelect
                label="People"
                value={selectedPeople}
                onChange={(val: any) => setSelectedPeople(val)}
                options={users.map((user) => {
                    return { label: `${user.firstName} ${user.lastName}`, value: user.id, img: user.avatar || "/icons/no_avatar.svg"}
                })}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                icon={<FilterIcon />}
            />
        </StyledFiltersWrapper>
        {listOfCategoriesJsx}
    </StyledWrapper>
</LoaderLayout>
};