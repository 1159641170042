import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useMe } from "providers/MeProvider/Me.provider";
import { StyledContainer, StyledLoader, StyledText, StyledWrapper } from "./Loading.styled";


export const LoadingPage = () => {
    const { loadUser, user } = useMe();
    const isAccountReady = user?.accountReady;

    useEffect(() => {
        const interval = setInterval(() => {
            loadUser();
        }, 3000)

        return () => {
            clearInterval(interval)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccountReady])
    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledLoader><CircularProgress style={{width: 80, height: 80}} /></StyledLoader>
                <StyledText>We are preparing your Artificial Memory...</StyledText>
            </StyledWrapper>
        </StyledContainer>
    )
}