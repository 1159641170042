import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    margin-top: 16px;
`
export const StyledHeaderWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const StyledHeaderText = styled('div')`
    margin-left: 8px;
`
export const StyledContent = styled('div')`
    margin-top: 8px;
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`