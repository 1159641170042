import { FC } from "react";
import { UserProps } from "./User.type";
import { StyledWrapper } from "./User.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { Person } from "@/components/v3/List/Person/Person.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { CreateOrDeleteUserRelationsFetch } from "@/axios/AiService/Users/Users.api";

export const User: FC<UserProps> = ({
    user
}) => {
    const { userRelations, user: userMe, loadUserRelations } = useMe();
    const isSelected = userRelations.some((el) => el.userId === user.id);
    const addToMyUsersHandler = async () => {
        userMe?.id && await CreateOrDeleteUserRelationsFetch({
            userId: userMe?.id,
            relations: [{
                userId: user.id,
                relationType: "FOLLOWING"
            }]
        }, {
            action: "CREATE"
        }).then(() => loadUserRelations());
    };
    const removeFromMyUsersHandler = async () => {
        userMe?.id && await CreateOrDeleteUserRelationsFetch({
            userId: userMe?.id,
            relations: [{
                userId: user.id,
                relationType: "FOLLOWING"
            }]
        }, {
            action: "DELETE"
        }).then(() => loadUserRelations());
    };
    return <StyledWrapper>
        <Person
            person={user}
        />
        {!isSelected && <Button onClick={addToMyUsersHandler}>Select</Button>}
        {isSelected && <Button variant="tertiary" onClick={removeFromMyUsersHandler}>Remove</Button>}
    </StyledWrapper>;
}