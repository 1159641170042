import { styled } from "@mui/material";
import { SizeType, VariantType } from "./DropdownField.type";
import { Arrow } from "./components/Arow/Arow.component";

export const StyledToggleBtn = styled('div', {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'size' && prop !== 'active' && prop !== 'withIcon' && prop !== 'variant'
})<{disabled: boolean, size: SizeType; active: boolean, withIcon: boolean, variant: VariantType}>`
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${({withIcon}) => withIcon ? '8px' : '12px'};
    padding-right: ${({withIcon}) => withIcon ? '8px' : '12px'};
    padding-top: ${({size}) => size === 'small' ? '0' : '4px'};
    padding-bottom: ${({size}) => size === 'small' ? '0' : '4px'};
    transition: .2s ease-in-out;
    box-sizing: border-box;
    height: ${({size}) => size === 'small' ? '32px' : '40px'};
    ${({variant, disabled, active}) => variant === 'standard' && `
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
        background: ${disabled ? '#EBEBEB' : '#FFFFFF'};
        border: ${disabled ? 'none' :
            active ? '1px solid #202020' : '1px solid rgba(0, 0, 0, 0.16)'};
    `}
    ${({variant}) => variant === 'tetrinary' && `
        background: transparent;
    `}
    &:hover {
        cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
        ${({disabled, variant}) => !disabled && variant === 'standard' && 'border: 1px solid #202020;'}
        ${({disabled, variant}) => !disabled && variant === 'tetrinary' && 'background: rgba(0, 0, 0, 0.06);'}
    }
`;
export const StyledLabel = styled('div')`
    color: #202020;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-right: 8px;
`;

export const StyledArrow = styled(Arrow, {
    shouldForwardProp: (prop) => prop !== 'active'
})<{active: boolean}>`
    transition: .2s ease-in-out;
    transform-origin: center;
    width: 24px;
    height: 24px;
    transform: ${({active}) => active ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
export const StyledIconWrapper = styled('div')`
    width: 18px;
    height: 18px;
    margin-right: 10px;
`;
export const StyledDropdownContainer = styled('div')<{width?: number}>`
    background: #FFFFFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.24);
    overflow: none;
    width: ${({width}) => width ? `${width}px` : '100%'};
`;
export const StyledDropdownWrapper = styled('div')`
    overflow: auto;
    max-height: 514px;
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
    }
    &::-webkit-scrollbar-track {
        background: rgb(0,0,0,0.08);
    }
`