import { FC } from "react";
import { ActivityMessageOrCommentProps } from "./ActivityMessageOrComment.type";
import { StyledWrapper } from "./ActivityMessageOrComment.styled";
import { ActivityMessage } from "../ActivityMessage2/ActivityMessage.component";
import { Comment } from "../Comment2/Comment.component";

export const ActivityMessageOrComment: FC<ActivityMessageOrCommentProps> = ({
    activityMessage,
    comment,
    users,
    useSkeleton = true,
    isExpired = false,
    className
}) => {
    return <>
        {!!activityMessage && <ActivityMessage className={className} useSkeleton={useSkeleton} isExpired={isExpired} activityMessage={activityMessage} />}
        {!!comment && <Comment className={className} useSkeleton={useSkeleton} isExpired={isExpired} comment={comment} users={users} />}
    </>
};