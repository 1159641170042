import { FC } from "react";
import { ActivityProps } from "./Activity.type";
import { SmallHeader } from "./components/SmallHeader/SmallHeader.component";
import { StyledActivitiesWrapper, StyledNode, StyledWrapper } from "./Activity.styled";
// import { getSize } from "./Activity.utils";
import moment from "moment";
import { isItemExpired } from "@/utils/v3/IsItemExpired/IsItemExpired.util";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { ActivityMessageOrComment } from "../ActivityMessageOrComment2/ActivityMessageOrComment.component";
import { LargeHeader } from "./components/LargeHeader/LargeHeader.component";

export const Activity: FC<ActivityProps> = ({
    size: sizeProp,
    node,
    importantUserIds,
    activities,
    users,
    relationships,
    lastActor,
    className,
    onUpdate,
    useSkeleton = true
}) => {
    const { user } = useMe();
    const isIndividual = user?.paymentPlan.name === 'INDIVIDUAL';
    const isNodeExpired = isItemExpired(node.createdAt) && !node.sampleData && isIndividual;
    // const size = getSize({ size: sizeProp, actionType: lastActor?.actionType })
    const size = 'large';
    const activitiesWithComments = [...(activities || []), ...(node.comments || [])].sort((a, b) => {
        let aTime, bTime;
        if ('createdAt' in a) aTime = moment.utc(a.createdAt).local().toDate();
        else if ('messageTs' in a) aTime = moment.unix(parseInt(a.messageTs)).toDate();

        if ('createdAt' in b) bTime = moment.utc(b.createdAt).local().toDate();
        else if ('messageTs' in b) bTime = moment.unix(parseInt(b.messageTs)).toDate();
        if (!!aTime && !!bTime) {
            return bTime.getTime() - aTime.getTime();
        }
        return -1
    });;
    const activitiesWithCommentsJsx = activitiesWithComments.map((activityOrComment, i) => {
        if ('createdAt' in activityOrComment) {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} comment={activityOrComment} users={users} key={i} />
        } else {
            return <ActivityMessageOrComment useSkeleton={useSkeleton} isExpired={isNodeExpired} activityMessage={activityOrComment} users={users} key={i} />
        }
    });
    return <StyledWrapper className={className}>
        {size === 'large' && <StyledNode
            before={<LargeHeader useSkeleton={useSkeleton} node={node} actor={lastActor} isExpired={isNodeExpired}/>}
            node={node}
            users={users}
            activitiesWithComments={activitiesWithComments}
            relationships={relationships}
            onUpdate={onUpdate}
            importantUserIds={importantUserIds}
            useSkeleton={useSkeleton}
        />}
        {/* {size === 'small' && <SmallHeader useSkeleton={useSkeleton} node={node} size={size} actor={lastActor} isExpired={isNodeExpired}/>}
        {size === 'small' && (!!activities?.length || !!node.comments?.length) && <StyledActivitiesWrapper>
            {activitiesWithCommentsJsx}
        </StyledActivitiesWrapper>} */}
    </StyledWrapper>
};