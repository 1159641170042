import { useEffect, useState } from 'react';
import { UseWindowOpenReturnType } from './UseWindowOpen.type';
import { useRenederBelow } from '../v3/UseResponsive/UseResponsive.hook';

export const useWindowOpen = (): UseWindowOpenReturnType => {
    const [windowLocal, setWindowLocal] = useState<Window | null>(null);
    const [href, setHref] = useState<string | null>(null);

    const isMobile = useRenederBelow('tablet');

    useEffect(() => {
        const handleHrefChange = () => {
            if (windowLocal) {
                if (!windowLocal.closed) {
                    try {
                        setHref(windowLocal.location.href);
                    } catch (e) {
                        console.error("Can't access the URL of the popup window.", e);
                    }
                } else {
                    setWindowLocal(null);
                    setHref(null);
                }
            }
        };

        const intervalId = setInterval(handleHrefChange, 1000);

        return () => {
            if (windowLocal && !windowLocal.closed) {
                windowLocal.close();
            }
            clearInterval(intervalId);
        };
    }, [windowLocal]);


    const close = () => {
        windowLocal?.close();
    };
    const focus = () => {
        windowLocal?.focus();
    };
    const open = (url?: string | URL) => {
        const width = window.screen.width * 0.7;
        const height = window.screen.height * 0.7;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;

        const features = `width=${width},height=${height},left=${left},top=${top}`;
        const newWindow = window.open(url, '_blank', !isMobile ? features : undefined);
        setWindowLocal(newWindow);
    };

    return {
        window: windowLocal,
        open,
        close,
        href,
        focus
    };
};
