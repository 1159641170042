import { InfoIcon } from "@/icons/v3/info.icon";
import { FC, useState, useRef, useMemo } from "react";
import { StyledArrow, StyledButton, StyledContentActions, StyledContentDescription, StyledContentTitle, StyledContentWrapper, StyledPopper } from "./ExpiredTip.styled";
import { Fade, Paper } from "@mui/material";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { ExpiredTipProps } from "./ExpiredTip.type";

export const ExpiredTip: FC<ExpiredTipProps> = ({ className }) => {
    const router = useRouter();
    const { invitedUsers, confirmedInvitedUsers } = useMe();
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const tooltipHoveredRef = useRef(false);
    const buttonHoveredRef = useRef(false);

    const handleTooltipClose = () => {
        if (!tooltipHoveredRef.current && !buttonHoveredRef.current) {
            setAnchorEl(null);
        }
    };

    const handleTooltipOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const id = anchorEl ? 'expired-tip-popper' : undefined;
    return (
        <>
            <StyledButton
                className={`${className} ignore--node-actions`}
                onMouseEnter={(e) => {
                    buttonHoveredRef.current = true;
                    handleTooltipOpen(e);
                }}
                onMouseLeave={() => {
                    buttonHoveredRef.current = false;
                    setTimeout(handleTooltipClose, 100);
                }}
                aria-describedby={id}
                variant="tertiary"
                icon={<InfoIcon className="icon" />}
                onClick={() => undefined}
            />
            <StyledPopper
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="top"
                transition
                style={{zIndex: 1}}
                className="ignore--node-actions"
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            onMouseEnter={() => {
                                tooltipHoveredRef.current = true;
                            }}
                            onMouseLeave={() => {
                                tooltipHoveredRef.current = false;
                                setTimeout(handleTooltipClose, 100);
                            }}
                            style={{background: 'transparent', boxShadow: 'none', padding: 0}}
                        >
                            <StyledContentWrapper>
                                <StyledContentTitle>Resource is older than 30 days</StyledContentTitle>
                                <StyledContentDescription>
                                    Free plan has 30 days history limit.<br />
                                    Upgrade to Professional Plan to use Sense without limits.
                                </StyledContentDescription>
                                <StyledContentActions>
                                    <Button variant="secondary" onClick={() => {
                                        router.push({
                                            name: router.name,
                                            params: router.params,
                                            query: {
                                                ...router.query,
                                                dialog: 'payment',
                                                plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                                            }
                                        });
                                    }}>Upgrade Plan</Button>
                                </StyledContentActions>
                                <StyledArrow />
                            </StyledContentWrapper>
                        </Paper>
                    </Fade>
                )}
            </StyledPopper>
        </>
    );
};
