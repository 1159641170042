import { Person } from "@/components/v3/List/Person/Person.component";
import { FilledCard } from "@/components/v3/Other/FilledCard/FilledCard.component";
import { styled } from "@mui/material";

export const StyledWrapper = styled(FilledCard)`
    margin-top: 16px;
`;
// export const StyledPersonsWrapper = styled('div')`
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     flex-wrap: wrap;
// `;
// export const StyledPerson = styled(Person)`
//     padding: 12px 8px;
//     cursor: pointer;
//     border-radius: 6px;
//     margin-top: 8px;
//     transition: all 0.1s ease-in-out;
//     margin-right: 32px;
//         border: 1px solid transparent;
//     &:hover {
//         border: 1px solid #202020;
//     }
// `;
export const StyledPerson = styled(Person)`
    padding: 12px 8px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    margin-top: 8px;
    transition: all 0.1s ease-in-out;
    &:hover {
        border: 1px solid #202020;
    }
`;