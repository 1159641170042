import { Box } from "@mui/material";
import { ManageButton } from "./components/ManageButton/ManageButton.component";
import { useMe } from "providers/MeProvider/Me.provider";
import { IntegrationProps } from "./mIntegration.type";
import {
    StyledActionsWrapper,
    StyledListItemNode,
    StyledListItem,
    StyledMobileActionsWrapper,
} from "./Integration.styled";
import { openConectAppLink } from "@/utils/v3/OpenConectAppLink/OpenConectAppLink.util";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRenederBelow } from "@/hooks/v3/UseResponsive/UseResponsive.hook";
import { useEffect, useMemo } from "react";
import { useRouter } from "@/providers/Router/Router.provider";
import { defineApp } from "../../ManageIntegrationsContent.utils";
import { useWindowOpen } from "@/hooks/UseWindowOpen/UseWindowOpen.hook";

export const Integration = ({
        servicesIntegrationStatus,
        icon,
        appName,
        accounts,
        disconnectHandler,
        type,
        caption: captionProp,
        setIsOpenConnectChannels
    }: IntegrationProps) => {
    const { user, loadUser } = useMe();
    const isMobile = useRenederBelow('mobileRotate');
    const router = useRouter();
    const isOnboarding = router.name === 'connectApps'    
    const {url, appPathName} = openConectAppLink(type, user?.id, {isOnboarding});
    
    const caption = useMemo(() => {
        if (accounts?.length) {
            return `${accounts.length} account${accounts.length > 1 ? 's' : ''} connected`;
        }
        return captionProp;
    }, [accounts, captionProp]);
    const {open, href, focus, window: integrationWindow, close} = useWindowOpen();
    useEffect(() => {
        if (!!href) {
            const url = new URL(href);
            if (url.host === window.location.host) {
                loadUser();
                close();
                if (isOnboarding) {
                    if (!!setIsOpenConnectChannels) {
                        switch (type) {
                            case 'slack':
                                setIsOpenConnectChannels('slack');
                                break;
                            case 'click_up':
                                setIsOpenConnectChannels('click_up');
                                break;
                            case 'trello':
                                setIsOpenConnectChannels('trello');
                                break;
                            case 'asana':
                                setIsOpenConnectChannels('asana');
                                break;
                            case 'github':
                                setIsOpenConnectChannels('github');
                                break;
                            case 'atlassian':
                                setIsOpenConnectChannels('atlassian');
                                break;
                            case 'microsoft_teams':
                                setIsOpenConnectChannels('microsoft_teams');
                                break;
                        }
                    }
                } else {
                    router.push({
                        name: 'forYou',
                        params: {
                            dialog: appPathName,
                            type: type
                        }
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appPathName, href, isOnboarding, router, type]);
    const connectHandler = () => {
        if (!integrationWindow) {
            open(url);
        } else {
            focus();
        }
    }
    return (
        <StyledListItemNode bottomButtons={isMobile && !!accounts?.length} >
            <StyledListItem
                rounded={false}
                img={icon}
                title={appName}
                caption={caption}
                imgSize="large"
            />
            <Box>
                {!servicesIntegrationStatus && (
                    <Button onClick={() => {
                        if (!integrationWindow) {
                            url && open(url)
                        } else {
                            focus();
                        }
                    }} >Connect</Button>
                )}
                {servicesIntegrationStatus && (
                    <Box>
                        <StyledActionsWrapper>
                            {!!accounts?.length && !isMobile && <>
                                <Button variant="secondary" onClick={() => {
                                    if (!integrationWindow) {
                                        url && open(url)
                                    } else {
                                        focus();
                                    }
                                }}>
                                    Add Account
                                </Button>
                                <ManageButton icon={icon} connectHandler={connectHandler} disconnectHandler={disconnectHandler} accounts={accounts} appName={appName}/>
                            </>}
                            {!accounts?.length && <Button variant="tertiary" onClick={() => {
                                disconnectHandler(defineApp(appName))
                            }}>
                                Disconnect
                            </Button>}
                        </StyledActionsWrapper>
                    </Box>
                )}
                {isMobile && !!accounts?.length && <StyledMobileActionsWrapper>
                    <Button variant="secondary" center onClick={() => {
                        if (!integrationWindow) {
                            url && open(url)
                        } else {
                            focus();
                        }
                    }}>
                        Add Account
                    </Button>
                    <ManageButton bottomButtons icon={icon} connectHandler={connectHandler} disconnectHandler={disconnectHandler} accounts={accounts} appName={appName}/>
                </StyledMobileActionsWrapper>}
            </Box>
        </StyledListItemNode>
    )
}