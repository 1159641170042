import { Box, styled } from "@mui/material";

export const StyledWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const StyledContainer = styled(Box)`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
`;

export const StyledCard = styled("div")`
    padding: 24px;
    border-radius: 8px;
    background: #FFF;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 100%;
    max-width: 595px;
`;
export const StyledText = styled('div')`
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 16px;
`;
export const StyledButtonContainter = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
`;