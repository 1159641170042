import { Box, styled } from "@mui/material";

export const StyledNavigationItem = styled(Box, {
    shouldForwardProp: prop => prop !== 'active' && prop !== 'actions'
  })<{active: boolean, actions: boolean}>`
    display: flex;
    font-weight: 500;
    flex-direction: row;
    align-content: stretch;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: rgba(0, 0, 0, 0.06);
    }
    ${({active}) => active && `
        background: rgba(0, 0, 0, 0.06);
    `}
    ${({actions}) => actions && `
        padding-right: 4px;
    `}
    border-radius: 8px;
`;
export const StyledImg = styled('img', {
    shouldForwardProp: prop => prop !== 'avatar'
  })<{avatar: boolean}>`
      width: 24px;
      margin-right: 8px;
      border-radius: ${({avatar}) => avatar ? '50%' : '0'};
`;
export const StyledNavigationItemWrapper = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 10000 1 0%;
    min-height: 40px;
`;