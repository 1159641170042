import { FC, useEffect, useMemo, useRef, useState } from "react";
import { StyledContainer, StyledMenuContainer, StyledSettingsWrapper } from "./Settings.styled";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { SettingsIcon } from "@/icons/v3/settings.icon";
import { MainMenu } from "./components/MainMenu/MainMenu.component";
import { Popover } from "@mui/material";
import { IntegrationType } from "@/components/v3/Modals/ConnectChannels/ConnectChannels.type";
import { useRouter } from "@/providers/Router/Router.provider";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { GetPortalUrlFetch } from "@/axios/OrganizationService/Payment/Payment.api";
import { MenuItem } from "@/components/v3/Other/MenuItem/MenuItem.component";
import { Divider } from "@/components/v3/Other/Divider/Divider.component";
import { ConnectChannels } from "@/components/v3/Modals/ConnectChannels/ConnectChannels.component";
import { StartIntegrationDialog } from "@/components/v3/Modals/StartIntegrationDialog/StartIntegrationDialog.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { StyledMenuIcon } from "@/components/v3/Other/Menu/Menu.styled";

export const Settings: FC = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [value, onChange] = useState<boolean>(false);
    const [isOpenConnectChannels, setIsOpenConnectChannels] = useState<IntegrationType | false>(false);
    const [isStartIntegrationModalOpen, setIsStartIntegrationModalOpen] = useState<boolean>(false);
    const router = useRouter();
    const { logout, user, invitedUsers, confirmedInvitedUsers } = useMe();
    const isAdmin = user?.isAdmin;
    const isSubscriptionPaid = user?.subscriptionStatus === "PAID";
    const dialogName = router.query.dialog;
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    useEffect(() => {
        if (dialogName === 'manage-integrations' && !!router.query.type) {
            setIsStartIntegrationModalOpen(true);
        } else if (dialogName === 'inviteTeammates') {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'manage-users',
                }
            })
        } else if (dialogName === 'connect-slack-channels') {
            setIsOpenConnectChannels('slack');
        } else if (dialogName === 'connect-click-up-projects') {
            setIsOpenConnectChannels('click_up');
        } else if (dialogName === 'connect-trello-boards') {
            setIsOpenConnectChannels('trello');
        } else if (dialogName === 'connect-asana-projects') {
            setIsOpenConnectChannels('asana');
        } else if (dialogName === 'connect-github-projects') {
            setIsOpenConnectChannels('github');
        } else if (dialogName === 'connect-atlassian-projects') {
            setIsOpenConnectChannels('atlassian');
        } else if (dialogName === 'connect-microsoft-teams-projects') {
            setIsOpenConnectChannels('microsoft_teams');
        } else if (dialogName === 'managePayment') {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'payment',
                    plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                }
            })
        } else if (dialogName === 'openCreateSpaceModal') {
            router.push({
                name: router.name,
                params: router.params,
                query: {
                    ...router.query,
                    dialog: 'create-space',
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogName, router, amountOfPaidUsers]);
    const getRedirectLinkHandlerClick = () => {
        user?.id && GetPortalUrlFetch({ userId: user.id }).then(response => {
            window.location.href = response.data.portalUrl
        })
    }
    return <StyledSettingsWrapper ref={ref}>
        <ConnectChannels
            isOpen={isOpenConnectChannels}
            setIsOpen={setIsOpenConnectChannels}
        />
        <StartIntegrationDialog value={isStartIntegrationModalOpen} onChange={setIsStartIntegrationModalOpen} />
        <Popover
            open={value}
            anchorEl={ref.current}
            onClose={() => onChange(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <StyledMenuContainer>
                <MenuItem onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'manage-integrations',
                        }
                    });
                }}>
                    Manage integrations
                </MenuItem>
                {isAdmin && <MenuItem onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'manage-users',
                        }
                    })
                }}>
                    Manage users
                </MenuItem>}
                <MenuItem onClick={() => {
                    router.push({
                        name: router.name,
                        params: router.params,
                        query: {
                            ...router.query,
                            dialog: 'settings',
                        }
                    })
                }}>
                    Settings
                </MenuItem>
                {isAdmin && <>
                    {!isSubscriptionPaid && <MenuItem onClick={() => {
                        router.push({
                            name: router.name,
                            params: router.params,
                            query: {
                                ...router.query,
                                dialog: 'payment',
                                plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                            }
                        })
                    }}>
                        Payment
                    </MenuItem>}
                    {isSubscriptionPaid && <MenuItem onClick={() => {
                        getRedirectLinkHandlerClick()
                    }}>
                        Manage billing
                    </MenuItem>}
                </>}
                <Divider />
                <MenuItem variant="error" onClick={() => {
                    logout();
                }}>
                    Log out
                </MenuItem>
            </StyledMenuContainer>
        </Popover>
        <StyledContainer onClick={() => onChange(true)}>
            <ListItem
                title="Settings & Integrations"
                img={<SettingsIcon />}
            >
                <Button onClick={() => onChange(true)} icon={<StyledMenuIcon />} variant="tertiary" />
            </ListItem>
        </StyledContainer>
    </StyledSettingsWrapper>;
};