import styled from "@emotion/styled";

export const StyledPersonWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'active'
})<{active: boolean;}>`
    font-weight: 500;
    cursor: pointer;
    background: ${({ active }) => active ? 'rgba(0,0,0,.06)' : 'transparent'};
    border-radius: 8px;
    padding: 8px 8px;
    transition: all .2s ease-in-out;
    &:hover {
        background: rgba(0,0,0,.06);
    }
`;