import { FC } from "react";
import { AppProps } from "./App.type";
import { StyledAppIcon, StyledCheckboxCard, StyledText } from "./App.styled";
import { FallingCard } from "@/components/v3/Other/FallingCard/FallingCard.component";

export const App: FC<AppProps> = ({ app, value, onChange, isVisible }) => {
  return <FallingCard value={isVisible} >
    <StyledCheckboxCard value={value} onChange={onChange}>
      <StyledAppIcon application={app.application} />
      <StyledText>{app.title}</StyledText>
    </StyledCheckboxCard>
  </FallingCard>
};