import { FC, useEffect, useMemo } from "react";
import { AppProps } from "./App.type";
import { StyledWrapper } from "./App.styled";
import { ListItem } from "@/components/v3/List/ListItem/ListItem.component";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { openConectAppLink } from "@/utils/v3/OpenConectAppLink/OpenConectAppLink.util";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { AppIcon } from "@/components/v3/Other/AppIcon/AppIcon.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { ButtonProps } from "../../Fields/Button/Button.type";
import { useWindowOpen } from "@/hooks/UseWindowOpen/UseWindowOpen.hook";

export const App: FC<AppProps> = ({
    app,
    buttonProps = {},
    caption,
}) => {
  const { user, loadUser } = useMe();
  const router = useRouter();
  const type = useMemo(() => {
    if (app.title === "Asana") {
      return 'asana'
    } else if (app.title === "Github") {
      return 'github'
    } else if (app.title === "ClickUp") {
      return 'click_up'
    } else if (app.title === "Trello") {
      return 'trello'
    } else if (app.title === "Figma") {
      return 'figma'
    } else if (app.title === "Slack") {
      return 'slack'
    } else if (app.title === "Jira" || app.title === "Confluence") {
      return 'atlassian'
    } else if (app.title === 'Microsoft Teams') {
      return 'microsoft_teams'
    } else if (app.title === 'Notion') {
      return 'notion'
    } else if (app.title === 'Gmail') {
      return 'gmail'
    } else if (app.title === 'Google Drive') {
      return 'google_drive'
    } else if (app.title === 'Outlook') {
      return 'outlook'
    } else if (app.title === 'Dropbox for Business') {
      return 'dropbox_business'
    } else if (app.title === 'Google Calendar') {
      return 'google_calendar'
    }
    return 'asana'
  }, [app.title]);
  
  const {url, appPathName} = openConectAppLink(type, user?.id, {
    spaceApp: app.application,
    spaceId: router.params.spaceId as string
  });
  const {open, href, focus, window: integrationWindow} = useWindowOpen();
    useEffect(() => {
      if (!!href) {
          const url = new URL(href);
          if (url.host === window.location.host) {
            const isSpace = !!router.params.spaceId;
            router.push({
              name: isSpace ? 'space' : 'forYou',
              params: {
                  spaceId: isSpace ? router.params.spaceId : undefined,
              },
              query: {
                dialog: appPathName,
                type: type,
                spaceApp: app.application,
                addTab: router.query.tab
              }
            });
            loadUser();
          }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.application, appPathName, href, type]);
  const connectHandler = () => {
    if (!integrationWindow) {
      url && open(url)
    } else {
      focus();
    }
  }
  const defaultButtonProps: ButtonProps = {
    variant: 'primary',
    onClick: connectHandler
  };
  const finalButtonProps = { ...defaultButtonProps, ...buttonProps };
  return <StyledWrapper>
      <ListItem
          title={app.title}
          img={<AppIcon application={app.application} />}
          caption={caption}
      />
      {!app.isConnected && <Button {...finalButtonProps}>Connect</Button>}
  </StyledWrapper>
};