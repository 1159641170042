import { FC } from "react";
import { GroupLayoutProps } from "./GroupLayout.type";
import { StyledFilledCard, StyledTitle } from "./GroupLayout.styled";

export const GroupLayout: FC<GroupLayoutProps> = ({
    title, children,
}) => {
    return <StyledFilledCard>
        <StyledTitle>{title}</StyledTitle>
        {children}
    </StyledFilledCard>
};